import React from 'react';
import { getDatabase } from '../../db/datamanager';
import { AirtableRecord } from '../../db';
import { Box } from '@rogoag/airtable';
import { useEffect, useState } from 'react';
import { ScannedBox } from '../../db/types';
import { alertConfirm } from '../../alertDispatcher';

interface Props {
  labName: string;
  dropoffName: string;
  group: any;
  switchToCourier: (dropoffName: string, labName: string) => Promise<void>;
  expandBox: (boxId: string) => void;
  collapseBox: (boxId: string) => void;
  excludeBox: (boxId: string) => void;
  scannedBoxIds: string[];
  expandedBoxes: string[];
  excludedBoxes: string[];

  cameraError: string;
  activateCaptureModal: (captureTargetId: string) => void;
  onUploadedBoxPictureChange: (boxId: string, file: File) => Promise<void>;
  loading: boolean;
  captureLoading: boolean;

  onNoScanOrPicExcuseChange: (box: AirtableRecord<Box>, excuse: string | undefined) => void;
}
import ShippingGroupBoxCard from './ShippingGroupBoxCard';
import { ShippingGroupCardsContainer } from './ShippingLabGroupCardsContainer';

export function ShippingLabGroupCarded({
  labName,
  dropoffName,
  group,
  switchToCourier,
  expandBox,
  collapseBox,
  excludeBox,
  scannedBoxIds,
  expandedBoxes,
  excludedBoxes,
  cameraError,
  activateCaptureModal,
  onUploadedBoxPictureChange,
  loading,
  captureLoading,
  onNoScanOrPicExcuseChange,
}: Props) {
  const [scannedBoxes, setScannedBoxes] = useState<ScannedBox[]>([]);

  useEffect(() => {
    const getScannedBoxesFromDb = async () => {
      const db = getDatabase();
      const scannedBoxes = await db.ScannedBoxes.toArray();
      setScannedBoxes(scannedBoxes);
    };

    getScannedBoxesFromDb();
  }, [scannedBoxIds]);

  const groupData = group.get(dropoffName);

  return (
    <ShippingGroupCardsContainer
      labName={labName}
      dropoffName={dropoffName}
      shipMethod={groupData.method}
      onSwitchToCourierClick={async () => await switchToCourier(dropoffName, labName)}
    >
      {groupData.labBoxes.get(labName).map((box: AirtableRecord<Box>) => {
        const boxCode = box.get('Box ID') || '';
        const scannedBox = scannedBoxes.find((b: ScannedBox) => b.box_id === boxCode);

        return (
          <ShippingGroupBoxCard
            key={box.id}
            box={box}
            expandBox={expandBox}
            collapseBox={collapseBox}
            excludeBox={excludeBox}
            dropoffName={dropoffName}
            group={group}
            expandedBoxes={expandedBoxes}
            excludedBoxes={excludedBoxes}
            scannedBoxIds={scannedBoxIds}
            cameraError={cameraError}
            scannedBoxPicture={scannedBox?.image}
            activateCaptureModal={async () => {
              if (await alertConfirm('Do you confirm that scanning failed?')) {
                activateCaptureModal(boxCode);
              }
            }}
            onUploadedBoxPictureChange={onUploadedBoxPictureChange}
            loading={loading}
            captureLoading={captureLoading}
            noScanOrPicExcuse={box.get('Box Ops Notes')}
            onNoScanOrPicExcuseChange={(newExcuse: string | undefined) => onNoScanOrPicExcuseChange(box, newExcuse)}
          />
        );
      })}
    </ShippingGroupCardsContainer>
  );
}