import { MdFileUpload } from 'react-icons/md';
import { LoadingButton } from '../utils';
import CheckIcon from '@material-ui/icons/Check';
import { Button, Grid } from '@material-ui/core';
import { alertConfirm, alertError } from '../../alertDispatcher';
import { FaQrcode } from 'react-icons/fa';

interface Props {
  scanBoxesDisabled: boolean;
  groupedBoxes: any[];
  loadingScanner: boolean;
  cameraError: string;
  needsScanned: boolean;
  updateDetectedCode: (boxId: string, arrayBuffer: ArrayBuffer, b: boolean) => void;
  startScanner: () => void;
}
export function ShippingPhtotoCaptureUpload({
  scanBoxesDisabled,
  groupedBoxes,
  loadingScanner,
  cameraError,
  needsScanned,
  updateDetectedCode,
  startScanner,
}) {
  return (
    <Grid item xs={6} sm={1}>
      {cameraError ? (
        <Button variant="outlined" component="label" disabled={scanBoxesDisabled}>
          3. Box Pics <MdFileUpload style={{ marginLeft: 7.5 }} fontSize="medium" />
          <input
            type="file"
            accept=".jpg"
            style={{ display: 'none' }}
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
              if (!event.target.files) {
                return;
              }

              if (event.target.files.length !== groupedBoxes.length) {
                alertError(
                  `You are shipping ${groupedBoxes.length} boxes, but only uploaded ${event.target.files.length} pictures!`,
                );
                return;
              }

              const confirm = await alertConfirm(
                'You are overriding scanning. Do you have ALL of the boxes for this dropoff?',
              );

              if (!confirm) {
                return;
              }

              for (const [index, box] of groupedBoxes.entries()) {
                await updateDetectedCode(box.get('Box ID'), await event.target.files[index].arrayBuffer(), true);
              }
            }}
            multiple={true}
            data-testid="scan-boxes-upload"
            disabled={scanBoxesDisabled}
          />
        </Button>
      ) : (
        <LoadingButton variant="outlined" onClick={startScanner} loading={loadingScanner} disabled={scanBoxesDisabled}>
          3. Scan Boxes
          {!needsScanned ? (
            <CheckIcon style={{ marginLeft: 7.5, color: '#c1cd23' }} fontSize="small" />
          ) : (
            <FaQrcode style={{ marginLeft: 7.5 }} fontSize="large" />
          )}
        </LoadingButton>
      )}
    </Grid>
  );
}
