import React from 'react';
import { IconButton } from '@material-ui/core';
import { formatDirectionsUrlWithAddress } from '../../utils';
import logger from '../../logger';
import DirectionsIcon from '@material-ui/icons/Directions';
import { AirtableRecord } from '../../db';
import { Dropoffs } from '@rogoag/airtable';

type DirectionsButtonProps = {
  dropoffRecord: AirtableRecord<Dropoffs> | undefined;
  dropoffName: string;
};

const DirectionsButton = ({ dropoffName, dropoffRecord }: DirectionsButtonProps) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={formatDirectionsUrlWithAddress(dropoffRecord?.get('Location'))}
      onClick={async (e) => {
        e.stopPropagation();
        await logger.log('CLICK_DIRECTIONS', `Selected Directions: ${dropoffName}`);
      }}
    >
      <IconButton disabled={!dropoffRecord} color="secondary">
        <DirectionsIcon fontSize="large" />
      </IconButton>
    </a>
  );
};

export default DirectionsButton;
