import JSZip from 'jszip';
import { Geometry } from 'ol/geom';
import {
  AirtableRecord,
  AirtableSearch,
  Attachment,
  Mission,
  Sample,
  SampleBox,
  SelectedRecord,
  Session,
  User,
} from './db';
import Airtable from './airtable';
import { dispatchSessionUpdated } from './sessionEvents';
import { dispatchMissionCreatedDeleted } from './missionEvents';
import { deleteAllRecords, getMemoryTable } from './db/datamanager';
import { DataObject } from './db/dataobject';
import logger from './logger';
import { AppRole, SampleErrorMap, ScheduleMap } from './types/types';
import { TeamUserFlags } from './types/rogo.at.zod';
import BaseTable from './db/basetable';
import { saveRecoveryZip } from './services/RecoveryZipService';
import { checkFuturePasswords, cleanSet, daysIntoYear, generateScheduledKey, getLastDigitOfYear } from './utils';
import Feature from 'ol/Feature';
import { flatten, orderBy } from 'lodash';
import { AirtableRecordFields } from './db/types';
import { Jobs, Team } from '@rogoag/airtable';

const BOX_DATA_SUFFIX = '_box.json';
/**
 * Gets the current session
 */
export function getCurrentSession() {
  return Session.findOne();
}

/**
 * Gets the current mission
 */
export function getCurrentMission() {
  const session = getCurrentSession();
  let mission: Mission | undefined = undefined;
  if (session) {
    mission = session.getMission();
  }
  return mission;
}

/**
 * Gets the current user
 */
export function getCurrentUser() {
  const session = getCurrentSession();
  if (!session) return undefined;
  const user = session.getUser();
  return user;
}

export async function saveCurrentMissionRecoveryZip({
  tag = '',
  offlineSync = false,
  uploadToAWS = false,
  forceDownload = false,
  additionalParams = {},
}) {
  const session = getCurrentSession();
  return await saveRecoveryZip(session?.getMission(), {
    tag,
    offlineSync,
    uploadToAWS,
    forceDownload,
    additionalParams,
  });
}

export async function getJobKmlFromZip({ file, jobID }: { file: File; jobID: string }) {
  const jsZip = new JSZip();
  const zip = await jsZip.loadAsync(file);
  const kmlFiles = Object.keys(zip.files)
    .filter((fileKey) => fileKey.endsWith('.kml'))
    .filter((fileKey) => fileKey.includes(jobID));
  if (!kmlFiles.length) {
    throw new Error('No KML files found');
  }

  const kmlFile = kmlFiles[0];
  const kmlString = (await zip.file(kmlFile)?.async('string')) || '';
  return { filename: kmlFile, content: kmlString };
}

export async function textToZip({ filename, content }: { filename: string; content: string }) {
  const zip = new JSZip();
  zip.file(filename, content);
  const zipContent = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE' });
  if (filename.endsWith('.kml')) {
    filename = filename.replace('.kml', '.kmz');
  }
  return { filename, content: zipContent };
}

/**
 * Loads recovery file
 * This function has become quite large and bloated. It is less than ideal code
 * However, it appears to be working reasonably well in the short term, so we
 * will leave it as iti s for the time being until a better indexeddb orm can be
 * used
 */
export async function loadRecoveryZip(uploadFile: File, clearData = true) {
  await logger.log(`LOAD_RECOVERY_ZIP`, `Loading recovery file ${uploadFile.name}, clearData=${clearData}`);
  await SampleBox.logBoxes('BOXES_BEFORE_LOADING_RECOVERY');

  if (!uploadFile.name.endsWith('.zip')) {
    throw new Error('File type is incorrect');
  }

  const jsZip = new JSZip();
  const zip = await jsZip.loadAsync(uploadFile);
  const userFile = Object.keys(zip.files).find((fileKey) => fileKey.endsWith('.user.json'));
  const kmlFiles = Object.keys(zip.files).filter((fileKey) => fileKey.endsWith('.kml'));

  if (clearData) {
    await deleteAllRecords({ exceptTables: ['User', 'Task', 'Permission', 'Robot', 'Role'] });
  }

  let userParsed: User | undefined;

  let users: User[] | undefined;
  let session = getCurrentSession();

  if (!session && userFile) {
    const userData = await zip.files[userFile].async('text');
    userParsed = JSON.parse(userData) as User;
    users = await User.recoverUsers([userParsed]);
  }

  if (!session && (!users || !users.length)) {
    throw new Error('No users to allow file login and we dont have an active session');
  }

  if (!session && users) {
    //console.log(users[0]);
    const username = users[0].name;
    const password = users[0].hashed_password;
    const alreadyHashed = true; //we saved the hash as well
    session = await Session.newSession(username, password, alreadyHashed);
  }

  const recoverData = async <T>(ending: string) => {
    //console.log(ending);
    const file = Object.keys(zip.files).find((fileKey) => fileKey.endsWith(ending));
    if (!file || !file.length) return [];
    const data = await zip.files[file].async('text');
    return JSON.parse(data) as T[];
  };

  type IdToRecordMap = Record<number, DataObject>;
  const oldIdToNewRecordMap: { [tableName: string]: IdToRecordMap } = {};

  const atRecords = await recoverData<AirtableRecord & { modified_fields_list: string[] }>('_atRecords.json');
  const existingRecords = AirtableRecord.query();
  const atRecordTable = getMemoryTable('AirtableRecord') as BaseTable<AirtableRecord>;
  const atRecordIDMap: IdToRecordMap = {};
  for (const record of atRecords) {
    // check if this airtable record already exists
    if (existingRecords.some((item) => item.id === record.id)) continue;

    // for the airtable record, we serialized the modified_fields set as a list
    // so we need to create a new set when we get it back in order to properly restore it
    record.modified_fields = new Set(record.modified_fields_list);
    const oldId = record._instance_id;
    record._instance_id = 0;
    record._refs = {};
    const newRecord = atRecordTable.add(record);

    atRecordIDMap[oldId] = newRecord;

    //console.count("AirtableRecord")
  }
  oldIdToNewRecordMap['AirtableRecord'] = { ...atRecordIDMap };

  const attachments = await recoverData<Attachment & { contentB64: string }>('_attachments.json');
  const existingAttachments = Attachment.query();
  const attachmentTable = getMemoryTable('Attachment') as BaseTable<Attachment>;
  const attachmentIdMap: IdToRecordMap = {};
  for (const attachment of attachments) {
    //console.log(JSON.stringify(attachment));
    // check if this attachment already exists
    if (existingAttachments.some((item) => item.attachment_id === attachment.attachment_id)) continue;
    const oldId = attachment._instance_id;
    attachment._instance_id = 0;
    attachment._refs = {};

    let base64BlobData: Blob | undefined = undefined;
    // recover base64 data as attachment file if it exists/is truthy
    if (attachment.contentB64) {
      base64BlobData = await (await fetch(attachment.contentB64)).blob();
    }
    attachment.content = base64BlobData;

    // get linked AirtableRecord based on its old ID
    //console.log(JSON.stringify(attachment));
    const atRecord = attachment.AirtableRecord_id
      ? oldIdToNewRecordMap['AirtableRecord'][attachment.AirtableRecord_id]
      : undefined;

    // TODO warn about this, this means we aren't getting a correct link...
    if (!atRecord) {
      console.error('ATTACHMENTS: SERIOUS CONCERN, WE COULD NOT FIND ATTACHMENT -> AIRTABLERECORD');
      continue;
    }

    const newAttachment = attachmentTable.add(attachment);
    attachmentIdMap[oldId] = newAttachment;

    // set the airtable record id for this attachment to the NEW instance ID based on our lookup
    newAttachment.AirtableRecord_id = atRecord._instance_id;

    //console.count("Attachment")
  }
  oldIdToNewRecordMap['Attachment'] = { ...attachmentIdMap };

  const atSearchRecords = await recoverData<AirtableSearch>('_atSearch.json');
  const existingSearchRecords = AirtableSearch.query();
  const atSearchRecordsTable = getMemoryTable('AirtableSearch') as BaseTable<AirtableSearch>;
  const atSearchRecordMap: IdToRecordMap = {};
  for (const atSearch of atSearchRecords) {
    //console.log(atSearch);
    // check if this search already exists
    const oldId = atSearch._instance_id;

    const existingSearches = existingSearchRecords.filter(
      (item) => item.table === atSearch.table && item.view === atSearch.view && item.query === atSearch.query,
    );
    if (existingSearches.length) {
      // TODO we should reconsider this, we should have only one unique search based
      // on table, view, and query, but anytime we are doing this, it feels bad...
      atSearchRecordMap[oldId] = existingSearches[0];
      continue;
    }

    atSearch._instance_id = 0;
    atSearch._refs = {};
    const newSearchRecord = atSearchRecordsTable.add(atSearch);

    if (!newSearchRecord) {
      // this is not a crucial issue because the searches don't have actual relationship data, so we can just
      // skip it if it doesn't add for some reason
      continue;
    }
    atSearchRecordMap[oldId] = newSearchRecord;

    //console.count("AirtableSearch")
  }
  oldIdToNewRecordMap['AirtableSearch'] = { ...atSearchRecordMap };

  const selctedRecords = await recoverData<SelectedRecord>('_selectedRecord.json');
  const existingSelectedRecords = SelectedRecord.query();
  const selectedRecordsTable = getMemoryTable('SelectedRecord') as BaseTable<SelectedRecord>;
  const selectedRecords: IdToRecordMap = {};
  for (const atSelectedRecord of selctedRecords) {
    // check if this search already exists
    if (existingSelectedRecords.some((item) => item.table === atSelectedRecord.table)) continue;
    const oldId = atSelectedRecord._instance_id;
    atSelectedRecord._instance_id = 0;
    atSelectedRecord._refs = {};

    const atRecord = atSelectedRecord.AirtableRecord_id
      ? oldIdToNewRecordMap['AirtableRecord'][atSelectedRecord.AirtableRecord_id]
      : undefined;

    if (!atRecord) {
      console.error('SELECTED RECORDS: SERIOUS CONCERN, CANT FIND SELECTED RECORD -> AIRTABLE RECORD');
      continue;
    }

    const newSearchRecord = selectedRecordsTable.add(atSelectedRecord);
    selectedRecords[oldId] = newSearchRecord;

    newSearchRecord.AirtableRecord_id = atRecord._instance_id;

    const atSearch = atSelectedRecord.AirtableSearch_id
      ? oldIdToNewRecordMap['AirtableSearch'][atSelectedRecord.AirtableSearch_id]
      : undefined;

    // because of the way AT search works, this one is always present, and gets created
    // when the app first tries to query AirTable. As a result... we will do a bad thing
    // here and intetionally set our missing instance ID to 1 since that is the problematic
    // ID that is not in our clever map
    if (!atSearch) {
      console.error('SELECTED RECORDS: SERIOUS CONCERN, CANT FIND SELECTED RECORD -> SEARCH');
      continue;
    }
    newSearchRecord.AirtableSearch_id = atSearch._instance_id;

    //console.count("SelectedRecord")
  }
  atSearchRecordMap['Selected Record'] = { ...selectedRecords };

  const boxes = await recoverData<SampleBox>(BOX_DATA_SUFFIX);
  if (boxes.length) {
    await SampleBox.recoverBoxes(boxes);
  }

  for (let kmlFile of kmlFiles) {
    const kmlData = await zip.files[kmlFile].async('text');
    const [mission, active] = await Mission.load_kml(kmlData, undefined, { sampling: true });
    if (active) {
      if (!session) {
        continue;
      }

      await mission.update_mission_details();
      session.Mission_id = mission.instance_id;
      const allSamples = mission.getAllSamples();
      if (allSamples.length) {
        // TODO BARCODE
        // const currentSampleInstanceId = findNextSample(undefined);
        // session.Sample_id = currentSampleInstanceId;
        // dispatchUpdatedCurrentSample();
      }
      dispatchSessionUpdated();
      dispatchMissionCreatedDeleted();
    }
  }

  await SampleBox.logBoxes('BOXES_AFTER_LOADING_RECOVERY');

  return userParsed;
}

export async function loadUsers() {
  // Load user list from Airtable. Add any new users, update existing users,
  // remove old users.  If a user is logged in and their user account changes
  // or get deleted, log them out.
  try {
    const session = getCurrentSession();
    const search = await Airtable.search<Team>('Team', 'Operator Login for App - LOCK');
    if (!search) throw Error("Can't find search object for Team:Operator Login for App - LOCK");
    await search.refresh();
    const loadedUsers = search.getRecords();
    for (const loadedUser of loadedUsers) {
      let user = User.findOne((sel) => sel?.name === loadedUser.get('App Username'));
      if (!user) {
        // create new user
        user = User.create();
        user.name = loadedUser.get('App Username')!;
      } else if (
        session &&
        session.username === user.name && // existing user changes, logout
        (user.hashed_password !== loadedUser.get('App password (hashed)') ||
          user.airtable_name !== loadedUser.get('Name') ||
          (user.email && user.email !== loadedUser.get('Email')) ||
          user.Role_id !== loadedUser.get('App role ID') ||
          user.user_id !== loadedUser.get('App User ID'))
      ) {
        await session.logout();
      }
      // update user
      user.id = loadedUser.id;
      user.hashed_password = loadedUser.get('App password (hashed)')!;
      user.airtable_name = loadedUser.get('Name')!;
      user.email = loadedUser.get('Email')!;
      user.app_roles = (loadedUser.get('Role') as AppRole[]) || ['Operators'];
      user.user_flags = (loadedUser.get('User Flags') as TeamUserFlags[]) || [];
      user.Role_id = loadedUser.get('App role ID');
      user.user_id = loadedUser.get('App User ID');
    }
    // remove deleted users
    for (const user of User.query()) {
      const isBuiltInUser = user.name === 'guest' || user.name === 'guest_maps';
      const userInNewUsers = loadedUsers.find((sel) => sel.get('App Username') === user.name);
      if (!isBuiltInUser && !userInNewUsers) {
        if (session && session.username === user.name) {
          await session.logout();
        }
        user.dispose();
      }
    }
  } catch (e) {
    console.error(e);
  }
}

// TODO need to resolve this with the getField function from AirTable, possibly...
export function getField<T, U extends AirtableRecordFields>(
  record: AirtableRecord<U>,
  fieldName: keyof U,
  defaultValue: T | undefined = undefined,
  { returnArray = false } = {},
): T {
  if (!record) {
    return defaultValue || ('' as T);
  }

  const value = record.get(fieldName.toString());
  if (value instanceof Array) {
    if (returnArray) {
      return value as unknown as T;
    }
    if (value.length > 0) {
      return (value[0] || defaultValue || '') as T;
    }
  }

  return (value || defaultValue || '') as T;
}

export function getFieldArrayIncludes(
  record: AirtableRecord,
  fieldName: string,
  searchValue: string | number,
): boolean {
  const fieldValue = record?.get(fieldName);
  if (fieldValue instanceof Array) {
    return fieldValue.includes(searchValue);
  }

  return false;
}

export function getJobFromFeature(scheduled: ScheduleMap, unassigned: AirtableRecord[], feature: Feature<Geometry>) {
  const props = feature.getProperties();
  if (props.unassigned) {
    return unassigned.find((job) => !!job && job.id === props.job_id) as AirtableRecord<Jobs>;
  } else {
    const schedulingRecords = scheduled[generateScheduledKey(props.schedDate, props.robot, props.operatorName)];
    if (!schedulingRecords) {
      return undefined;
    }

    return schedulingRecords.find((job) => !!job && job.id === props.job_id)!;
  }
}

async function getTuningPassword(dayOffset: number) {
  const session = getCurrentSession();
  if (!session) {
    throw new Error('No session found');
  }
  const user = session.getUser();

  let atUserRecord: AirtableRecord<Team> | undefined = AirtableRecord.findOne(
    (record) => !!record && record.table === 'Team' && record.get('App Username') === user?.name,
  );
  if (!atUserRecord) {
    const search = await Airtable.search<Team>('Team', 'Operator Login for App - LOCK');
    if (!search) throw Error("Can't find search object for Team:Operator Login for App - LOCK");
    await search.refresh();
    if (search.getRecords().length === 0) {
      throw Error('Cannot find user profile');
    }
    atUserRecord = search.getRecords()[0];
  }

  const appUserID = atUserRecord.get('App User ID') as number;
  console.log('App User ID', appUserID);

  const date = new Date(Date.now() + dayOffset * 24 * 60 * 60 * 1000);
  console.log('Date', date);

  const password = parseInt(appUserID.toString() + daysIntoYear(date).toString() + getLastDigitOfYear(date));
  console.log('Password', password.toString(16).toLowerCase());

  return password;
}

export async function checkAllTuningPasswords(password: string) {
  return await checkFuturePasswords(password, getTuningPassword, { hardPassword: '9362' });
}

export async function checkAllEngineeringPasswords(password: string) {
  return await checkFuturePasswords(password, getEngineeringPassword, { hardPassword: '5513' });
}

async function getEngineeringPassword(dayOffset: number) {
  console.log('Getting engineering password', dayOffset);
  return (await getTuningPassword(dayOffset)) + 0xee;
}

export async function getPulledSamples(mission: Mission) {
  let missionSamples: Sample[] = [];
  let missionSamplesNames: string[] = [];
  try {
    missionSamples = cleanSet(flatten(mission.getSampleSites().map((sel) => sel.getSamples())));
    missionSamples.sort((a, b) => {
      if (a.sample_id && b.sample_id) return a.sample_id.localeCompare(b.sample_id, 'en', { numeric: true });
      return 0;
    });
    missionSamples = missionSamples.filter((sample) => sample.bag_id !== '');
    let consecutiveSamples: number[][] = [];
    let [k, l] = [0, 0];
    for (const [i, sample] of missionSamples.entries()) {
      if (i !== 0) {
        if (Number.parseInt(sample.sample_id) - Number.parseInt(missionSamples[i - 1].sample_id) === 1) {
          l += 1;
        } else {
          consecutiveSamples.push([k, k + l]);
          [k, l] = [i, 0];
        }
      }
      if (i + 1 === missionSamples.length) {
        consecutiveSamples.push([k, k + l]);
      }
    }
    for (const [i, k] of consecutiveSamples) {
      if (i !== k) {
        missionSamplesNames.push(`${missionSamples[i].sample_id}-${missionSamples[k].sample_id}`);
      } else {
        missionSamplesNames.push(`${missionSamples[i].sample_id}`);
      }
    }
  } catch (e) {
    console.error(e);
  }

  return missionSamplesNames.join(', ');
}

/**
 * Obtains and orders samples for a given mission
 * @param {object} mission Data class instance of mission
 * @returns {array} Contains all of the samples in a given misison
 */
export function getAllSamples(this: Mission, include_skips = true) {
  return orderBy(flatten(this.getSampleSites().map((site) => site.getSamples())), ['order'], ['asc']).filter(
    (sample) => include_skips || !sample.skipped_or_deleted,
  );
}

/**
 * Get all sample boxes for this mission
 * @param this
 * @returns
 */
export function getAllSampleBoxes(this: Mission) {
  return this.getSampleSites().getSamples().getSampleBoxes();
}

/**
 * Get the total samples for the mission
 * @param {Mission} mission the mission object to get total samples for
 * @returns {number} the number of total samples
 */
export function getTotalSamples(mission: Mission) {
  let totalSamples = 0;
  if (mission) {
    const samples = mission.getAllSamples();
    totalSamples = samples.length;
  }
  return totalSamples;
}

/**
 * Finds sample given a sample id from a given mission
 * @param {string} sampleId The sample id of the sample to find
 * @param {number} missionId The data class instance id of the mission
 */
export function findSampleFromMission(sampleId: string, missionId: number) {
  const mission = Mission.get(missionId);
  if (!mission) {
    return [];
  }
  const allSamples = mission.getAllSamples();

  return allSamples.find((sample) => sample.sample_id === sampleId);
}

export function createSampleMap(samples: Sample[]): SampleErrorMap {
  const sampleMap: SampleErrorMap = {};
  if (!samples) {
    return sampleMap;
  }

  for (let sample of samples) {
    if (!sample.sample_id) continue;
    sampleMap[sample.sample_id] = {
      dupError: false,
      gapError: false,
      invalidError: false,
    };
  }

  return sampleMap;
}
