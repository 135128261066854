import { PureComponent } from 'react';
import withWidth, { isWidthDown, WithWidthProps } from '@material-ui/core/withWidth';
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  Button,
  FormControlLabel,
} from '@material-ui/core';

import ProgressBar from '../utils/ProgressBar';

import { MdFileUpload } from 'react-icons/md';
import Airtable, { AirtableIDLookup } from '../../airtable';

import { copyMap, distanceLatLon, fileToBase64, getUserPosition } from '../../utils';
import { uuidv4 } from '../../utils';
import logger from '../../logger';
import LoadingButton from '../utils/LoadingButton';
import {
  alertSuccess,
  alertWarn,
  alertError,
  alertConfirm,
  cancelConfirmTag,
  alertInfo,
  alertWarnConfirm,
} from '../../alertDispatcher';
import EventBus from '../../EventBus';
import { SESSION_EVENTS } from '../../sessionEvents';
import { getCurrentSession, getCurrentUser } from '../../dataModelHelpers';

import { KM_PER_MILE, MILES_PER_KM, MS_PER_S } from '../../constants';
import { AirtableRecord, SampleBox } from '../../db';
import {
  Dropoff,
  DropoffBoxMap,
  DropoffLocationDistance,
  DropoffSmartGroup,
  LabToBoxMap,
  LocationMap,
} from '../../types/types';
import { decodeBoxUID } from '../../db_ops/sample_box_ops';
import { extractBoxID } from '../../barcode_rules';
import { Coordinate } from 'ol/coordinate';
import { transferUpdatesForProcessing } from '../../db_ops/airtable_record_ops';
import { Box, BoxShipments, Dropoffs, Jobs, Labs, Team } from '@rogoag/airtable';
import DropoffFilter from './DropoffFilter';
import DirectionsIcon from '@material-ui/icons/Directions';
import { CustomCheckbox } from '../utils/CustomCheckbox';
import { UnknownDropoffDialog } from './UnknownDropoffDialog';
import { BypassDialog } from '../utils/BypassDialog';
import { ConfirmDialog } from '../utils';
import { ExcludedBoxesList } from './ExcludedBoxesList';
import { getDatabase } from '../../db/datamanager';
import getVersion from '../../version';
import { ScannedBox } from '../../db/types';
import { ShippingFlagsStore } from './ShippingFlagsStore';
import { DropoffSelection } from './DropoffSelection';
import { ShippingPhtotoCaptureUpload } from './ShippingPhotoCaptureOrUpload';
import { ShippingRefreshBoxes } from './ShippingRefreshBoxes';
import { PhotoCaptureModal } from './PhotoCaptureModal';
import { ShippingBarocdeScanner } from './ShippingBarcodeScanner';
import { ShippingLabGroup } from './ShippingLabGroup';
import TakePhotoOrUpload from './TakePhotoOrUpload';
import DirectionsButton from './DirectionsButton';
import { ShippingLabGroupCarded } from './ShippingLabGroupCarded';

interface ShippingViewProps {
  showVoidLabelPopup: () => void;
}

interface ShippingViewState {
  activeScan: boolean; // we are actively scanning for a barcode
  allLabDropoffLocations: LocationMap; // all possible lab dropoff locations
  anchors: {}; // used to store our page anchors when for dropoffs in our selection list
  dropoffInfo: DropoffBoxMap; // current mapping for dropoff locations and boxes
  dropoffPicture: Blob | undefined; // current dropoff location picture
  excludedBoxes: string[]; // boxes excluded from the shipping view
  expandedBoxes: string[]; // boxes that we want to be expanded in the shipping view
  group: DropoffSmartGroup; // our smart grouping of boxes
  loading: boolean; // page is actively loading
  loadingCapture: boolean; // page is actively loading picture capture window
  loadingScanner: boolean; // page is actively loading barcode scanner
  photoModalActive: boolean; // indiciates if the modal for capturing the dropoff location picture is active/shown
  submitting: boolean; // boolean to indicate if we are submitting, shows view modal spinner
  readingCode: boolean; // boolean to indicate if we are reading a barcode
  targetBoxIDForUPSLabel: string; // the ID we are looking for the UPS label for
  geoErrorCode: number | undefined;
  geoErrorMessage: string;
  cameraError: string; // the "Friendly" camera error we'll save for the user
  cameraDomError: string; // the underlying "DOM" error
  submitError: string; // error on submission
  submitErrorMessage: string; // detailed submission error
  filterText: string;
  unknownDropoff: boolean;
  pendingSubmit: boolean;
  dropoffNotes: string;
  notesDropoffVisible: boolean;
  bypassPopupVisible: boolean;
  onBypassClose: (bypassed: boolean) => void;
  rogoHqInfoVisible: boolean;
  labsPreferCourier: string[];
  scannedBoxIds: string[];
  captureTarget: string;
}

export interface ShippingFlags {
  notCloseToDropoff: boolean;
  shippedUpsForCourierPreferred: boolean;
}

type PropsWithWidth = ShippingViewProps & WithWidthProps;

class ShippingView extends PureComponent<PropsWithWidth, ShippingViewState> {
  processing: boolean;
  userPos: Coordinate;
  VALID_DISTANCE: number;
  COURIER_SWITCH_DISTANCE: number;
  RECALC_INTERVAL_MS: number;
  recalcTimeout: NodeJS.Timeout;
  SHIPPING_AIRTABLE_VIEW: string;

  constructor(props: ShippingViewProps) {
    super(props);

    this.state = {
      activeScan: false,
      allLabDropoffLocations: new Map<string, DropoffLocationDistance[]>(),
      anchors: {},
      dropoffInfo: new Map<string, string>(),
      dropoffPicture: undefined,
      excludedBoxes: [],
      expandedBoxes: [],
      group: new Map<string, Dropoff>(),
      loading: false,
      loadingCapture: false,
      loadingScanner: false,
      photoModalActive: false,
      submitting: false,
      readingCode: false,
      targetBoxIDForUPSLabel: '',
      geoErrorCode: undefined,
      geoErrorMessage: '',
      cameraError: '',
      cameraDomError: '',
      submitError: '',
      submitErrorMessage: '',
      filterText: '',
      unknownDropoff: false,
      pendingSubmit: false,
      dropoffNotes: '',
      notesDropoffVisible: false,
      bypassPopupVisible: false,
      labsPreferCourier: [],
      onBypassClose: () => {},
      rogoHqInfoVisible: false,
      scannedBoxIds: [],
      captureTarget: 'dropoff',
    };

    this.loadJobsForShipping = this.loadJobsForShipping.bind(this);
    this.loadBoxesForShipping = this.loadBoxesForShipping.bind(this);
    this.recalculateDistanceLoop = this.recalculateDistanceLoop.bind(this);
    this.submitBoxes = this.submitBoxes.bind(this);
    this.switchDropoff = this.switchDropoff.bind(this);
    this.excludeBox = this.excludeBox.bind(this);
    this.includeBox = this.includeBox.bind(this);
    this.makeActiveOrInactive = this.makeActiveOrInactive.bind(this);
    this.updateDetectedCode = this.updateDetectedCode.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
    this.expandBox = this.expandBox.bind(this);
    this.collapseBox = this.collapseBox.bind(this);
    this.checkForLast = this.checkForLast.bind(this);
    this.onUploadedBoxPictureChange = this.onUploadedBoxPictureChange.bind(this);
    this.switchToCourier = this.switchToCourier.bind(this);
    this.onNoScanOrPicExcuseChange = this.onNoScanOrPicExcuseChange.bind(this);
    this.getBoxesFromGroup = this.getBoxesFromGroup.bind(this);

    this.SHIPPING_AIRTABLE_VIEW = 'App View - Boxes Ready for Shipping';
    // For testing purposes:
    // this.SHIPPING_AIRTABLE_VIEW = 'Sergeis - all boxes';
    this.processing = false;

    this.VALID_DISTANCE = KM_PER_MILE / 2; // a valid distance is 1/2 mile. We use km for all GPS distance calcs
    this.COURIER_SWITCH_DISTANCE = KM_PER_MILE * 2;
    this.RECALC_INTERVAL_MS = 15 * MS_PER_S; // 15 seconds is our recalc interval
  }

  async componentDidMount() {
    await this.loadBoxesForShipping(true);
    EventBus.on(SESSION_EVENTS.UPDATED, this.loadBoxesForShipping);

    const db = getDatabase();

    const boxesScanned = await db.ScannedBoxes.toArray();
    this.setState({ scannedBoxIds: boxesScanned.map((box) => box.box_id) });
  }

  componentWillUnmount() {
    cancelConfirmTag(this.constructor.name);
    EventBus.remove(SESSION_EVENTS.UPDATED, this.loadBoxesForShipping);
    clearTimeout(this.recalcTimeout);
  }

  /**
   * Gets all the boxes ready for shipping
   * @param   {boolean} forceRefresh - Whether or not to force refresh
   * @returns {Promise<AirtableRecord[]>} array of valid boxes
   */
  async getValidBoxes(forceRefresh: boolean) {
    const session = getCurrentSession();
    if (!session) return [];
    const username = session.username;

    const boxSearch = await Airtable.search<Box>('Box', this.SHIPPING_AIRTABLE_VIEW, `{User ID} = '${username}'`);
    if (!boxSearch.getRecords().length || forceRefresh) {
      console.log('REFRESHING BOX SEARCH');
      await boxSearch.refresh();
    }
    const boxes = boxSearch.getRecords();

    console.log(`Found ${boxes.length} boxes prior to filtering`);

    return boxes;
  }

  /**
   *
   * @param boxes
   * @param dropoffInfo
   * @param allLabDropoffLocations
   * @returns
   */
  async smartGroup(
    boxes: AirtableRecord[],
    dropoffInfo: DropoffBoxMap,
    allLabDropoffLocations: Map<string, DropoffLocationDistance[]>,
  ) {
    /* Groups boxes by dropoff which is determined by lab. Tries to find nearest dropoff */
    const group: DropoffSmartGroup = new Map<string, Dropoff>();
    for (let box of boxes) {
      const labName: string = (box.get('Lab Name').replace('/', '-') || 'No Lab Provided').replace(/"/g, '');
      const locationKey: string | undefined = dropoffInfo.get(labName);

      if (!locationKey) {
        continue;
      }
      let dropoffs = allLabDropoffLocations.get(locationKey);

      // TODO this should be better clarified as a property
      if (!dropoffs) {
        continue;
      }
      const nearestDropoff = dropoffs[0];

      // TODO attempt to fix field issue related to selecting nearby dropoffs
      if (!nearestDropoff) {
        continue;
      }

      const dropoffName = nearestDropoff?.record.get('Name') || 'Unknown Dropoff';

      let dropoff = group.get(dropoffName);
      if (!dropoff) {
        dropoff = { labBoxes: new Map<string, AirtableRecord[]>() } as Dropoff;
        group.set(dropoffName, dropoff);
      }

      let labDropoffs = dropoff.labBoxes.get(labName);
      if (!dropoff.labBoxes?.get(labName)) {
        labDropoffs = [];
        dropoff.labBoxes.set(labName, labDropoffs);
      }

      if (nearestDropoff.distance && this.VALID_DISTANCE > nearestDropoff.distance) {
        dropoff.valid = true;
      } else {
        dropoff.valid = false;
      }
      dropoff.distance = nearestDropoff.distance ? nearestDropoff.distance : undefined; // convert to miles for UI

      // TODO dropoff.labs.get(labName) should be an array and souldn't be undefined...
      // can we use dropoffLabs from earlier? Is the reference stored in the map or is it copied/stored by value?
      dropoff.labBoxes.get(labName)?.push(box);
      dropoff.method = locationKey;
      dropoff.id = nearestDropoff?.record?.id;
    }

    // once we have all the distnaces and nearest dropoffs selected, and potential dropoffs being made active
    // we can now sort the active dropoffs by distance and then only keep the closest one active
    const closestDropoffs: [string, number | undefined][] = [];
    for (const dropoffName of group.keys()) {
      const dropoff = group.get(dropoffName);
      if (dropoff?.valid) {
        closestDropoffs.push([dropoffName, dropoff.distance]);
      }
    }

    closestDropoffs.sort((a, b) => {
      if (!a[1] || !b[1]) {
        return 0;
      }

      return a[1] - b[1];
    });

    const closestActiveDropoffGroup = closestDropoffs[0];

    if (closestActiveDropoffGroup) {
      for (const dropoffName of group.keys()) {
        const dropoff = group.get(dropoffName);
        if (closestActiveDropoffGroup.length > 0 && closestActiveDropoffGroup[0] !== dropoffName && dropoff) {
          dropoff.valid = false;
        }
      }
    }

    return group;
  }

  async handleCapture(capturedImage: Blob) {
    await logger.log('HANDLE_CAPTURE', 'Dropoff picture taken');
    this.setState({ dropoffPicture: capturedImage, photoModalActive: false });
  }

  async onUploadedBoxPictureChange(boxId: string, image: File | undefined) {
    await logger.log('SHIPPING - UPLOAD BOX PICTURE');

    if (boxId && image) {
      await this.updateDetectedCode(boxId, image, true);
    }

    this.setState({ photoModalActive: false });
  }

  async onNoScanOrPicExcuseChange(box: AirtableRecord<Box>, excuse: string | undefined) {
    if (excuse && !(await alertConfirm('Do you confirm that you cannot scan or take/upload a picture for this box?'))) {
      return;
    }

    const boxCode = box.get('Box ID');
    if (!boxCode) {
      return;
    }

    await logger.log('SHIPPING - NO SCAN EXCUSE', { boxCode, excuse });

    box.set('Box Ops Notes', excuse);
    this.setState({ group: copyMap(this.state.group) });

    if (excuse) {
      await this.updateDetectedCode(boxCode, '', true);
    } else {
      const db = getDatabase();
      await db.ScannedBoxes.delete(boxCode);
      const boxesScanned = await db.ScannedBoxes.toArray();

      this.setState({ scannedBoxIds: boxesScanned.map((box) => box.box_id) });
    }
  }

  async createBoxShipment(userPos: Coordinate, boxRecordsIds: string[]): Promise<AirtableRecord<BoxShipments>> {
    const imageName = uuidv4();
    const { notCloseToDropoff, shippedUpsForCourierPreferred } = ShippingFlagsStore.get();

    const flags: ('Not Close To Dropoff' | 'Forced UPS')[] = [];
    if (notCloseToDropoff) {
      flags.push('Not Close To Dropoff');
    }

    if (shippedUpsForCourierPreferred) {
      flags.push('Forced UPS');
    }
    const db = getDatabase();
    const boxesScanned = await db.ScannedBoxes.toArray();

    await logger.log('HANDLE_IMAGE_UPLOAD', 'Uploading dropoff picture');
    await logger.log(
      'HANDLE_IMAGE_UPLOAD',
      `Boxes scanned: ${JSON.stringify(
        boxesScanned.map((scannedBox) => ({
          box_id: scannedBox.box_id,
          timestamp: scannedBox.timestamp,
        })),
      )}`,
    );

    // Creating a new box shipment (first AT API call)
    const boxShipmentRecord = await Airtable.createRecord<BoxShipments>(AirtableIDLookup['Box Shipments'].id, {
      Name: imageName,
      Notes: this.state.dropoffNotes,
      Box: boxRecordsIds,
      'Box Count': boxRecordsIds.length,
      'Shipment Flags': flags,
      'Exact Lat': userPos ? userPos[0] : undefined,
      'Exact Lon': userPos ? userPos[1] : undefined,
      Hostname: `${window.location.hostname}, ${getVersion()}`,
    } as BoxShipments); // TODO forcing this to be a BoxShipments type but it's really a Partial<BoxShipments> type...

    const attachment = await boxShipmentRecord.new_attachment('Image');
    if (!this.state.dropoffPicture) {
      throw new Error('No dropoff picture to upload!');
    }
    await attachment.write(`${imageName}`, `png`, this.state.dropoffPicture);

    // Updating the box shipment with the attachment knowing the newly-created record id (second AT API Call)
    await boxShipmentRecord.sync();

    return boxShipmentRecord as AirtableRecord<BoxShipments>;
  }

  handleBoxImageUploads = async (box: AirtableRecord, imageDataB64?: string, deferSync = true) => {
    if (!imageDataB64) {
      return box;
    }
    const attachment = await box.new_attachment('Image');
    // convert base64 image data to Blob
    const blob = await fetch(imageDataB64).then((r) => r.blob());
    await attachment.write(box.get('Box ID'), `png`, blob);
    if (!deferSync) {
      await box.sync();
    }
    return box;
  };

  /**
   * Switch from UPS drop for a box group to a Courier drop
   * @param {string} dropoffName - dropoff name to switch from
   * @param {string} labName - lab name to switch to
   */
  async switchToCourier(dropoffName: string, labName: string) {
    const group = copyMap(this.state.group);
    const dropoffInfo = this.state.dropoffInfo;
    const initialDropoff = this.state.allLabDropoffLocations.get(labName)?.[0];
    if (initialDropoff) {
      const initialDropoffName = initialDropoff.record.get('Name');
      group.set(initialDropoffName, {
        distance: initialDropoff.distance,
        labBoxes: new Map<string, AirtableRecord[]>(),
        method: labName,
        id: initialDropoff.record.id,
        valid: false,
      } as Dropoff);

      // copy lab data to new dropoff
      group.get(initialDropoffName)?.labBoxes.set(labName, group.get(dropoffName)?.labBoxes?.get(labName) ?? []);

      group.get(dropoffName)?.labBoxes.delete(labName);
      if (group.get(dropoffName)?.labBoxes.size === 0) {
        group.delete(dropoffName);
      }

      dropoffInfo.set(labName, labName);
      this.setState({ group, dropoffInfo });
    } else {
      alertWarn('Can not switch dropoff to courier, no courier locations available!');
    }
  }

  /**
   * Switch a box group assigned to a courier to UPS
   * @param {string} dropoffName - Key of the current group to switch from
   * @param {string} labName - Key used to point lab in dropoff to another lab
   */
  async switchToUPS(dropoffName: string, labs: LabToBoxMap) {
    const group = copyMap(this.state.group);
    const courierPreferred: string[] = [];
    for (const labName of labs.keys()) {
      // get airtable record
      const airtableLabs = await this.getAllLabs(false); // get the airtable records
      const labRecord = airtableLabs.find((lab) => lab.get('Name') === (labName || 'No Lab Provided'));
      if (labRecord) {
        const deliveryMethod = labRecord.get('Primary Delivery Method');
        const name = labRecord.get('Name');
        if (deliveryMethod !== 'UPS') {
          courierPreferred.push(name);
        }
      }
    }

    if (courierPreferred.length > 0) {
      const popupPromise = new Promise<boolean>((resolve, reject) => {
        this.setState({
          bypassPopupVisible: true,
          labsPreferCourier: courierPreferred,
          onBypassClose: (result: boolean) => {
            this.setState({ bypassPopupVisible: false, labsPreferCourier: [] });
            resolve(result);
          },
        });
      });
      const shouldStillSwitch = await popupPromise;
      if (!shouldStillSwitch) {
        return; // cancel change
      }
    }
    const dropoffInfo = this.state.dropoffInfo;
    for (const lab in labs.keys()) {
      dropoffInfo[lab] = 'UPS';
    }

    // check if we have a current UPS group
    let currentUPS: string | undefined = undefined;
    for (const [key, value] of group.entries()) {
      if (value.method === 'UPS') {
        currentUPS = key;
        break;
      }
    }
    if (currentUPS) {
      // if we do, then set the boxes on this dropoff group
      group.get(dropoffName)?.labBoxes.forEach((boxes, lab) => group.get(currentUPS)?.labBoxes.set(lab, boxes));
    } else {
      // otherwise create a new UPS group
      // TODO we are forcing this to not be undefined but is that true?
      const newUPS = this.state.allLabDropoffLocations.get('UPS')?.[0]!;
      const newUPSName = newUPS.record.get('Name');
      group.set(newUPSName, {
        distance: newUPS.distance,
        // TODO we are forcing this to not be undefined but is that true?
        labBoxes: copyMap(group.get(dropoffName)!.labBoxes),
        method: 'UPS',
        id: newUPS.record.id,
        valid: true,
      });
    }
    group.delete(dropoffName);
    this.setState({ group, dropoffInfo });
  }

  /**
   *
   * @param   {number} distance
   * @returns True if the distance is valid (within 1/2 mile right now)
   */
  isValidDistance(distance: number) {
    if (distance && this.VALID_DISTANCE > distance) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Find possible dropoffs given a set of labs
   * @param {array} boxLabs - An array of boxes that are ready to ship
   * @returns {object} - An object containing the closest locations for the given labs and airtable records + distances
   */
  async findDropoffs(boxLabs: string[], forceRefresh: boolean) {
    let userPos: Coordinate | undefined;
    try {
      userPos = await getUserPosition();
      logger.log('FIND_DROPOFFS', `User position: ${userPos}`);
    } catch (err) {
      let message = 'Unknown error getting user location!';
      if (err && typeof err === 'object' && 'message' in err && 'code' in err) {
        const geoError = err as GeolocationPositionError;
        if (geoError.code === GeolocationPositionError.PERMISSION_DENIED) {
          message = 'Please turn on permissions for location!';
        } else if (geoError.code === GeolocationPositionError.POSITION_UNAVAILABLE) {
          message = 'Unable to get your location, please try again later!';
        } else if (geoError.code === GeolocationPositionError.TIMEOUT) {
          message = 'Timeout trying to get location';
        }
        this.setState({ geoErrorCode: geoError.code, geoErrorMessage: geoError.message });
      }

      alertWarn(message);
    }

    const allLabDropoffLocations = new Map<string, DropoffLocationDistance[]>();
    const dropoffInfo = new Map<string, string>(); // TODO: name something more intuitive?

    const airtableLabs = await this.getAllLabs(forceRefresh); // get the airtable records

    // search for non ups dropoffs so we can get the records later
    await Airtable.search<Dropoffs>('Dropoffs', 'App View - Non UPS', '', true);

    allLabDropoffLocations.set('UPS', await this.getUpsDropoffs(userPos, forceRefresh));

    const setDropoffInfo = async (boxLab: string) => {
      // there is an implicit assumption that every box will match with a lab record
      // in typescript strict mode, we would be forced to deal with this box that has no lab record...
      const labRecord = airtableLabs.find((lab) => lab.get('Name') === (boxLab || 'No Lab Provided'));
      if (!labRecord) {
        await logger.log('FIND_DROPOFFS', `Warning: We couldn't find lab ${boxLab}, skipping...`);
        return;
      }

      const deliveryMethod = labRecord.get('Primary Delivery Method');
      const courierDropoffs = await this.getCourierDropoffs(labRecord, userPos, forceRefresh);
      allLabDropoffLocations.set(boxLab, courierDropoffs);
      if (deliveryMethod === 'UPS') {
        dropoffInfo.set(boxLab, 'UPS');
      } else {
        dropoffInfo.set(boxLab, boxLab);
      }
    };

    for (let boxLab of boxLabs) {
      await setDropoffInfo(boxLab);
    }

    return { allLabDropoffLocations, dropoffInfo };
  }

  private async getAllLabs(forceRefresh: boolean) {
    const labSearch = await Airtable.search<Labs>('Labs', 'App View - Labs', ''); // search for all of the labs
    if (!labSearch.getRecords().length || forceRefresh) {
      console.log('REFRESHING LAB SEARCH');
      await labSearch.refresh();
    }
    const airtableLabs = await labSearch.getRecords(); // get the airtable records
    return airtableLabs;
  }

  /**
   * Recalculate distance at 15 second interval as long as user postion is obtainable
   */
  async recalculateDistanceLoop() {
    let userPos: Coordinate | undefined = undefined;
    try {
      userPos = await getUserPosition();
      logger.log('RECALCULATE_DISTANCE_LOOP', `User position: ${userPos}`);
    } catch (err) {
      // ignore if the user denies location so we keep retrying
    }

    let _locations: LocationMap | undefined = undefined;
    if (userPos && userPos.length) {
      _locations = this.state.allLabDropoffLocations;
      for (const locationKey of _locations.keys()) {
        const locations = _locations.get(locationKey) ?? [];
        for (const location of locations) {
          if (location) {
            location.distance = this.getDropoffDistance(location.record, userPos);
          }
        }
      }
    }

    if (_locations?.size) {
      this.setState({ allLabDropoffLocations: _locations }, () => {
        this.recalcTimeout = setTimeout(this.recalculateDistanceLoop, this.RECALC_INTERVAL_MS);
      });
    } else {
      this.recalcTimeout = setTimeout(this.recalculateDistanceLoop, this.RECALC_INTERVAL_MS);
    }
  }

  /**
   * Gets all of the UPS dropoffs as well as the distances relative to the user
   * @param {array|null} userPos - Array containing user position [lat, lon]
   * @returns {array} upsDropoffLocations - Array of objects with relative distance and airtable record instance
   */
  async getUpsDropoffs(userPos: Coordinate | undefined, forceRefresh: boolean) {
    const upsDropoffLocations: DropoffLocationDistance[] = [];
    const upsSearch = await Airtable.search<Dropoffs>('Dropoffs', 'App View - UPS', '');
    if (!upsSearch.getRecords().length || forceRefresh) {
      console.log('REFRESHING UPS SEARCH');
      await upsSearch.refresh();
    }
    const upsDropoffs = upsSearch.getRecords();
    for (let upsDropoff of upsDropoffs) {
      const distance = userPos ? this.getDropoffDistance(upsDropoff, userPos) : undefined;
      upsDropoffLocations.push({ distance, record: upsDropoff });
    }

    return upsDropoffLocations.sort((a, b) => {
      if (a.distance === undefined || b.distance === undefined) {
        return 0;
      }
      return a.distance - b.distance;
    });
  }

  async getCourierDropoffs(labRecord: AirtableRecord, userPos: Coordinate | undefined, forceRefresh: boolean) {
    const labDropoffLocations: DropoffLocationDistance[] = [];
    const labDropoffsIds = labRecord.get('Dropoffs/Shipping (Active)') || [];

    const courierDropoffSearch = await Airtable.search<Dropoffs>('Dropoffs', 'App View - Non UPS', '');
    if (!courierDropoffSearch.getRecords().length || forceRefresh) {
      console.log('REFRESHING NON UPS SEARCH');
      await courierDropoffSearch.refresh();
    }

    const courierDropoffs = courierDropoffSearch.getRecords();

    for (let labDropoffId of labDropoffsIds) {
      const labDropoff = courierDropoffs.find((dropOff) => dropOff.id === labDropoffId);

      if (!labDropoff) {
        await logger.log('GET_COURIER_DROPOFFS', `Warning: We couldn't retrieve dropoff ${labDropoffId}, skipping...`);
        continue;
      }

      const distance = userPos ? this.getDropoffDistance(labDropoff, userPos) : undefined;
      labDropoffLocations.push({ distance, record: labDropoff });
    }

    return labDropoffLocations.sort((a, b) => {
      if (a.distance === undefined || b.distance === undefined) {
        return 0;
      }
      return a.distance - b.distance;
    });
  }

  getDropoffDistance(dropoff: AirtableRecord, userPos: void | Coordinate) {
    if (userPos && dropoff) {
      const dropoffLat = dropoff.get('Dropoff Lat');
      const dropoffLon = dropoff.get('Dropoff Lon');
      return distanceLatLon(userPos[0], userPos[1], dropoffLat, dropoffLon, 'Kilometers') * MILES_PER_KM;
    } else {
      return undefined;
    }
  }

  handleLoadFailure(err: unknown) {
    if (err && typeof err === 'object' && 'message' in err && 'name' in err) {
      const domError = err as DOMException;
      let error = '';
      if (domError.name === 'NotAllowedError') {
        error = "You didn't allow camera permissions.";
      } else if (domError.name === 'NotFoundError') {
        error = 'No camera was found.';
      }
      alertError(error);
      this.setState({ cameraError: error });
    } else {
      alertError('Something went wrong while loading video!');
    }

    if (this.state.photoModalActive) {
      this.setState({ loadingCapture: false, photoModalActive: false });
    } else {
      this.setState({ loadingScanner: false, activeScan: false, targetBoxIDForUPSLabel: '' });
    }
  }

  /**
   * Appends box to array and saves boxes scanned to localstorage
   * @param   {string} boxId - Box ID to save
   * @returns {string[]}     - Returns array of boxes scanned
   */
  async saveBoxScanned(boxId: string, image: string, override: boolean = false, boxOpsNotes: string = '') {
    // make sure this box ID doesn't already exist in indexeddb
    const db = getDatabase();
    const alreadyScanned = await db.ScannedBoxes.get(boxId);

    if (!override && alreadyScanned) {
      alertWarn(`Box ${boxId} has already been scanned!`);

      return;
    } else {
      // This will override a box if it already exists.
      db.ScannedBoxes.put({ box_id: boxId, timestamp: new Date(), image: image, boxOpsNotes: boxOpsNotes });
    }

    const boxesScanned = await db.ScannedBoxes.toArray();

    this.setState({ scannedBoxIds: boxesScanned.map((box) => box.box_id) });

    return boxesScanned;
  }

  async clearBoxesScanned() {
    const db = getDatabase();
    await db.ScannedBoxes.clear();
  }

  getBoxGroup(boxid: string) {
    if (this.state.group) {
      for (const dropoffName of this.state.group.keys()) {
        const dropoff = this.state.group.get(dropoffName);
        if (dropoff?.labBoxes) {
          for (let lab of this.state.group.get(dropoffName)?.labBoxes?.keys() ?? []) {
            const boxes = this.state.group.get(dropoffName)?.labBoxes.get(lab);
            if (boxes?.some((box) => box.get('Box ID') === boxid)) {
              return dropoff;
            }
          }
        }
      }
    }
  }

  async makeActiveOrInactive(dropoffName: string) {
    const group = copyMap(this.state.group);
    const dropoff = group.get(dropoffName);
    const active = !!dropoff?.valid;

    if (active) {
      // if unactivating, just make the switch
      dropoff.valid = false;
    } else {
      // if activating, iterate through all dropoffs and check for any that are already active
      const distance = dropoff?.distance;
      if (distance && distance > this.VALID_DISTANCE) {
        const confirmNotClose = await alertWarnConfirm(
          'You are not close to this dropoff (> 1/2 mile). Are you sure this is the right dropoff?',
        );
        logger.log(
          'MAKE_ACTIVE',
          `Selected Dropoff: ${dropoffName}, distance: ${dropoff.distance}, confirmNotClose: ${confirmNotClose}`,
        );
        if (!confirmNotClose) {
          return;
        }
      }

      if (dropoff?.method === 'UPS') {
        // also search for a close courier for the lab?
        const labName = this.state.group.get(dropoffName)?.labBoxes.keys().next().value as string;
        const labCourierLocations = this.state.allLabDropoffLocations?.get(labName)?.sort((a, b) => {
          if (!a.distance || !b.distance) {
            return 0;
          }

          return a.distance - b.distance;
        });
        if (labCourierLocations && labCourierLocations.length > 0) {
          const closestLocation = labCourierLocations[0];
          const distance = closestLocation.distance;
          if (distance && distance < this.COURIER_SWITCH_DISTANCE) {
            alertWarn(
              'There is a courier location within 2 miles of you for this lab. This should be preferred over UPS if possible.',
            );
          }
        }
      }

      for (let _dropoff of group.values()) {
        if (_dropoff.valid) {
          const switchDropoffs = await alertWarnConfirm(
            'You already have an active dropoff. You can only have one active dropoff at a time. Do you want to make this dropoff active instead?',
          );
          logger.log('MAKE_ACTIVE', `Selected Dropoff: ${dropoffName}, switchDropoffs: ${switchDropoffs}`);
          if (!switchDropoffs) {
            return;
          }
          _dropoff.valid = false;
        }
      }
      if (dropoff) {
        dropoff.valid = true;
      }
    }
    this.setState({ group });
  }

  /**
   * Extracts all of the valid boxes
   * @returns {AirtableRecord[]} Boxes that are apart of the active dropoff
   */
  getGroupedBoxes() {
    let groupedBoxes: AirtableRecord<Box>[] = [];
    if (this.state.group) {
      for (const dropoffName of this.state.group.keys()) {
        const dropoff = this.state.group.get(dropoffName);
        if (!dropoff) continue;
        if (dropoff.valid && dropoff.labBoxes) {
          const dropoffGroup = this.state.group.get(dropoffName);
          if (!dropoffGroup) continue;
          for (let labName of dropoffGroup.labBoxes.keys()) {
            const box = dropoffGroup.labBoxes.get(labName);
            if (!box) {
              continue;
            }
            groupedBoxes = groupedBoxes.concat(box);
          }
        }
      }
    }

    groupedBoxes = groupedBoxes.filter((box) => !this.state.excludedBoxes.includes(box.get('Box ID')!));

    return groupedBoxes;
  }

  /**
   * Callback for decoder
   * @param {string} detectedCode - The code detected by scanner
   */
  async updateDetectedCode(detectedCode: string, rawBytes: ArrayBuffer | File | string, override = false) {
    await logger.log('UPDATE_DETECTED_CODE - start', { detectedCode, override });
    const millisecondsStart = new Date().getTime();

    if (this.state.readingCode) {
      await logger.log('UPDATE_DETECTED_CODE - end - already reading code', {
        ms_spent: new Date().getTime() - millisecondsStart,
      });

      return;
    }

    this.setState({ readingCode: true });

    if (Boolean(this.state.targetBoxIDForUPSLabel)) {
      await this.handleUPSShipment(detectedCode);

      await logger.log('UPDATE_DETECTED_CODE - end - handled UPS shipment', {
        ms_spent: new Date().getTime() - millisecondsStart,
      });

      return;
    }

    const groupedBoxes = this.getGroupedBoxes();
    const validBox = groupedBoxes.find((box) => {
      const boxCode = box.get('Box ID');
      if (!boxCode) {
        return undefined;
      }

      // console.log(`box.get('Box ID')=${boxCode}, detectedCode=${detectedCode}, ${detectedCode.includes(boxCode)}`);

      return detectedCode.includes(boxCode);
    });

    if (!validBox && this.findBoxInInactiveDropoffs(detectedCode)) {
      await logger.log('UPDATE_DETECTED_CODE - end - no validBox && found box in inactive dropoffs', {
        ms_spent: new Date().getTime() - millisecondsStart,
      });

      return;
    }

    if (!validBox) {
      await this.handleAlienBox(detectedCode, rawBytes, groupedBoxes);
      this.setState({ readingCode: false });

      await logger.log('UPDATE_DETECTED_CODE - end - handled alien box', {
        ms_spent: new Date().getTime() - millisecondsStart,
      });

      return;
    }

    const boxId = validBox.get('Box ID')!;
    await logger.log('UPDATE_DETECTED_CODE', `Box ID scanned ${boxId}`);
    const db = getDatabase();
    const boxesScanned = await db.ScannedBoxes.toArray();
    if (!override && boxesScanned.find((boxScannedID) => boxScannedID.box_id === boxId)) {
      alertError('This QR code was already scanned!');
      this.setState({ readingCode: false });

      await logger.log('UPDATE_DETECTED_CODE - end - QR already scanned', {
        ms_spent: new Date().getTime() - millisecondsStart,
      });

      return;
    }

    alertSuccess(`Box ${boxId} Scanned!`);

    // get the dropoff for this box
    const boxGroup = this.getBoxGroup(boxId);
    if (!boxGroup) {
      alertError(`Unable to find dropoff for box ${boxId}`);
      this.setState({ readingCode: false });

      await logger.log('UPDATE_DETECTED_CODE - end - no box group', {
        ms_spent: new Date().getTime() - millisecondsStart,
      });

      return;
    }

    if (boxGroup.method !== 'UPS') {
      // alert the user to void the shipping label
      this.props.showVoidLabelPopup();
    } else {
      await this.prepareForUPSShipment(validBox, boxId);
    }

    const scannedBoxesRefreshed = await this.saveBoxScanned(
      boxId,
      typeof rawBytes === 'string' ? rawBytes : await fileToBase64(rawBytes),
      override,
      validBox.get('Box Ops Notes'),
    );

    if (!scannedBoxesRefreshed) {
      this.setState({ readingCode: false });

      await logger.log('UPDATE_DETECTED_CODE - end - no refreshed scanned boxes', {
        ms_spent: new Date().getTime() - millisecondsStart,
      });

      return;
    }

    if (this.allBoxesScanned(groupedBoxes, scannedBoxesRefreshed)) {
      this.setState({ activeScan: false });
    }

    this.setState({ readingCode: false });

    await logger.log('UPDATE_DETECTED_CODE - end!', {
      ms_spent: new Date().getTime() - millisecondsStart,
    });
  }

  private findBoxInInactiveDropoffs(detectedCode: string): boolean {
    // Search for any boxes that might not be at an active dropoff...
    // if we find one, warn the user that they need to set that box dropoff to active first

    // Box https://lab.rogoag.com/9u9l58p014 is not at the active dropoff. Please only scan boxes for your active dropoff
    const boxId = detectedCode.split('/').pop(); // parse out the ID from the URL
    if (this.getBoxesFromGroup().filter((box) => boxId === box.get('Box ID')).length > 0) {
      alertWarn(`Box ${boxId} is not at your active dropoff. Please only scan boxes for your active dropoff`);
      this.setState({ readingCode: false });

      return true;
    }

    return false;
  }

  private async handleUPSShipment(detectedCode: string) {
    // check if valid UPS label
    // if valid, update box with UPS label
    // if not valid, warn user
    // clear targetBoxIDForUPSLabel
    // validate with regex 1Z[A-Z0-9]{16}

    const validUPSLabel = detectedCode.match(/1Z[A-Z0-9]{16}/);
    if (!validUPSLabel) {
      // only exit (set targetBoxIDForUPSLabel to "") if a valid UPS label, otherwise warn user
      alertWarn('Invalid UPS label! Please try again.');
      this.setState({ readingCode: false });

      return;
    }

    const boxSearch = await Airtable.search('Box', '', `{Box ID} = '${this.state.targetBoxIDForUPSLabel}'`, true);
    const records = boxSearch.getRecords();
    if (records.length !== 1) {
      alertError(`Unable to find retrieve box ${this.state.targetBoxIDForUPSLabel}. Please try again.`);
      this.setState({ readingCode: false, targetBoxIDForUPSLabel: '' });

      return;
    }

    const box = records[0];
    await box.set('Tracking Number', detectedCode);
    await box.sync();

    alertSuccess(`Successfully added tracking number ${detectedCode} to box ${this.state.targetBoxIDForUPSLabel}`);

    this.setState({ readingCode: false, targetBoxIDForUPSLabel: '' });
  }

  private allBoxesScanned(groupedBoxes: AirtableRecord<Box>[], scannedBoxesRefreshed: ScannedBox[]) {
    return (
      groupedBoxes.filter((box) =>
        Object.keys(scannedBoxesRefreshed).find((scannedBoxID) => scannedBoxID === box.get('Box ID')),
      ).length === groupedBoxes.length
    );
  }

  private async prepareForUPSShipment(validBox: AirtableRecord<Box>, boxId: string) {
    // if the box is being shipped via UPS and we DON'T have a label for it,
    // then we will prompt them to scan it if it is already present
    // or to add a label and then scan it
    const trackingNumber = validBox.get('Tracking Number');
    if (trackingNumber) {
      return;
    }

    const result = await alertConfirm(
      'The box you just scanned is being shipped via UPS. Does it already have a label?',
    );
    // if no, then prompt to add a label
    if (!result) {
      alertInfo('Please add a label to the box and then scan the barcode.');
    } else {
      alertInfo('Please scan the UPS barcode on the box');
    }
    this.setState({ targetBoxIDForUPSLabel: boxId });
  }

  private async handleAlienBox(
    detectedCode: string,
    rawBytes: string | ArrayBuffer | File,
    groupedBoxes: AirtableRecord<Box>[],
  ) {
    try {
      const boxUID = extractBoxID(detectedCode);
      if (!boxUID) {
        throw Error(`Unable to decode Box UID from ${detectedCode}`);
      }
      const { userId, labCode } = decodeBoxUID(boxUID);

      const boxUser = AirtableRecord.findOne<AirtableRecord<Team>>(
        (record) => !!record && record.table === 'Team' && record.get('App User ID') === userId,
      );

      if (!boxUser) {
        throw Error(`Error: Cannot find a user with {App User ID} === {${userId}}`);
      }

      const labRecord = AirtableRecord.findOne<AirtableRecord<Labs>>(
        (record) => !!record && record.table === 'Labs' && record.get('Lab Code Gen') === labCode,
      );

      if (!labRecord) {
        throw Error(`Error: Cannot find a lab with {Lab Code} === {${labCode}}`);
      }

      const boxSearch = await Airtable.search(
        'Box',
        'App View - Boxes Ready for Shipping',
        `{Box ID} = '${boxUID}'`,
        true,
      );

      const boxRecords = boxSearch.getRecords();

      const currentUser = getCurrentUser();

      if (!currentUser) {
        throw Error('Error: No current user found');
      }

      // Option 1: if the box does not exist under any user in the shipping view, go ahead and create it
      if (boxRecords.length === 0) {
        console.log(`Creating a new box with id ${boxUID} and User ID ${currentUser.name}`);

        await Airtable.createRecord('Box', {
          'Box ID': boxUID,
          'Lab Name': labRecord.get('Name'),
          'User ID': currentUser.name,
          Jobs: '',
          Samples: '',
        });
      }

      // Option 2: if the box exists and the user code is different from ours
      else {
        const boxRecord = boxRecords[0];
        const boxUserID = boxRecord.get('User ID');
        if (boxUserID !== currentUser.name) {
          console.log(`Updating box USER ID from ${boxUserID} to ${currentUser.name}`);

          await boxRecord.set('User ID', currentUser.name);
          await boxRecord.sync();
        }
      }

      // use this to just refresh everything
      await this.loadBoxesForShipping(true);

      const boxesScanned = await this.saveBoxScanned(
        boxUID,
        typeof rawBytes === 'string' ? rawBytes : await fileToBase64(rawBytes),
      );

      if (!boxesScanned) {
        throw Error('Error: Unable to save box to scanned boxes');
      }

      const isLast =
        groupedBoxes.filter((box) =>
          Object.keys(boxesScanned).find((scannedBoxID) => scannedBoxID === box.get('Box ID')),
        ).length === groupedBoxes.length;

      if (isLast) {
        this.setState({ activeScan: false });
      }
    } catch (err) {
      function errIsError(err: any): err is Error {
        return 'message' in err;
      }

      if (errIsError(err)) {
        alertError(err.message);
      }
      alertError('The QR code scanned does not match any boxes at this location.');
    }
  }

  getBoxesFromGroup(): AirtableRecord[] {
    const allLabBoxes = Array.from(this.state.group.values()).reduce((acc, entry) => {
      const labBoxesMap = entry.labBoxes;

      const boxes = Array.from(labBoxesMap.values()).flat(); // Flatten arrays inside labBoxes

      return acc.concat(boxes);
    }, [] as AirtableRecord[]);

    return allLabBoxes;
  }

  /**
   * Loads in Boxes as well as the dropoff locations
   */
  async loadBoxesForShipping(forceRefresh = false) {
    const db = getDatabase();
    const boxesScanned = await db.ScannedBoxes.toArray();

    this.setState({ loading: true, scannedBoxIds: boxesScanned.map((box) => box.box_id) });

    try {
      const validBoxes = (await this.getValidBoxes(forceRefresh)).map((box) => {
        const scannedBox = boxesScanned.find((boxScanned) => boxScanned.box_id === box.get('Box ID'));
        if (!scannedBox) {
          // Setting notes to empty string because non-empty "Box Ops Notes"
          // means that a box should be in the scanned boxes list already
          box.set('Box Ops Notes', '');
        } else {
          box.set('Box Ops Notes', scannedBox.boxOpsNotes);
        }

        return box;
      });

      console.log(`Got ${validBoxes.length} valid boxes for shipping`);

      const boxLabs = Array.from(
        new Set(
          validBoxes.map((box) =>
            ((box?.get('Lab Name')?.replace('/', '-') as string | undefined) || 'No Lab Provided').replace(/"/g, ''),
          ),
        ),
      );

      const { allLabDropoffLocations, dropoffInfo } = await this.findDropoffs(boxLabs, forceRefresh); // find the nearest dropoffs
      const group = await this.smartGroup(validBoxes, dropoffInfo, allLabDropoffLocations); // find all boxes that can dropoff at that location
      this.setState({
        dropoffInfo,
        allLabDropoffLocations,
        group,
      });

      for (const dropoffName of group.keys()) {
        // this deals with Rogo HQ being the automatically selected dropoff
        // and we will warn appropriately
        if (dropoffName.toLowerCase().includes('rogo')) {
          // full screen alert about what to do at Rogo HQ
          this.setState({ rogoHqInfoVisible: true });
        }
      }

      await this.recalculateDistanceLoop();
    } catch (err) {
      await logger.log('LOAD_BOXES_FOR_SHIPPING_ERROR', err);

      if (err && typeof err === 'string') {
        console.error('Failed to refresh on shipping, message: ', err);
        alertError(`Error occurred while refreshing! Contact support! ${err}`);
      } else if (err && typeof err === 'object' && 'message' in err && typeof err.message === 'string') {
        if (err.message.includes('Failed to fetch')) {
          console.error('Failed refresh on shipping due to internet');
          alertError('Could not refresh due to internet!');
        } else {
          console.error('Failed to refresh on shipping, message: ', err.message);
          alertError(`Error occurred, contact ops! ${err.message}`);
        }
      } else {
        console.error('Failed to refresh on shipping, unkown reason: ', err);
        alertError('Could not refresh for unknown reason! Contact support!');
      }
    }

    this.setState({ loading: false });
  }

  /**
   * Loads any jobs ready for shipping, gets set in react state
   * @returns {array} Job records that are ready to ship
   */
  async loadJobsForShipping(forceRefresh: boolean) {
    const session = getCurrentSession();
    const email = session?.getUser()?.email;
    if (!email) {
      alertWarn('No valid user email!');
      return [];
    }
    try {
      const search = await Airtable.search<Jobs>('Jobs', '2. # Ship Soil', `{Operator Email} = '${email}'`, true);
      if (!search.getRecords().length || forceRefresh) {
        console.log('REFRESHING JOBS SEARCH');
        await search.refresh();
      }
      return search.getRecords();
    } catch (e) {
      console.warn(e);
    }

    return []; // if not a session or if an error ocurrs, return an empty array
  }

  expandBox(boxId: string) {
    const expandedBoxes = [...this.state.expandedBoxes, boxId];
    this.setState({ expandedBoxes });
  }

  collapseBox(boxId) {
    const expandedBoxes = [...this.state.expandedBoxes];
    expandedBoxes.splice(expandedBoxes.indexOf(boxId));
    this.setState({ expandedBoxes });
  }

  async checkForLast() {
    const groupedBoxes = this.getGroupedBoxes();
    const db = getDatabase();

    const boxesScanned = await db.ScannedBoxes.toArray();

    const isLast =
      groupedBoxes.filter((box) => Object.keys(boxesScanned).find((scannedBoxID) => scannedBoxID === box.get('Box ID')))
        .length === groupedBoxes.length;
    if (isLast) {
      this.setState({ activeScan: false });
    }
  }

  async excludeBox(boxId: string) {
    const excludedBoxes = [...this.state.excludedBoxes, boxId];

    this.setState({ excludedBoxes });

    await this.checkForLast();

    alertInfo(`Excluded box ${boxId} from drop!`);
  }

  async includeBox(boxId: string) {
    const excludedBoxes = [...this.state.excludedBoxes];
    excludedBoxes.splice(excludedBoxes.indexOf(boxId));
    this.setState({ excludedBoxes });

    await this.checkForLast();

    alertInfo(`Added back box ${boxId} to drop!`);
  }

  /**
   * Unused - see https://github.com/rogoag/missioncontrol/pull/345/commits/578252384fd871cf97fbfa3910fab9f27045fd82
   *
   * Checks for irregular amounts of samples for a given job distributed amongst the boxes
   * @returns {array} Jobs with irregular amount of samples
   */
  async checkForIrregularSamples() {
    const groupedBoxes = this.getGroupedBoxes();
    const jobsMissingSamples: string[] = [];
    const jobs = Array.from(new Set(groupedBoxes.flatMap((box) => box.get('Jobs') as string[] | undefined)));
    // TODO it's not clear why this would be an array with a single undefined value, but that's what we're getting.
    // so we will do a simple undefined check inside the loop for now. Right now this only comes up with "unsynced"
    // boxes for now
    for (let job of jobs) {
      if (!job) continue;
      let jobRecord: any = await Airtable.getRecord('Jobs', job);
      if (!jobRecord) {
        jobRecord = await Airtable._find('Jobs', job);
      } else {
        console.log('REFRESHING JOB RECORD');
        await jobRecord.refresh();
      }
      const expectedSamples = jobRecord.get('# Samples');
      let actualSamples = 0;
      groupedBoxes.forEach((box) => {
        const boxSamples = box.get('Samples');
        if (!boxSamples) {
          return;
        }

        const boxSamplesParsed = JSON.parse(boxSamples);
        const sampleObj = boxSamplesParsed[job];
        if (sampleObj) {
          actualSamples += sampleObj.samples.length;
        }
      });
      if (expectedSamples !== actualSamples) {
        jobsMissingSamples.push(job);
      }
    }

    return jobsMissingSamples;
  }

  /**
   * Unused - see https://github.com/rogoag/missioncontrol/pull/345/commits/578252384fd871cf97fbfa3910fab9f27045fd82
   *
   * @returns
   */
  confirmShippingIrregularSamples() {
    return alertConfirm(
      'Samples have an irregular number. Check for duplicate boxes or missing samples. Do you have approval to ship?',
      this.constructor.name,
    );
  }

  isBoxValidForUpdate(boxID: string, boxesScanned: { box_id: string }[]): boolean {
    return (
      !this.state.excludedBoxes.includes(boxID) && !!boxesScanned.find((boxScanned) => boxScanned.box_id === boxID)
    );
  }

  async processUploadedImages(userPos: Coordinate) {
    const boxRecordsToUpdate: AirtableRecord[] = [];
    const db = getDatabase();
    const boxesScanned = await db.ScannedBoxes.toArray();

    const shipDate = new Date().toISOString();
    const affectedDropoffNames: string[] = [];
    for (let dropoffName of this.state.group.keys()) {
      // iterate over valid grouping
      const dropOff = this.state.group.get(dropoffName);
      if (!dropOff?.valid) {
        continue;
      }

      for (let lab of dropOff.labBoxes.keys()) {
        for (let box of dropOff?.labBoxes?.get(lab) ?? []) {
          // iterate over boxes
          const boxID = box.get('Box ID');
          if (!boxID || !this.isBoxValidForUpdate(boxID, boxesScanned)) {
            continue;
          }

          await this.handleBoxImageUploads(box, boxesScanned.find((boxScanned) => boxScanned.box_id === boxID)?.image);

          await box.set('Ship Date', shipDate);
          await box.set('Dropoff Link', this.state.unknownDropoff ? [] : [dropOff.id]);
          await box.set('Exact Dropoff Lat', userPos ? userPos[0] : null);
          await box.set('Exact Dropoff Lon', userPos ? userPos[1] : null);
          await box.set('Box State', dropOff.method === 'UPS' ? 'Shipped - UPS' : 'Shipped - Courier');

          boxRecordsToUpdate.push(box);
        }
      }

      affectedDropoffNames.push(dropoffName);
    }

    return { boxRecordsToUpdate, affectedDropoffNames };
  }

  async processScannedBoxes(userPos: Coordinate): Promise<void> {
    const { boxRecordsToUpdate, affectedDropoffNames } = await this.processUploadedImages(userPos);
    const boxShipmentRecord = await this.createBoxShipment(
      userPos,
      boxRecordsToUpdate.map((record) => record.id),
    );

    await transferUpdatesForProcessing(boxRecordsToUpdate, boxShipmentRecord.id);

    // Update the group in the state.
    const group = copyMap(this.state.group);
    affectedDropoffNames.forEach((dropoffName) => {
      group.delete(dropoffName);
    });
    this.setState({ group });
  }

  /**
   * Submits information taken at a dropoff to airtable
   */
  async submitBoxes() {
    await logger.log('SUBMIT_BOXES', 'Submit Jobs Initiated');
    await SampleBox.logBoxes('BOXES_BEFORE_SUBMIT');

    this.setState({ submitting: true });

    try {
      let userPos: Coordinate | undefined = undefined;
      try {
        userPos = await getUserPosition(); // get the user position
        await logger.log('SUBMIT_BOXES', `User position: ${userPos}`);
      } catch {
        alertWarn('Could not get user location for dropoff!');
      }

      // this shouldn't be possible but we'll put in a workaround juust in case
      if (this.state.unknownDropoff && !this.state.dropoffNotes) {
        const result = await alertWarnConfirm(
          "Uh-oh, you selected unknown dropoff but we don't have any notes. Do you want to submit anyways?",
        );
        if (!result) {
          return;
        }
      }

      if (!userPos) {
        throw new Error('Could not get user location for dropoff!');
      }

      await this.processScannedBoxes(userPos);

      this.setState({
        excludedBoxes: [],
        dropoffPicture: undefined,
        submitError: '',
        submitErrorMessage: '',
        filterText: '',
        dropoffNotes: '',
      });
      await this.clearBoxesScanned();
      ShippingFlagsStore.set({ notCloseToDropoff: false, shippedUpsForCourierPreferred: false });

      alertSuccess('Shipped Successfully');
      await logger.log('SUBMIT BOXES', 'submitBoxes successful');
    } catch (err) {
      await logger.log('SUBMIT_BOXES_ERROR', err);

      console.log('ShippingView -> submitBoxes error', err);

      // if the error is just a string...
      if (err && typeof err === 'string') {
        console.error('Failed to submit on shipping, message: ', err);
        alertError(`Error occurred! Contact support! ${err}`);
        this.setState({ submitError: 'Submission Error', submitErrorMessage: err });
      }

      // if the error is an object with a message property
      else if (err && typeof err === 'object' && 'message' in err && typeof err.message === 'string') {
        console.error('Failed to submit on shipping, message: ', err.message);
        if (err.message.includes('Failed to fetch')) {
          console.error('Failed submit on shipping due to internet');
          alertError('Could not submit due to internet!');
          this.setState({ submitError: 'Submission Error', submitErrorMessage: 'Internet Failure!' });
        } else {
          alertError(`Error occurred! Contact support! ${err.message}`);
          this.setState({ submitError: 'Submission Error', submitErrorMessage: err.message });
        }
        // else not an object or not message string type
      } else {
        console.error('Failed to submit on shipping, unkown reason: ', err);
        alertError('Could not submit for unknown reason! Contact support!');
        this.setState({ submitError: 'Submission Error', submitErrorMessage: err?.toString() ?? 'Unknown error' });
      }

      await logger.log('SUBMIT_BOXES', 'Submit Jobs Failed');
    }

    await SampleBox.logBoxes('BOXES_AFTER_SUBMIT');

    this.setState({ submitting: false });
  }

  /**
   * Switch dropoff
   * @param {DropoffLocationDistance} newDropoff - The dropoff to switch to, contains distance and airtable record instance
   * @param {string}                  dropoffName - The name of the dropoff to switch from
   */
  switchDropoff(newDropoff: DropoffLocationDistance, dropoffName: string) {
    logger.log('SWITCH_DROPOFF', `Switching dropoff from ${dropoffName} to ${newDropoff?.record?.get('Name')}`);

    const group = copyMap(this.state.group);
    const newDropoffName = newDropoff.record.get('Name');

    // check if lab preferred delivery method is courier and switching to UPS
    // if so, warn user that they are switching to UPS
    if (newDropoffName.toLowerCase().includes('rogo')) {
      this.setState({ rogoHqInfoVisible: true });
    }

    group.set(newDropoffName, group.get(dropoffName)!);
    const dropoffInGroup = group.get(newDropoffName)!;
    dropoffInGroup.distance = newDropoff.distance;
    dropoffInGroup.id = newDropoff?.record?.get('ID For Labs');

    if (newDropoffName !== dropoffName) {
      group.delete(dropoffName);
    }
    const anchors = { ...this.state.anchors };
    anchors[`${dropoffName}Anchor`] = null;
    this.setState({ anchors, group });
  }

  needsScanning(groupedBoxes: AirtableRecord[], scannedBoxIds: string[]) {
    return (
      !groupedBoxes.length ||
      groupedBoxes.length !==
        groupedBoxes.filter((box) =>
          scannedBoxIds.find(
            (scannedBoxID) =>
              scannedBoxID === box.get('Box ID') && !this.state.excludedBoxes.includes(box.get('Box ID')),
          ),
        ).length
    );
  }

  render() {
    const isMobile = this.props.width ? isWidthDown('sm', this.props.width) : false;

    // TODO: clean up top of render, order should be done somewhere else
    // dedupe boxes
    const scannedBoxIds = this.state.scannedBoxIds;
    const groupedBoxes = this.getGroupedBoxes().filter(
      (box, index, self) => self.findIndex((b) => b.get('Box ID') === box.get('Box ID')) === index,
    );

    const completedBoxes = scannedBoxIds.filter((boxId) =>
      groupedBoxes.find((box) => box.get('Box ID') === boxId && !this.state.excludedBoxes.includes(box.get('Box ID')!)),
    ).length;

    const progressBar = [
      <ProgressBar
        key={'ScannedBoxesProgressBar'}
        totalNumber={groupedBoxes.length}
        completedNumber={completedBoxes}
      />,
    ];

    const needsScanned = this.needsScanning(groupedBoxes, scannedBoxIds);
    const haveValidDropoff = [...this.state.group.keys()].find((key) => this.state.group.get(key)!.valid);
    const scanBoxesDisabled = !needsScanned || !haveValidDropoff;

    const alphaOrder = [...this.state.group.keys()].sort((a, b) => {
      if (this.state.group.get(a)!.method! < this.state.group.get(b)!.method!) {
        return -1;
      } else {
        return 1;
      }
    });

    const groupKeys = alphaOrder.sort((a, b) => {
      return this.state.group.get(b)?.valid === true &&
        this.state.group.get(b)?.valid !== this.state.group.get(a)?.valid
        ? 1
        : -1;
    });

    return (
      <>
        <ConfirmDialog
          message="
          When shipping at Rogo HQ, you need to ensure that you 
          <br> - Ship courier and UPS boxes SEPARATELY (will perform two or more submissions in the app)
          <br> - Ensure you place your boxes on the CORRECT pallet (UPS or lab pallet if courier)
          <br> - Call logistics manager if you have any questions"
          open={this.state.rogoHqInfoVisible}
          onConfirm={() => this.setState({ rogoHqInfoVisible: false })}
          title="Special Instructions for Rogo HQ"
          confirmAction={'I understand'}
        ></ConfirmDialog>

        <UnknownDropoffDialog
          unknownDropoffPopupVisible={this.state.notesDropoffVisible}
          setUnknownDropoffPopupVisible={(visible: boolean) => {
            this.setState({ notesDropoffVisible: visible });
          }}
          dropoffNotes={this.state.dropoffNotes}
          setDropoffNotes={(dropoffNotes: string) => this.setState({ dropoffNotes })}
        />

        <BypassDialog
          bypassKeyword="bypass"
          visible={this.state.bypassPopupVisible}
          dialogTitle="Bypass Lab Shipping Preference"
          title="Bypass Lab Shipping Preference"
          subTitle={`${this.state.labsPreferCourier.join(', ')} prefer COURIER. This should ONLY be done with approval from your ops manager`}
          onClose={this.state.onBypassClose}
          acceptButtonText="Switch to UPS"
        />

        <Grid container spacing={1} style={{ paddingTop: 10 }}>
          <Grid item xs={12}>
            {progressBar}
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <ShippingRefreshBoxes
                geoErrorMessage={this.state.geoErrorMessage}
                loadBoxesForShipping={this.loadBoxesForShipping}
                loading={this.state.loading}
              />

              <Grid item xs={12} sm={1} style={{ paddingLeft: 20 }}>
                <Typography>2. Select Dropoff + Make Active (Below)</Typography>
              </Grid>

              <ShippingPhtotoCaptureUpload
                cameraError={this.state.cameraError}
                groupedBoxes={groupedBoxes}
                loadingScanner={this.state.loadingScanner}
                scanBoxesDisabled={scanBoxesDisabled}
                needsScanned={needsScanned}
                startScanner={() => {
                  this.setState({ activeScan: true, loadingScanner: true });
                }}
                updateDetectedCode={this.updateDetectedCode}
              />

              <Grid item xs={6} sm={1}>
                <Typography>
                  {Boolean(this.state.cameraError) ? this.state.cameraError : 'or exclude if not shipping'}
                </Typography>
                <Typography>{this.state.cameraDomError ? this.state.cameraDomError : ''}</Typography>
              </Grid>

              <TakePhotoOrUpload
                buttonLabel="4. Dropoff"
                cameraError={this.state.cameraError}
                picture={this.state.dropoffPicture}
                activateCaptureModal={() =>
                  this.setState({ photoModalActive: true, loadingCapture: true, captureTarget: 'dropoff' })
                }
                onUploadedFileChange={this.handleCapture}
                loading={this.state.loading}
                captureLoading={this.state.loadingCapture}
              />

              <Grid item xs={6} sm={1}>
                <Typography>
                  {this.state.cameraError ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://support.google.com/chrome/answer/114662?hl=en&co=GENIE.Platform%3DAndroid&oco=0"
                    >
                      Fix for Android
                    </a>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography>
                  {this.state.cameraError ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://support.google.com/chrome/answer/114662?hl=en&co=GENIE.Platform%3DiOS&oco=0"
                    >
                      Fix for iOS
                    </a>
                  ) : (
                    ''
                  )}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={1}>
                <LoadingButton
                  variant="outlined"
                  onClick={
                    Boolean(this.state.unknownDropoff) && !Boolean(this.state.dropoffNotes)
                      ? () => this.setState({ notesDropoffVisible: true })
                      : this.submitBoxes
                  }
                  loading={this.state.submitting}
                  disabled={!this.state.dropoffPicture || this.state.loading || !groupedBoxes.length || needsScanned}
                >
                  {Boolean(this.state.unknownDropoff) && !Boolean(this.state.dropoffNotes)
                    ? '5. Enter Dropoff Name'
                    : '5. Submit'}
                  <MdFileUpload style={{ marginLeft: 7.5 }} fontSize="medium" />
                </LoadingButton>
              </Grid>

              <Grid item xs={6} sm={1}>
                <Typography></Typography>
                <Typography></Typography>
              </Grid>
            </Grid>

            {this.state.loading ? (
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <LinearProgress color="secondary" />
              </Grid>
            ) : (
              // generate multiple tables per dropoff
              <Grid container>
                {groupKeys.map((dropoffName) => {
                  const filteredDropoffs = this.state.allLabDropoffLocations
                    .get(this.state.group.get(dropoffName)!.method!)!
                    .filter((dropoff) =>
                      dropoff.record.get('Name').toLowerCase().includes(this.state.filterText.toLowerCase()),
                    );

                  const currentDropoffRecord = this.state.allLabDropoffLocations
                    .get(this.state.group.get(dropoffName)!.method!)!
                    .find((dropoff) => dropoff.record.get('Name') === dropoffName)?.record;

                  const dropoffInResults = dropoffName.toLowerCase().includes(this.state.filterText);
                  const noFilterText = !this.state.filterText;

                  return (
                    <Paper key={dropoffName} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Grid container>
                        <Table size="medium" style={{ tableLayout: 'fixed' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" colSpan={4} style={{ flex: 1 }}>
                                <div style={{ padding: 0, margin: 0 }}>
                                  {!this.state.unknownDropoff && (
                                    <>
                                      <DropoffFilter
                                        filterText={this.state.filterText}
                                        updateFilter={(filterText) => {
                                          this.setState({ filterText });
                                        }}
                                      />
                                      <div>{`${filteredDropoffs.length} dropoffs found`}</div>
                                    </>
                                  )}

                                  <Grid container>
                                    {!this.state.unknownDropoff && (
                                      <>
                                        <DropoffSelection
                                          anchors={this.state.anchors}
                                          dropoffName={dropoffName}
                                          updateAnchors={(anchors) => this.setState({ anchors })}
                                          filteredDropoffs={filteredDropoffs}
                                          switchDropoff={this.switchDropoff}
                                          unknownDropoff={this.state.unknownDropoff}
                                          noFilterText={noFilterText}
                                          dropoffInResults={dropoffInResults}
                                          distance={this.state.group?.get(dropoffName)?.distance}
                                        />

                                        <Grid item xs={2}>
                                          <DirectionsButton
                                            dropoffName={dropoffName}
                                            dropoffRecord={currentDropoffRecord}
                                          />
                                        </Grid>

                                        {import.meta.env.DEV && (
                                          <Grid item xs={12}>
                                            <Button
                                              onClick={() => {
                                                const boxesFromGroup = this.getBoxesFromGroup();
                                                for (const box of boxesFromGroup) {
                                                  this.saveBoxScanned(box.get('Box ID'), 'data:image/jpeg;base64,');
                                                }
                                              }}
                                              color="secondary"
                                              variant={'text'}
                                            >
                                              DEBUG FEATURE ONLY!! AUTO SCAN BOXES
                                            </Button>
                                          </Grid>
                                        )}
                                      </>
                                    )}

                                    {this.state.group.get(dropoffName)!.method === 'UPS' && (
                                      <Grid item xs={12}>
                                        <FormControlLabel
                                          control={
                                            <CustomCheckbox
                                              checked={this.state.unknownDropoff}
                                              color="primary"
                                              onChange={(e) => {
                                                this.setState({ unknownDropoff: e.target.checked });
                                              }}
                                            />
                                          }
                                          label="Unknown Dropoff?"
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </div>
                              </TableCell>
                            </TableRow>

                            <TableRow key={'makeInactive'}>
                              <TableCell align="center" colSpan={4} style={{ flex: 1 }}>
                                <Grid container direction="row">
                                  <Button
                                    key={this.state.group.get(dropoffName)!.valid ? 'Make Inactive' : 'Make Active'}
                                    onClick={async () => await this.makeActiveOrInactive(dropoffName)}
                                    style={{ height: 20 }}
                                    color="secondary"
                                    variant={'text'}
                                  >
                                    {this.state.group.get(dropoffName)!.valid ? 'Make Inactive' : 'Make Active'}
                                  </Button>

                                  {this.state.group.get(dropoffName)!.method !== 'UPS' && (
                                    <Button
                                      key={'Switch to UPS'}
                                      onClick={async () =>
                                        await this.switchToUPS(dropoffName, this.state.group.get(dropoffName)!.labBoxes)
                                      }
                                      color="secondary"
                                      variant={'text'}
                                      style={{ height: 20, marginLeft: 10, marginRight: 10 }}
                                    >
                                      Switch to UPS
                                    </Button>
                                  )}
                                </Grid>
                              </TableCell>
                            </TableRow>

                            {!this.state.group.get(dropoffName)!.valid && (
                              <TableRow key={'inactiveWarningRow'}>
                                <TableCell align="center" colSpan={4} style={{ flex: 1 }}>
                                  <Typography
                                    variant="body2"
                                    style={{
                                      marginLeft: 10,
                                      fontStyle: 'italic',
                                      paddingBottom: 5,
                                      paddingTop: 5,
                                      opacity: 0.8,
                                    }}
                                  >
                                    Dropoff is inactive, make it active if you want to drop at this location
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableHead>

                          {!isMobile && (
                            <TableBody>
                              {[...this.state.group.get(dropoffName)!.labBoxes.keys()].map((labName) => (
                                // Style so that the entire row is the "pointer" and the whole row can be clicked for the checkbox
                                <ShippingLabGroup
                                  key={labName}
                                  collapseBox={this.collapseBox}
                                  expandBox={this.expandBox}
                                  excludeBox={this.excludeBox}
                                  dropoffName={dropoffName}
                                  excludedBoxes={this.state.excludedBoxes}
                                  expandedBoxes={this.state.expandedBoxes}
                                  group={this.state.group}
                                  labName={labName}
                                  scannedBoxIds={this.state.scannedBoxIds}
                                  switchToCourier={this.switchToCourier}
                                  cameraError={this.state.cameraError}
                                  activateCaptureModal={(captureTargetId) =>
                                    this.setState({
                                      photoModalActive: true,
                                      loadingCapture: true,
                                      captureTarget: captureTargetId,
                                    })
                                  }
                                  onUploadedBoxPictureChange={this.onUploadedBoxPictureChange}
                                  loading={this.state.loading}
                                  captureLoading={this.state.loadingCapture}
                                  onNoScanOrPicExcuseChange={this.onNoScanOrPicExcuseChange}
                                />
                              ))}
                            </TableBody>
                          )}
                        </Table>

                        {isMobile &&
                          [...this.state.group.get(dropoffName)!.labBoxes.keys()].map((labName) => (
                            <ShippingLabGroupCarded
                              key={labName}
                              collapseBox={this.collapseBox}
                              expandBox={this.expandBox}
                              excludeBox={this.excludeBox}
                              dropoffName={dropoffName}
                              excludedBoxes={this.state.excludedBoxes}
                              expandedBoxes={this.state.expandedBoxes}
                              group={this.state.group}
                              labName={labName}
                              scannedBoxIds={this.state.scannedBoxIds}
                              switchToCourier={this.switchToCourier}
                              cameraError={this.state.cameraError}
                              activateCaptureModal={(captureTargetId) =>
                                this.setState({
                                  photoModalActive: true,
                                  loadingCapture: true,
                                  captureTarget: captureTargetId,
                                })
                              }
                              onUploadedBoxPictureChange={this.onUploadedBoxPictureChange}
                              loading={this.state.loading}
                              captureLoading={this.state.loadingCapture}
                              onNoScanOrPicExcuseChange={this.onNoScanOrPicExcuseChange}
                            />
                          ))}
                      </Grid>
                    </Paper>
                  );
                })}

                {/* // excluded boxes are in their own table */}
                {this.state.excludedBoxes.length > 0 && (
                  <ExcludedBoxesList excludedBoxes={this.state.excludedBoxes} includeBox={this.includeBox.bind(this)} />
                )}
              </Grid>
            )}
          </Grid>

          <ShippingBarocdeScanner
            activeScan={this.state.activeScan}
            updateDetectedCode={this.updateDetectedCode.bind(this)}
            closeScanner={() => this.setState({ activeScan: false, targetBoxIDForUPSLabel: '', loadingScanner: false })}
            progressBar={progressBar}
            onCanPlay={() => this.setState({ loadingScanner: false })}
            handleLoadFailure={this.handleLoadFailure.bind(this)}
            loadingScanner={this.state.loadingScanner}
            targetBoxIDForUPSLabel={this.state.targetBoxIDForUPSLabel}
          />

          <PhotoCaptureModal
            photoModalActive={this.state.photoModalActive}
            handleCapture={(file: File) =>
              this.state.captureTarget === 'dropoff'
                ? this.handleCapture(file)
                : this.onUploadedBoxPictureChange(this.state.captureTarget, file)
            }
            loadingCapture={this.state.loadingCapture}
            closeCapture={() => this.setState({ photoModalActive: false })}
            onCanPlay={() =>
              this.setState({
                loadingCapture: false,
                cameraError: '',
                cameraDomError: '',
              })
            }
            handleLoadFailure={this.handleLoadFailure.bind(this)}
          />
        </Grid>
      </>
    );
  }
}

export default withWidth()(ShippingView);
