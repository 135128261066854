import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingButton } from '../utils';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  cardGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
}));

interface Props {
  labName: string;
  dropoffName: string;
  shipMethod: string;
  onSwitchToCourierClick: () => void;
  children: React.ReactNode;
}

export const ShippingGroupCardsContainer: React.FC<Props> = ({
  labName,
  onSwitchToCourierClick,
  shipMethod,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box p={2} width={'100%'}>
      {/* Header */}
      {shipMethod === 'UPS' && (
        <div className={classes.header}>
          <Typography variant="h6">{labName}</Typography>

          <LoadingButton onClick={onSwitchToCourierClick} color="secondary" size="small">
            Switch to Courier
          </LoadingButton>
        </div>
      )}

      <Grid container spacing={2} justifyContent="center">
        {React.Children.map(children, (child) => (
          <Grid item>{child}</Grid>
        ))}
      </Grid>
    </Box>
  );
};
