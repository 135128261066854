import React, { PureComponent } from 'react';

import { Popper, Paper, Popover, TextField } from '@material-ui/core';

import MapButton from './MapButton';

import { ImListNumbered, ImMagicWand, ImSortNumericAsc, ImTab } from 'react-icons/im';
import { BiPolygon } from 'react-icons/bi';
import { FaDrawPolygon } from 'react-icons/fa';
import { GiHazardSign, GiPathDistance } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { FcCheckmark } from 'react-icons/fc';

import { DRAW_TYPES, SampleSource, SettingsAccessLevel, TakenCore } from '../../../types/types';

import './styles/drawbutton.css';
import { MAP_TYPES, MapType } from '../helpers/mapTypes';
import { getCurrentMission } from '../../../dataModelHelpers';
import { TiChartLineOutline } from 'react-icons/ti';
import { calculateTakenCoreCentroid, updateState } from '../../../utils';
import { transformTakenCoresToArmPosition } from '../../../services/RobotArmService';
import { Coordinate } from 'ol/coordinate';
import { DrawConfig } from '../../../types/types';
import { dispatchMissionUpdated } from '../../../missionEvents';
import MapLoadingButton from './MapLoadingButton';

import { GiNuclearBomb } from 'react-icons/gi';
import { alertConfirm, alertError, alertInfo, alertSuccess, alertWarn } from '../../../alertDispatcher';
import { MdNotInterested } from 'react-icons/md';
import { ShowChangeTypeSelectorPopup, promptForChangeReason } from '../../../changeReasonHelpers';
import { FertilityArmOffset, HDArmOffset, RobotArmOffsetX, RobotArmOffsetY } from '../../../db/local_storage';

interface DrawButtonProps {
  addSample: (point: Coordinate, sampleId: string, sampleSource: SampleSource) => Promise<void>;
  addCore: (point: Coordinate, sampleId: string, sampleSource: SampleSource) => Promise<void>;
  addSampleActive: Coordinate | null;
  addSampleAfterPoint: (point: Coordinate, sampleId: string, sampleSource: SampleSource) => Promise<void>;
  cancelAddSample: () => void;
  coreLine: Coordinate[];
  drawConfig: DrawConfig;
  mapType: MapType;
  renumber: boolean;
  reorderLine: Coordinate[];
  toggleDrawCores: () => void;
  deleteAllCoresOrSamples: () => void;
  toggleDrawGenericZone: () => void;
  toggleDrawReorder: () => void;
  toggleDrawSample: (action: 'Moving' | 'Adding') => Promise<boolean>;
  toggleDrawSlowZone: () => void;
  toggleDrawUnsafeZone: () => void;
  updateRenumber: (renumber: boolean) => void;
  zoneMission: boolean;
  addCheckpoint: (name: string) => void;
  enterNewSampleId: boolean;
  accessLevel: SettingsAccessLevel;
  renameSampleId: boolean;
  renameSample: (point: Coordinate, newSampleId: string) => Promise<void>;
}

interface DrawButtonState {
  containerOpen: boolean;
  anchorEl: HTMLElement | null;
  sampleText: string;
  sampleError: string | null;
  drawButtonAnchor: HTMLButtonElement | null;
}

export default class DrawButton extends PureComponent<DrawButtonProps, DrawButtonState> {
  SERIALIZABLE_STATE_VARIABLES: (keyof DrawButtonState)[] = ['containerOpen'];
  buttonRef: React.RefObject<unknown>;

  constructor(props: DrawButtonProps) {
    super(props);

    this.state = {
      containerOpen: false,
      anchorEl: null,
      sampleText: '',
      sampleError: null,
      drawButtonAnchor: null,
    };

    this.toggleContainer = this.toggleContainer.bind(this);
    // TODO add sample auto-number
    this.handleApplySample = this.handleApplySample.bind(this);
    this.handleStartHover = this.handleStartHover.bind(this);
    this.handleStopHover = this.handleStopHover.bind(this);
    this.toggleRenumber = this.toggleRenumber.bind(this);

    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    this.deserializeState();

    const anchorEl = document.getElementById('toggleDrawUnsafeZone');
    this.setState({ anchorEl });
  }

  componentWillUnmount() {
    this.serializeState();
  }

  /**
   * serialize the state into session
   */
  serializeState(this: DrawButton) {
    const serializedState: Partial<DrawButtonState> = {};
    for (const stateKey of this.SERIALIZABLE_STATE_VARIABLES) {
      const value = this.state[stateKey];
      serializedState[stateKey] = value as never; // this is not ideal, why is this acting like this?
    }
    localStorage.setItem('DrawButtonState', JSON.stringify(serializedState));
  }

  /**
   * deserialize the state
   */
  deserializeState() {
    const serializedState = JSON.parse(localStorage.getItem('DrawButtonState') ?? '{}');
    if (serializedState) {
      for (const stateKey of Object.keys(serializedState)) {
        this.setState(updateState(stateKey as keyof DrawButtonState, serializedState[stateKey]));
      }
    }
  }

  toggleContainer(event) {
    this.setState({ containerOpen: !this.state.containerOpen });
  }

  handleStartHover(event: React.MouseEvent<HTMLButtonElement>) {
    if (!this.state.drawButtonAnchor) {
      this.setState({ drawButtonAnchor: event.currentTarget });
    }
  }

  handleStopHover() {
    this.setState({ drawButtonAnchor: null });
  }

  toggleRenumber() {
    this.props.updateRenumber(!this.props.renumber);
  }

  validateSample = () => {
    const mission = getCurrentMission();
    let validationMap = { error: false, text: '' };
    if (!this.state.sampleText || (!this.props.zoneMission && !this.state.sampleText.match(/^[a-zA-Z0-9]+$/))) {
      validationMap = { error: true, text: 'Must be alphanumeric' };
    }
    const samples = mission?.getAllSamples() ?? [];
    if (samples.find((sample) => sample.sample_id === this.state.sampleText)) {
      if (this.props.accessLevel === 'Technician' && this.props.renameSampleId) {
        return validationMap;
      }
      validationMap = { error: true, text: 'Sample ID already exists' };
    }
    return validationMap;
  };

  // TODO add sample auto-number
  async handleApplySample() {
    const validationMap = this.validateSample();
    if (!validationMap.error && this.props.addSampleActive) {
      if (this.props.mapType === MAP_TYPES.MAPS) {
        if (this.props.renameSampleId) {
          await this.props.renameSample(this.props.addSampleActive, this.state.sampleText);
        } else if (this.props.zoneMission) {
          try {
          await this.props.addCore(this.props.addSampleActive, this.state.sampleText, 'Rogo Map Maker');
          } catch (error) {
            console.error('Error adding core:', error);
            
            return;
          }
        } else {
          await this.props.addSample(this.props.addSampleActive, this.state.sampleText, 'Rogo Map Maker');
        }
      } else if (this.props.mapType === MAP_TYPES.SAMPLING) {
        await this.props.addSampleAfterPoint(this.props.addSampleActive, this.state.sampleText, 'Rogo Field Ops');
      }

      this.setState({ sampleText: '' });

      this.props.cancelAddSample();
    } else {
      this.setState({ sampleError: validationMap.text });
    }
  }

  render() {
    return (
      <React.Fragment>
        <MapButton
          onClick={this.toggleContainer}
          toggled={this.state.containerOpen}
          id={'toggleDrawUnsafeZone'}
          tooltip="Draw Map Items"
        >
          <FaDrawPolygon size={20} />
        </MapButton>
        <Popper anchorEl={this.state.anchorEl} open={this.state.containerOpen} style={{ zIndex: 2 }}>
          <Paper>
            <MapButton
              onClick={this.props.toggleDrawUnsafeZone}
              style={{
                backgroundColor: this.props.drawConfig.featureType === DRAW_TYPES.UNSAFE_ZONE ? '#e2e0e0' : undefined,
              }}
              tooltip="Draw Unsafe Zone (Crtl+1)"
            >
              <GiHazardSign size={24} color="rgb(164, 22, 35)" />
            </MapButton>
            <MapButton
              onClick={this.props.toggleDrawSlowZone}
              style={{
                backgroundColor: this.props.drawConfig.featureType === DRAW_TYPES.SLOW_ZONE ? '#e2e0e0' : undefined,
              }}
              tooltip="Draw Slow Zone (Ctrl+2)"
            >
              <GiHazardSign size={24} color="rgb(141, 103, 8)" />
            </MapButton>
            <MapButton
              onClick={this.props.toggleDrawGenericZone}
              style={{
                backgroundColor: this.props.drawConfig.featureType === DRAW_TYPES.PULLIN_ZONE ? '#e2e0e0' : undefined,
              }}
              tooltip="Draw Generic Zone (Ctrl+3)"
            >
              <BiPolygon size={24} color="rgb(29, 120, 29)" />
            </MapButton>
            {this.props.mapType === MAP_TYPES.MAPS && (
              <React.Fragment>
                {(!this.props.zoneMission || this.props.accessLevel === 'Technician') && (
                  <MapButton
                    onClick={async () => await this.props.toggleDrawSample('Adding')}
                    style={{
                      backgroundColor:
                        this.props.drawConfig.featureType === DRAW_TYPES.CENTROID ? '#e2e0e0' : undefined,
                    }}
                    onMouseOver={this.handleStopHover}
                    tooltip="Add Sample"
                  >
                    <IoMdAddCircleOutline size={22} />
                  </MapButton>
                )}
                {this.props.zoneMission && (
                  <MapButton
                    onClick={this.props.toggleDrawCores}
                    style={{
                      backgroundColor:
                        this.props.drawConfig.featureType === DRAW_TYPES.CORE_LINE ? '#e2e0e0' : undefined,
                    }}
                    disabled={this.props.reorderLine.length > 0}
                    tooltip="Draw Coring Stops"
                  >
                    <TiChartLineOutline size={24} />
                  </MapButton>
                )}
                {this.props.mapType === MAP_TYPES.MAPS && (
                  <MapButton
                    onClick={this.props.deleteAllCoresOrSamples}
                    disabled={this.props.reorderLine.length > 0}
                    tooltip={this.props.zoneMission ? 'Delete All Cores' : 'Delete All Samples'}
                  >
                    <GiNuclearBomb size={24} />
                  </MapButton>
                )}
              </React.Fragment>
            )}
            <MapButton
              // onMouseOver={this.handleStartHover}
              onClick={() => {
                this.props.toggleDrawReorder();
              }}
              style={{
                backgroundColor: this.props.drawConfig.featureType === DRAW_TYPES.REORDER_LINE ? '#e2e0e0' : undefined,
              }}
              disabled={this.props.coreLine.length > 0}
              tooltip="Reorder Path"
            >
              <GiPathDistance size={24} />
            </MapButton>
            <MapButton
              // onMouseOver={this.handleStartHover}
              onClick={async () => {
                getCurrentMission()?.reverse_path();
              }}
              disabled={this.props.coreLine.length > 0}
              tooltip="Reverse Path"
            >
              <ImTab size={24} />
            </MapButton>
            {!this.props.zoneMission && (
              <MapButton
                onClick={async () => {
                  const mission = getCurrentMission();
                  this.props.addCheckpoint(`Renumber Points`);
                  await mission?.renumber_samples(this.props.mapType === MAP_TYPES.SAMPLING, true);
                  dispatchMissionUpdated();
                }}
                // onMouseLeave={this.handleStopHover}
                toggled={this.props.renumber}
                style={{ pointerEvents: 'auto' }}
                tooltip="Renumber Points in Path Order"
              >
                <ImSortNumericAsc size={22} />
              </MapButton>
            )}
            {!this.props.zoneMission && (
              <MapButton
                onClick={async () => {
                  const mission = getCurrentMission();
                  this.props.addCheckpoint(`Reorder Path By Sample ID`);
                  const samples = mission?.getAllSamples() ?? [];
                  const sortedSamples = samples.sort((a, b) => {
                    return parseInt(a.sample_id) - parseInt(b.sample_id);
                  });
                  const coordinates = sortedSamples
                    .map((sample) => sample.getSampleSite()?.getSampleCentroid()?.getCoordinates())
                    .filter((coord) => !!coord);
                  await mission?.reorder(coordinates, false, this.props.mapType !== MAP_TYPES.MAPS);
                  dispatchMissionUpdated();
                }}
                // onMouseLeave={this.handleStopHover}
                toggled={this.props.renumber}
                style={{ pointerEvents: 'auto' }}
                tooltip="Reorder Path By Sample ID"
              >
                <ImListNumbered size={22} />
              </MapButton>
            )}
            {this.props.mapType === MAP_TYPES.MAPS &&
              getCurrentMission()?.getJob()?.client.toLowerCase().includes('loam') && (
                <MapButton
                  onClick={async () => {
                    this.props.addCheckpoint(`The Magical Loam Job Transformer`);
                    const mission = getCurrentMission();
                    if (!mission) {
                      alertError('No mission loaded');
                      return;
                    }
                    const cores: TakenCore[] = mission.cores ? JSON.parse(mission.cores) : [];

                    const currentDx = RobotArmOffsetX.get();
                    const currentDy = RobotArmOffsetY.get();

                    const searchName = mission.name.toLowerCase();
                    if (searchName.includes('small plot')) {
                      if (searchName.includes('bd')) {
                        alertInfo('Using HD Mini Offset (Needs fixed, but does not matter for Loam Small Plot BD)');
                        RobotArmOffsetX.set(-1.1);
                        RobotArmOffsetY.set(-1.1);
                      } else {
                        alertInfo('Using Fertility Offset');
                        RobotArmOffsetX.set(FertilityArmOffset.dx);
                        RobotArmOffsetY.set(FertilityArmOffset.dy);
                      }
                    } else {
                      alertInfo('Using HD Offset');
                      RobotArmOffsetX.set(HDArmOffset.dx);
                      RobotArmOffsetY.set(HDArmOffset.dy);
                    }

                    transformTakenCoresToArmPosition(cores);

                    RobotArmOffsetX.set(currentDx);
                    RobotArmOffsetY.set(currentDy);

                    const coresBySample: Record<string, TakenCore[]> = {};
                    cores.forEach((core) => {
                      const sample_id = core[5];
                      if (!coresBySample[sample_id]) {
                        coresBySample[sample_id] = [];
                      }
                      coresBySample[sample_id].push(core);
                    });

                    const samples = mission.getAllSamples();
                    const missing_ids: string[] = [];
                    let samplesMoved = 0;
                    console.log(coresBySample);
                    for (const sample of samples) {
                      console.log(`Moving ${sample.sample_id}`);
                      if (coresBySample[sample.sample_id]) {
                        const centroid = calculateTakenCoreCentroid(coresBySample[sample.sample_id]);
                        sample.getSampleSite().getSampleCentroid()?.move(centroid.x, centroid.y);
                        samplesMoved++;
                      } else {
                        missing_ids.push(sample.sample_id);
                      }
                    }

                    console.log(`Missing IDs: ${missing_ids.join(', ')}`);

                    const totalSamples = samples.length;

                    const percentageMoved = (samplesMoved / totalSamples) * 100;
                    if (percentageMoved === 100) {
                      alertSuccess(`Moved ${samplesMoved} / ${totalSamples} (${percentageMoved}%) samples`);
                    } else if (percentageMoved > 95) {
                      alertWarn(`Moved ${samplesMoved} / ${totalSamples} (${percentageMoved}%) samples`);
                    } else {
                      alertError(`Moved ${samplesMoved} / ${totalSamples} (${percentageMoved}%) of samples`);
                    }

                    mission.clearSampleBarcodes();
                    mission.resetPulled();
                    mission.cores = '[]';

                    dispatchMissionUpdated();
                  }}
                  // onMouseLeave={this.handleStopHover}
                  toggled={this.props.renumber}
                  style={{ pointerEvents: 'auto' }}
                  tooltip="The Magical Loam Job Transformer"
                >
                  <ImMagicWand size={22} />
                </MapButton>
              )}
            <MapButton
              tooltip={'Skip / Unskip all'}
              key={'SkipAllUnscanned'}
              onClick={async () => {
                const mission = getCurrentMission();
                if (!mission) {
                  return;
                }
                
                const samples = mission?.getAllSamples();
                const unsampledAndUnskippedSamples = samples.filter(
                  (sample) => !sample.bag_id && sample.change_type !== 'Skip',
                );

                if (unsampledAndUnskippedSamples.length === 0) {
                  const skippedSamples = samples.filter((sample) => sample.change_type === 'Skip');
                  if (await alertConfirm(`Are you sure you want to unskip ${skippedSamples.length} samples?`)) {
                    this.props.addCheckpoint('Unskip All');
                    await mission.unskipAllSkipped();
                    dispatchMissionUpdated();
                  }
                } else {
                  const selectorOptions: ShowChangeTypeSelectorPopup = {
                    sampleCoreOrZoneId: `${unsampledAndUnskippedSamples.length} SAMPLES`,
                    changeTypeOptions: ['Skip', 'Delete', 'None'],
                    defaultChangeType: 'Skip',
                  };

                  const changeSampleResult = await promptForChangeReason(selectorOptions);
                  if (changeSampleResult.result === 'confirm' && !!changeSampleResult.selectedChangeType) {
                    this.props.addCheckpoint('Skip All Unscanned');
                    await mission.skipUnsampled(changeSampleResult.selectedChangeType, changeSampleResult.reason ?? '');
                    dispatchMissionUpdated();
                  }
                }
              }}
            >
              <MdNotInterested size={22} />
            </MapButton>
            <MapButton
              onClick={() => this.setState({ containerOpen: false })}
              onMouseOver={this.handleStopHover}
              tooltip="Close Drawing Menu"
            >
              <AiOutlineClose size={24} />
            </MapButton>
          </Paper>
        </Popper>
        <Popover
          anchorEl={this.state.anchorEl}
          open={
            Boolean(this.props.addSampleActive) && Boolean(this.props.enterNewSampleId || !!this.props.renameSampleId)
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <TextField
            label={'Sample ID'}
            style={{ margin: 10 }}
            onChange={(event) => this.setState({ sampleText: event.target.value })}
            value={this.state.sampleText}
            size={'small'}
            error={Boolean(this.state.sampleError)}
            helperText={this.state.sampleError}
          />
          <MapLoadingButton style={{ marginTop: 15 }} onClick={this.handleApplySample}>
            <FcCheckmark size={24} />
          </MapLoadingButton>
          <MapButton style={{ marginTop: 18 }} onClick={this.props.cancelAddSample}>
            <AiOutlineClose size={22} />
          </MapButton>
        </Popover>
      </React.Fragment>
    );
  }
}
