import { Coordinate } from 'ol/coordinate';
import {
  AppRole,
  BoundaryChangeType,
  CoordinatePoint,
  CoreDiameter,
  CoreSource,
  HasChangeReason,
  KMLFeature,
  RecordingDevice,
  SampleSource,
} from '../types/types';
import GridPatterns from './GridPatternsDatatype';
import SamplingTypes from './SamplingTypesDatatype';
import { TaskName } from './TaskClass';
import { TeamUserFlags } from '../types/rogo.at.zod';
import { ZoneRecordingType, ZoneType } from './ZoneTypesDatatype';
import { KMLElement } from '../kml';
import { DataList } from './basetable';
import { DataObject } from './dataobject';
import SoilCore from './SoilCoreClass';
import { FieldSet } from 'airtable/lib/field_set';

// export declare type AirtableRecordFields = { [key: string]: any };
export declare type AirtableRecordFields = FieldSet;

export interface RogoDexieBase {
  // id: number;
  instance_id?: number;
  _refs?: { [key: string]: number[] };
  _version?: number;
}

export interface KMLSerializable {
  // load_kml: (kml: KMLElement | KMLElement[]) => void;
  // load_kml: (args: any) => any;
  to_kml(): KMLElement | KMLElement[] | undefined;
}

export interface MapElement {
  // TODO add types
  to_feature(prevFeatures: any): KMLFeature | KMLFeature[] | undefined;
}

export interface IAirtableRecord<T extends AirtableRecordFields> extends RogoDexieBase {
  table: string;
  id: string;
  fields: T;
  modified_fields: Set<keyof T>;
  dirty: boolean;
}

export interface IAirtableSearch extends RogoDexieBase {
  table: string;
  view: string;
  query: string;
}

export interface IAttachment extends RogoDexieBase {
  field_name: string;
  attachment_id: string;
  filename: string;
  url: string;
  content?: Blob;
  downloadUrl: string;
  dirty: boolean;
  AirtableRecord_id: number | undefined;
}

export interface IBoundary extends RogoDexieBase, KMLSerializable {
  boundary_id: number;
  poly_id: number;
  coordinates: Coordinate[];
  OuterZone_id?: number;
  InnerZone_id?: number;
}

export interface ICorePoint extends RogoDexieBase {
  Waypoint_id?: number;
  SoilCore_id?: number;
}

export interface IJob extends RogoDexieBase {
  add_on_freq: number;
  attachment_id: string;
  auto_renumber: boolean;
  auto_zoom_near_sample: boolean;
  billing_account: string;
  boundary_change_notes: string;
  boundary_change_type: BoundaryChangeType;
  client: string;
  core_diameter: CoreDiameter;
  enable_manual_drive_aid: boolean;
  entrance_interview_crop: string;
  entrance_interview_residue: Residue;
  event_id: string;
  farm: string;
  field_aligned_sample_path: number;
  field_id: string;
  field: string;
  grower_id: string;
  grower: string;
  implement_centerted_navigation: boolean;
  in_field_notes_ops: string;
  job_flags: string[];
  lab_address: string;
  lab_code: string;
  lab_instructions: string;
  lab_name: string;
  lab_primary_delivery: string;
  lab_qrs_required: boolean;
  lab_short_name: string;
  lab_submittal_id: string;
  manual_core_length_measurement: boolean;
  manual_core_loss_measurement: boolean;
  use_original_sample_id: boolean;
  manual_hole_depth_measurement: boolean;
  map_making_rules: string;
  open_time: number;
  plot_mission: boolean;
  disable_core_line_alteration: boolean;
  plot_settings: string;
  required_fields: string;
  response_email: string;
  sample_order_type: string;
  sampling_company_id: string;
  sampling_company_name: string;
  sampling_tolerance_ft: number;
  sampling_type_special: string;
  selected_sample_column: string;
  sites_type: string;
  submitter_notified_id: string;
  test_package: string;
  tilled_field_at_sampling: boolean;
  zone_interleave: boolean;
  strict_core_enforcement: boolean;
}

export interface IMission extends RogoDexieBase, MapElement {
  name: string;
  last_modified: number;
  job_id: string | undefined;
  description: string;
  pullin_lat: number;
  pullin_lon: number;
  sample_date: number;
  nav_checksum: string;
  settings_changes: string;
  settings_start: string;
  settings_end: string;
  cores: string;
  getJob: () => IJob | undefined;
  getSampleSites: () => ISampleSite[];
  getSamplingSpecs: () => ISamplingSpec[];
  getSamples: () => ISample[];
}

export interface ISoilDumpEvent extends RogoDexieBase, KMLSerializable, CoordinatePoint, MapElement {
  guid: string;
  timestamp: number;
  lat: number;
  lon: number;
  heading: number;
  Mission_id?: number;
  PreviousDump_id?: number | undefined;
}

export interface IPathPoint extends RogoDexieBase {
  Waypoint_id?: number;
}

export interface IPermission extends RogoDexieBase {
  Role_id?: number;
  Task_id?: number;
}

export interface IRobotConnection extends RogoDexieBase {
  hostname: string;
  endpoint: string;
  port: number;
  insecure: boolean;
}

export interface IRole extends RogoDexieBase {
  name: string;
  default_task: string;
  session_expiration_days: number;
}

export interface ISampleBox extends RogoDexieBase, KMLSerializable {
  uid: string;
  closedTimestamp: string;
  needsUpdated: boolean;
  labName: string;
  session_token: string | undefined;
  username: string;
  trackingNumber: string | undefined;
  trackingNumberTime: string | undefined;
  jobBoxCountIndex: number;
  dailyBoxCountIndex: number;
  reprint_reason: string;

  Session_id: number | undefined;
  ReprintSession_id: number | undefined;
}

export interface ISampleCentroid extends RogoDexieBase, CoordinatePoint {
  SampleSite_id: number | undefined;
}

export interface ISample extends RogoDexieBase, KMLSerializable {
  sample_type: SamplingTypes;
  order: number;
  previous_order: number;
  bag_id: string | undefined;
  sampled_at: number;
  scanned_at: number;
  pulled_at: number;
  box_uid: string | undefined;
  skipped_or_deleted: boolean;
  change_reason: string | undefined;
  change_type: string | undefined;
  sample_id: string;
  getSampleSite: () => ISampleSite | undefined;
}

export interface ISampleSite extends RogoDexieBase, HasChangeReason {
  instance_id: number;
  original_sample_id: string;
  sample_site_source: SampleSource;
  getMission: () => IMission | undefined;
  getSamples: () => ISample[];
}

export interface ISampleZone extends RogoDexieBase {
  SampleSite_id: number | undefined;
  Zone_id: number | undefined;
}

export interface ISamplingSpec extends RogoDexieBase {
  spec_id: string;
  cores: number;
  pattern_type: GridPatterns;
  radius: number;
  length: number;
  angle: number;
  start_depth: number;
  end_depth: number;
  test_package: string;
  lab_name: string;
  lab_short_name: string;
  lab_address: string;
  lab_code: string;
  primary_spec: boolean;
  parallel_line_distance_ft: number;
}

export interface ISelectedRecord extends RogoDexieBase {
  AirtableRecord_id: number | undefined;
  AirtableSearch_id: number | undefined;
}

export interface ISession extends RogoDexieBase {
  token: string;
  robot_name: string;
  User_id: number | undefined;
  Robot_id: number | undefined;
  LastMission_id: number | undefined;
  Mission_id: number | undefined;
  Sample_id: number | undefined;
  Task_id: number | undefined;
}

export interface ISoilCore extends RogoDexieBase, CoordinatePoint, KMLSerializable {
  core_number: number;
  pulled: boolean;
  pulled_at: number;
  pulled_lat: number;
  pulled_lon: number;
  pulled_heading: number;
  source: CoreSource;
  waypoint_index: number;
  core_length_cm: number;
  depth_error: number;
  initiator?: number;
  test_core_Mission_id?: number;
  Sample_id?: number;
  SoilDumpEvent_id?: number;
  core_diameter_inches: string;
}

export interface ITask extends RogoDexieBase {
  name: TaskName;
  order: number;
  screen_name: string;
  local: boolean;
  sampling: boolean;
  robot: boolean;
}

export interface IUser extends RogoDexieBase {
  name: string;
  hashed_password: string;
  app_roles: AppRole[];
  airtable_name: string;
  user_id: number;
  id: string;
  email: string;
  user_flags: TeamUserFlags[];
}

export interface IWaypoint extends RogoDexieBase, CoordinatePoint {
  waypoint_number: number;
  Mission_id: number | undefined;
}

export interface IZone extends RogoDexieBase, KMLSerializable, MapElement {
  zone_name: string;
  zone_type: ZoneType;
  Mission_id: number | undefined;
}

export interface IZoneRecording extends RogoDexieBase {
  coordinates: Coordinate[];
  type: ZoneRecordingType;
  device: RecordingDevice;
  Mission_id: number | undefined;
}

export interface ScannedBox {
  box_id: string;
  timestamp: Date;
  image: string;
  boxOpsNotes?: string;
}

export const CropTypes = ['', 'Corn', 'Beans', 'Wheat', 'Cannot Identify', 'Other'] as const;
export type CropType = (typeof CropTypes)[number];

export const ResidueOptions = [
  '',
  'Normal',
  'Medium Residue',
  'Heavy Residue',
  'Deep Ripped',
  'Super Wet Bare Ground',
] as const;
export type Residue = (typeof ResidueOptions)[number];

export const ResidueHelperText: Record<Residue, string> = {
  '': '',
  'Super Wet Bare Ground': 'Bucket leaving depression in soil',
  Normal: 'Bean stubble or Low Residue',
  'Medium Residue': 'Light Corn Mat',
  'Heavy Residue': 'Fresh & thick corn Mat',
  'Deep Ripped': '',
};

export const ResidueDepthMap: Record<Residue, number> = {
  '': 0,
  'Super Wet Bare Ground': -0.5,
  Normal: 0,
  'Medium Residue': 0.5,
  'Heavy Residue': 1.0,
  'Deep Ripped': 2.0,
};

export type ClosestCore = readonly [SoilCore, number, boolean];

export interface IDataSyncInterface {
  restoreData: <T extends DataObject>(tableName: string) => Promise<DataList<T>>;
  saveData: <T extends DataObject>(tableName: string, data: DataList<T>) => Promise<void>;
}

export type SamplingSpecCreateParams = {
  Mission_id: number;
  spec_id: string;
  primary_spec: boolean;
  deal_id: string;
  pattern_type: GridPatterns;
  cores: number;
  radius: number;
  length: number;
  angle: number;
  start_depth: number;
  end_depth: number;
  parallel_line_distance_ft: number;
  barcode_regex?: string;
  lab_name?: string;
  lab_short_name?: string;
  lab_address?: string;
  lab_code?: string;
  test_package?: string;
};

export type SoilDumpEventCreateParams = {
  Mission_id: number;
  guid: string;
  timestamp: number;
  lat: number;
  lon: number;
  heading: number;
  PreviousDump_id?: number | undefined;
};

export type BoxCreateParams = {
  labName: string;
  labCode: string | undefined;
  uid: string | null;
  closedTimestamp: string;
  username: string;
  userId?: number;
  boxDailyCountNumber?: number;
  jobBoxCountIndex?: number;
  trackingNumber?: string;
  trackingNumberTime?: string;
};

export type SampleBoxDeSerialized = {
  uid: string;
  closedTimestamp: string;
  needsUpdated: boolean;
  labName: string;
  username: string;
  trackingNumber: string | undefined;
  trackingNumberTime: string | undefined;
  jobBoxCountIndex: number;
  dailyBoxCountIndex: number;
  reprint_reason: string;
};
