import React, { PureComponent } from 'react';

import BaseMap from './BaseMap';

import Airtable from '../../airtable';

import SchedulingLayer from './layers/SchedulingLayer';
import LiveRobotLayer from './layers/LiveRobotLayer';

import AssignmentButton from './buttons/AssignmentButton';
import DeselectAllButton from './buttons/DeselectAllButton';

import Collection from 'ol/Collection';

import { convertProjection4329, generateScheduledKey, someObjectValuesChanged } from '../../utils';
import { getJobFromFeature } from '../../dataModelHelpers';
import { getCurrentShift } from '../scheduling/helpers/shift';
import { alertError, alertWarn } from '../../alertDispatcher';
import { AirtableRecord } from '../../db';
import {
  ScheduleMap,
  JobBoundsMap,
  SchedulingBaseProps,
  KMLFeature,
  DefaultKMLFeatureProperties,
  SchedulingRecordTypes,
} from '../../types/types';
import Feature, { FeatureLike } from 'ol/Feature';
import GeometryType from 'ol/geom/GeometryType';
import SideBarInfoFields from '../scheduling/SideBarInfoFields';
import { IoIosStats } from 'react-icons/io';
import MapButton from './buttons/MapButton';
import { syncMulti } from '../../db_ops/airtable_record_ops';
import EventBus from '../../EventBus';

import * as Sentry from '@sentry/react';
import logger from '../../logger';
import { Geometry } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { Jobs, Shifts } from '@rogoag/airtable';
import { dispatchSetCenter, dispatchSetZoom } from '../../mapEvents';

interface SchedulingMapProps {
  showJobs: boolean;
  showDropoffs: boolean;
  filtered: AirtableRecord[];
  jobBounds: JobBoundsMap;
  selectedShift: string;
  availableShifts: AirtableRecord[];
  hiddenScheduled: string[];
  unassigned: AirtableRecord<any>[];
  scheduled: ScheduleMap;
  zoomField?: AirtableRecord;
  allRobots: AirtableRecord[];
  robotRefreshRate: number;
  updateUnassigned: (unassigned: AirtableRecord[]) => void;
  updateScheduled: (scheduled: ScheduleMap) => void;
  updateLoading: (loading: boolean) => void;
}

interface SchedulingMapState {
  selectedFeatures: Collection<Feature>;
  scheduleFeatures: KMLFeature<DefaultKMLFeatureProperties>[];
  showStats: boolean;
  totalAcres: number;
  totalSamples: number;
  averageSamples: number;
  averageAcres: number;
  totalFields: number;
  jobLinks: { name: string; link: string }[];
  statsDrawActive: boolean;
  fieldBoundaries: FeatureLike[];
}

export default class SchedulingMap extends PureComponent<SchedulingMapProps, SchedulingMapState> {
  constructor(props: SchedulingMapProps) {
    super(props);

    this.state = {
      selectedFeatures: new Collection([]),
      scheduleFeatures: [],
      showStats: false,
      totalAcres: 0,
      totalSamples: 0,
      averageSamples: 0,
      averageAcres: 0,
      totalFields: 0,
      jobLinks: [],
      statsDrawActive: false,
      fieldBoundaries: [],
    };

    this.updateSelectedFeatures = this.updateSelectedFeatures.bind(this);
    this.assignShift = this.assignShift.bind(this);
    this.deselectAll = this.deselectAll.bind(this);
  }

  async componentDidMount() {
    await this.loadAllFeatures();
  }

  async componentDidUpdate(prevProps: SchedulingMapProps) {
    // this is a map with shift identifiers as keys and arrays of jobs as values
    // we want to reduce the array to just be the job IDs
    const scheduled: Record<string, string[]> = {};
    Object.keys(this.props.scheduled).forEach((key) => {
      scheduled[key] = this.props.scheduled[key].map((job) => job.id);
    });

    if (JSON.stringify(this.props.jobBounds) !== JSON.stringify(prevProps.jobBounds)) {
      if (this.props.showJobs) {
        await this.loadAllFeatures();
      }
    }

    if (
      someObjectValuesChanged(this.props, prevProps, [
        'showJobs',
        'showDropoffs',
        'selectedShift',
        'hiddenScheduled',
        'scheduled',
        'filtered',
      ])
    ) {
      await this.loadAllFeatures();
    }
    // if (JSON.stringify(this.props.filtered) !== JSON.stringify(prevProps.filtered)) {
    //     await this.loadAllFeatures();
    // }
    // else if (this.props.selectedShift !== prevProps.selectedShift) {
    //     await this.loadAllFeatures();
    // }
    // else if (this.props.showJobs !== prevProps.showJobs) {
    //     await this.loadAllFeatures();
    // }
    // else if (this.props.showDropoffs !== prevProps.showDropoffs) {
    //     await this.loadAllFeatures();
    // }
    // else if (this.props.hiddenScheduled !== prevProps.hiddenScheduled) {
    //     await this.loadAllFeatures();
    // }
    // else if (this.props.scheduled !== prevProps.scheduled) {
    //     await this.loadAllFeatures();
    // }

    if (this.props.zoomField !== prevProps.zoomField && this.props.zoomField?.fields) {
      const zoomField = this.props.zoomField;
      const pullinCoords = this.getPullinFromZoomField(zoomField);

      if (pullinCoords) {
        dispatchSetCenter(pullinCoords);
        dispatchSetZoom(15);
      }
    }
  }

  updateSelectedFeatures(selectedFeatures: Collection<Feature>) {
    this.setState({ selectedFeatures });
  }

  getPullinFromZoomField(zoomField: AirtableRecord) {
    let pullinCoords: Coordinate | undefined = undefined;
    if (zoomField.table === 'Jobs') {
      const pullinCoordsString = zoomField.fields['Location']
        ? (zoomField.fields['Location'] as string).split(',')
        : undefined;
      if (pullinCoordsString) {
        pullinCoords = convertProjection4329([parseFloat(pullinCoordsString[1]), parseFloat(pullinCoordsString[0])]);
      }
    } else {
      if (zoomField.fields['Dropoff Lat'] && zoomField.fields['Dropoff Lon']) {
        const lon = zoomField.fields['Dropoff Lon'];
        const lat = zoomField.fields['Dropoff Lat'];
        pullinCoords = convertProjection4329([Array.isArray(lon) ? lon[0] : lon, Array.isArray(lat) ? lat[0] : lat]);
      }
    }

    if (!pullinCoords) {
      alertWarn('No valid pullin coordinates found to zoom to!');
    }

    return pullinCoords;
  }

  getPullinFromJob(job: AirtableRecord) {
    let pullinCoords: Coordinate | undefined = undefined;
    if (job.table === 'Jobs') {
      const pullinCoordsStrings = job.get('Location') ? (job.get('Location') as string).split(',') : undefined;
      if (pullinCoordsStrings) {
        pullinCoords = convertProjection4329([parseFloat(pullinCoordsStrings[1]), parseFloat(pullinCoordsStrings[0])]);
      }
    } else {
      if (job.get('Dropoff Lat') && job.get('Dropoff Lon')) {
        const lon = job.get('Dropoff Lon');
        const lat = job.get('Dropoff Lat');
        pullinCoords = convertProjection4329([Array.isArray(lon) ? lon[0] : lon, Array.isArray(lat) ? lat[0] : lat]);
      }
    }

    return pullinCoords;
  }

  loadUnassignedFeatures(unassigned: AirtableRecord[], boundaries: JobBoundsMap) {
    const unassignedFeatures = unassigned.flatMap((job) => {
      // Iterate through unassigned jobs and add the features
      const baseProps = this.getBaseProps(job);
      baseProps.unassigned = true;
      const pullinCoords = this.getPullinFromJob(job);
      const collectedFeats = this.collectScheduleFeatures(boundaries, baseProps, job, pullinCoords); // Generate features from the boundaries
      return collectedFeats;
    });

    return unassignedFeatures;
  }

  loadOrderLine(pullinCoords, lastJob, robot) {
    const orderLineFeature: KMLFeature = {
      type: 'Feature',
      properties: { type: 'OrderLine', robot },
      geometry: { type: GeometryType.LINE_STRING, coordinates: [lastJob, pullinCoords] },
    };

    return orderLineFeature;
  }

  loadScheduledFeatures(scheduled: ScheduleMap, boundaries: JobBoundsMap) {
    const scheduledFeatureList = Object.keys(scheduled).flatMap((key) => {
      const scheduleValues = key.split('/');
      let lastJob: Coordinate | undefined = undefined;
      let first = false;
      return scheduled[key].flatMap((job) => {
        let jobFeatures: KMLFeature[] = []; // features for this specific job
        const baseProps = this.getBaseProps(job);
        baseProps.unassigned = false;
        /* using the key for these values is faster than the getter.
                Over a large amount of jobs it makes a difference */
        baseProps.schedDate = scheduleValues[0];
        baseProps.robot = scheduleValues[1];
        baseProps.operatorName = scheduleValues[2];

        // @ts-ignore TODO: fix this
        baseProps.scheduleOrder = job.get('Order');
        /* this is used for determining what job is first.
                We don't use order because the first item in the order is not always equal to 0 */
        if (!first) {
          baseProps.first = true;
          first = true;
        }
        baseProps.selected = key === this.props.selectedShift;
        const pullinCoords = this.getPullinFromJob(job);
        if (key === this.props.selectedShift && lastJob && pullinCoords && pullinCoords.length) {
          jobFeatures.push(this.loadOrderLine(pullinCoords, lastJob, scheduleValues[0]));
          lastJob = pullinCoords;
        } else if (key === this.props.selectedShift && pullinCoords && pullinCoords.length) {
          lastJob = pullinCoords;
        }
        const collectedFeats = this.collectScheduleFeatures(boundaries, baseProps, job, pullinCoords);
        jobFeatures = jobFeatures.concat(collectedFeats);
        return jobFeatures;
      });
    });

    return scheduledFeatureList;
  }

  collectScheduleFeatures(
    boundaries: JobBoundsMap,
    baseProps: SchedulingBaseProps,
    job: AirtableRecord,
    pullinCoords?: Coordinate,
  ) {
    if (!pullinCoords) {
      return [];
    }
    const featureList: KMLFeature[] = [];
    const selectedFields = this.getJobArray();
    const bounds = boundaries[job.id];
    let internalOrder = selectedFields.findIndex((selectedJob) => selectedJob && selectedJob.id === job.id);
    if (bounds) {
      bounds.forEach((bound) => {
        const coords = bound.coords;
        const polyType = bound.type as GeometryType;
        const polyProps = Object.assign({}, baseProps);
        polyProps.type = 'Field';
        polyProps.internalOrder = internalOrder;
        const polygon = {
          type: 'Feature',
          properties: polyProps,
          geometry: {
            type: polyType,
            coordinates: coords,
          },
        };
        featureList.push(polygon);
      });
    }
    const pullinProps = Object.assign({}, baseProps);
    pullinProps.type = 'Pullin';
    pullinProps.internalOrder = internalOrder;
    const pullin = {
      type: 'Feature',
      properties: pullinProps,
      geometry: {
        type: GeometryType.POINT,
        coordinates: pullinCoords,
      },
    };
    featureList.push(pullin);
    return featureList;
  }

  async loadAllFeatures() {
    const boundaries = this.props.jobBounds; // the parsed boundaries
    // only want to show filtered jobs
    const unassigned = this.props.filtered;
    const scheduled = { ...this.props.scheduled };
    Object.keys(scheduled).forEach((key) => {
      if (this.props.hiddenScheduled.includes(key)) {
        scheduled[key] = scheduled[key] = [];
      }
    });
    let finalList: KMLFeature<DefaultKMLFeatureProperties>[] = []; // list of all the features to be added
    const scheduledFeatures = this.loadScheduledFeatures(scheduled, boundaries);
    finalList = finalList.concat(scheduledFeatures);
    const unassignedFeatures = this.loadUnassignedFeatures(unassigned, boundaries);
    finalList = finalList.concat(unassignedFeatures);

    //console.log(finalList.map((feature) => feature.properties.key).join(","));

    this.setState({ scheduleFeatures: finalList });
  }

  // 1. Get current shift (selected from sidebar)
  // 2. Get currently selected jobs (and dropoffs?)
  // 3. Get the current "Schedule Map"
  // 4.

  async assignShift() {
    this.props.updateLoading(true);
    const shift = getCurrentShift(this.props.selectedShift, this.props.availableShifts);
    const selectedFields = this.getJobArray();
    const scheduled = this.props.scheduled;
    console.log(`Shift: ${shift?.fields['Operator Name']}`);
    console.log(selectedFields.map((field) => field?.fields['Name']).join(','));
    if (shift) {
      const shiftRobot = shift.get('Robot')[0];
      const shiftSchedDate = shift.get('Sched Date');
      const shiftOperatorName = shift.get('Operator Name');
      const key = generateScheduledKey(shiftSchedDate, shiftRobot, shiftOperatorName);
      // this starts as all of the scheduled jobs for that operator first
      let orderedJobs: AirtableRecord[] = scheduled[key] || [];
      const allShiftTargetsIds = scheduled[key].map((job) => job.id);
      // const shiftJobsAndDropoffs = getField(shift, 'Jobs link', [] as string[]);
      // console.log('SHIFT JOBS', shiftJobsAndDropoffs.slice(), shift.id);
      // const shiftDropoffs = getField(shift, 'Shift Dropoffs', [] as string[]);
      // const allShiftTargets = shiftJobsAndDropoffs.concat(shiftDropoffs);

      const selectedInShift = selectedFields.filter((job) => job && allShiftTargetsIds.includes(job.id)); // grab all jobs that are selected that are also in the shift

      const shiftJobsSelected = selectedFields.map((job) => job?.id);
      if (selectedInShift.length === 0) {
        orderedJobs = orderedJobs.concat(selectedFields); // Append to end of shift then return
      } else {
        const anchor = selectedInShift[0]; // anchor will determine order to migrate internal order to job order
        orderedJobs = orderedJobs.filter((job) => !shiftJobsSelected.includes(job.id) || anchor.id === job.id); // remove all jobs except anchor
        const anchorIdx = orderedJobs.findIndex((job) => job.id === anchor.id); // get index of anchor
        orderedJobs = orderedJobs.filter((job) => job.id !== anchor.id); // now filter out the anchor
        for (let job of selectedFields.reverse()) {
          // Insert all of the elements with their internal order
          orderedJobs.splice(anchorIdx, 0, job);
        }
      }

      console.log('ORDERED JOBS', orderedJobs.map((job) => job.id).join(','));

      // Done with re-order... Now time to upload this to airtable and adjust other scheduled jobs and unassigned jobs
      await this.updateJobsAirtable(orderedJobs, key);
    } else {
      const onlyScheduled = selectedFields.filter((job) =>
        (['Shift Dropoffs', 'Dropoffs'].includes(job.table) && job.get('Shift')) || job.get('Scheduled?')
          ? // @ts-ignore
            !!job.get('Shift') && job.get('Shift')!.length // @ts-ignore
          : false,
      ); // avoid duplication

      await this.updateJobsAirtable(onlyScheduled, '');
    }
    this.props.updateLoading(false);
  }

  getBaseProps(job: AirtableRecord): SchedulingBaseProps {
    if (job.table === 'Jobs') {
      return {
        job_id: job.id,
        field_name: job.get('Field Name Clean'),
        lab: job.get('Lab Shortname') ? job.get('Lab Shortname')[0] : undefined,
        client: job.get('Client') ? job.get('Client')[0] : undefined,
        grower: job.get('Grower') ? job.get('Grower')[0] : undefined,
        pullinType: job.table,
      };
    } else {
      return {
        job_id: job.id,
        pullinType: job.table,
        dropoff_name:
          job.table === 'Dropoffs' ? job.get('Name') : job.get('Dropoff Name') ? job.get('Dropoff Name')[0] : '',
        dropoff_lab:
          job.table === 'Dropoffs' ? job.get('Labs') : job.get('Dropoff Lab') ? job.get('Dropoff Lab')[0] : '',
        address:
          job.table === 'Dropoffs'
            ? job.get('Location')
            : job.get('Dropoff Location')
              ? job.get('Dropoff Location')[0]
              : '',
      };
    }
  }

  // async shiftRollover(nextShift: AirtableRecord) {
  //     const key = this.props.selectedShift;
  //     const nextRobot = nextShift.get('Robot')[0];
  //     const nextDate = nextShift.get('Sched Date');
  //     const nextOperator = nextShift.get('Operator Name');
  //     const nextKey = `${nextRobot}/${nextDate}/${nextOperator}`;
  //     const rolloverJobs = this.props.scheduled[key].slice();
  //     const nextJobs = this.props.scheduled[nextKey].slice();
  //     await this.updateJobsAirtable(nextJobs.concat(rolloverJobs), nextKey);
  // }

  checkChanged(job: AirtableRecord, shiftId: string[], currentOrder: number) {
    // got a sentry error about not being able to access get on undefined. Job must be
    // undefined but not sure how? Still will do a check and add logging to be safe
    if (!job) {
      logger.log('SCHEDULING CHECK_CHANGED', 'Job is undefined');
      return false;
    }
    const jobShiftId = job.get('Shift') || [];
    const order = job.get('Order');
    return JSON.stringify(jobShiftId) !== JSON.stringify(shiftId) || order !== currentOrder;
  }

  getJobArray() {
    const selectedFeatures = this.state.selectedFeatures
      .getArray()
      .slice()
      .map((feature) => getJobFromFeature(this.props.scheduled, this.props.unassigned, feature));
    return selectedFeatures.filter((sel) => !!sel);
  }

  // This function is responsible for
  // - Checking if a shift assignment has changed

  async unassignPreviousShift(job: AirtableRecord) {
    const lastShiftId = job.get('Shift') ? job.get('Shift')[0] : undefined;
    if (lastShiftId) {
      const lastShift = this.props.availableShifts.find((shift) => shift.id === lastShiftId);
      if (lastShift && lastShift.get('Jobs link')) {
        await lastShift.set(
          'Jobs link',
          lastShift
            .get('Jobs link')
            .slice()
            .filter((jobLink) => jobLink !== job.id),
        );
      }
    }
  }

  async modifyJob(
    job: AirtableRecord,
    shift: AirtableRecord | undefined,
    currentOrder: number,
    jobsDropoffs: string[],
    { immediateSync = true } = {},
  ) {
    return await new Promise<AirtableRecord<any>>(async (resolve, reject) => {
      const shiftId = shift ? [shift.id] : [];
      currentOrder = shift ? currentOrder : 0;
      const scheduleItemHasChanged = this.checkChanged(job, shiftId, currentOrder);
      if (job.table === 'Jobs') {
        if (scheduleItemHasChanged) {
          await this.unassignPreviousShift(job);
          const robot = shift ? shift.get('Robot')[0] : null;
          await job.set('Robot', robot);
          await job.set('Shift', shiftId);
          await job.set('Order', currentOrder);
          if (shiftId.length > 0 && job.get('Skipped by Operator Time')) {
            // if scheduling on a shift, clear out the last skipped time
            await job.set('Skipped by Operator Time', null);
          }
          if (immediateSync && job.dirty) {
            await job.sync();
          }
        }
      } else if (job.table === 'Shift Dropoffs') {
        if (scheduleItemHasChanged) {
          await this.unassignPreviousShift(job);
          await job.set('Shift', shiftId);
          await job.set('Order', currentOrder);
          if (immediateSync && job.dirty) {
            await job.sync();
          }
        }
      } else if (job.table === 'Dropoffs') {
        job = await Airtable.createRecord('Shift Dropoffs', {
          Jobs: jobsDropoffs,
          Dropoff: [job.id],
          Shift: shiftId,
          Order: currentOrder,
        });
      } else {
        reject(`Unsupported table ${job.table}`);
      }
      job = await Airtable.getRecord(job.table, job.id);
      resolve(job);
    });
  }

  async updateJobsAirtable(orderedJobs: AirtableRecord[], key: string) {
    const scheduled: ScheduleMap = { ...this.props.scheduled };
    const scheduleJobIdMap: Record<string, string[]> = {};
    Object.keys(scheduled).forEach((key) => {
      scheduleJobIdMap[key] = scheduled[key].map((job) => job.id);
    });

    let unassigned = this.props.unassigned.slice();
    const shift = getCurrentShift(this.props.selectedShift, this.props.availableShifts);
    const newShift: SchedulingRecordTypes[] = [];
    let jobDropoffs: string[] = [];
    let dirtyUpdates: AirtableRecord[] = [];
    for (let currentOrder = 0; currentOrder < orderedJobs.length; currentOrder++) {
      // this definitely always exists
      const job = orderedJobs[currentOrder];
      let schedJob: SchedulingRecordTypes;
      const isScheduledJob = job.table === 'Jobs' && Boolean(job.get('Shift'));
      const isScheduledDropoff =
        job.table === 'Shift Dropoffs' && job.get('Shift') ? (job.get('Shift') as any[]).length : 0; // use shift length as bool
      const isScheduled = isScheduledJob || isScheduledDropoff;
      if (!isScheduled) {
        console.log(`Job ${job.fields['Name']} is not scheduled`);
        const idx = unassigned.findIndex((unassignedJob) => unassignedJob.id === job.id);
        if (idx === -1) {
          Sentry.setContext('assign_shift', {
            job: job?.id,
            unassigned: JSON.stringify(unassigned),
          });
          alertError(`Could not find job ${job?.id} in unassigned jobs`);
          Sentry.captureMessage('Could not find job in unassigned jobs');
          continue;
        }
        schedJob = unassigned[idx];
        if (schedJob) {
          schedJob = await this.modifyJob(schedJob, shift, currentOrder, jobDropoffs, { immediateSync: false }); // add the correct properties
        }

        if (schedJob && schedJob.dirty) {
          dirtyUpdates.push(schedJob);
        }
        if (job.table === 'Jobs') {
          // Only remove job from unassigned
          unassigned.splice(idx, 1);
        }
      } else {
        console.log(`Job ${job.fields['Name']} is scheduled`);
        // Dropoff uses lookup(so it returns array) so we need to check the type first
        const robot = typeof job.get('Robot') === 'string' ? job.get('Robot') : job.get('Robot')[0];
        const jobShiftId = (job.get('Shift') || [])[0];
        const shiftRecord = await AirtableRecord.getRecord<Shifts>('Shifts', jobShiftId);
        if (!shiftRecord) {
          throw new Error(`Could not find shift ${jobShiftId}`);
        }
        const schedDate = shiftRecord.get('Sched Date');
        if (!schedDate) {
          throw new Error(`Could not find sched date for shift ${jobShiftId}`);
        }
        const operatorName = shiftRecord.get('Operator Name')?.[0];
        //const schedDate = job.get('Sched Date')[0];
        //const operatorName = job.get('Operator Name')[0];
        const currentKey = generateScheduledKey(schedDate, robot, operatorName);
        const idx = scheduled[currentKey]?.findIndex((scheduledJob) => scheduledJob.id === job.id);
        schedJob = scheduled[currentKey][idx];
        if (schedJob) {
          schedJob = await this.modifyJob(schedJob, shift, currentOrder, jobDropoffs, { immediateSync: false });
        }

        if (schedJob && schedJob.dirty) {
          dirtyUpdates.push(schedJob);
        }
        scheduled[currentKey].splice(idx, 1);
        // if (schedJob) {
        //     schedJob = await this.modifyJob(schedJob, shift, currentOrder, jobDropoffs, { immediateSync: false });
        //     if (schedJob.dirty) {
        //         dirtyUpdates.push(schedJob);
        //     }
        //     scheduled[currentKey].splice(idx, 1);
        // } else {
        //     const scheduleJobIdMap: Record<string, string[]> = {};
        //     Object.keys(scheduled).forEach((key) => {
        //         scheduleJobIdMap[key] = scheduled[key].map((job) => job.id);
        //     });
        //     console.log(scheduled);
        //     Sentry.setContext('assign_shift', {
        //         currentKey,
        //         scheduleJobIdMap: JSON.stringify(scheduleJobIdMap),
        //         job: job?.id,
        //         idx,
        //     });
        //     alertError(`Could not find job ${job?.id} in scheduled jobs, something went wrong`);
        //     Sentry.captureMessage('Could not find job in scheduled jobs');
        //     continue;
        // }
      }
      if (shift && ['Dropoffs', 'Shift Dropoffs'].includes(job.table)) {
        for (const orderedJob of orderedJobs) {
          if (jobDropoffs.includes(orderedJob.id)) {
            const dropoff = await this.updateJobDropoff(orderedJob, [schedJob.id], { immediateSync: false });
            if (dropoff.dirty) {
              dirtyUpdates.push(dropoff);
            }
          }
        }
        jobDropoffs = [];
      } else if (job.table === 'Jobs' && shift) {
        jobDropoffs.push(job.id);
      }
      newShift.push(schedJob);
    } // for...

    if (shift) {
      scheduled[key] = newShift;
      /* Need to directly modify these links, we are not refreshing the shift for efficiency */
      shift.fields['Jobs link'] = newShift.filter((job) => job.table === 'Jobs').map((job) => job.id);
      shift.fields['Shift Dropoffs'] = newShift.filter((job) => job.table === 'Shift Dropoffs').map((job) => job.id);
    } else {
      unassigned = unassigned.concat(newShift.filter((job) => job.table === 'Jobs'));
    }

    Sentry.setContext('assign_shift', {
      recordIds: dirtyUpdates.map((record) => record.id),
      operator: shift ? shift.get('Operator Name') : 'Unassigned',
    });
    await syncMulti(dirtyUpdates);
    EventBus.dispatch('SCHEDULING:REFRESH_JOBS');

    this.props.updateScheduled(scheduled);
    this.props.updateUnassigned(unassigned);

    this.setState({ selectedFeatures: new Collection([]) });
  }

  async updateJobDropoff(job: AirtableRecord, dropoff: string[], { immediateSync = true } = {}) {
    return await new Promise<AirtableRecord>(async (resolve, reject) => {
      const currentDropoff = job.get('Dropoffs/Shipping') || [];
      if (JSON.stringify(currentDropoff) !== JSON.stringify(dropoff)) {
        await job.set('Dropoffs/Shipping', dropoff);
        if (immediateSync && job.dirty) {
          await job.sync();
        }
      }
      resolve(job);
    });
  }

  toggleStatsDraw = () => {
    this.setState({ statsDrawActive: !this.state.statsDrawActive, showStats: false });
  };

  //async displayStats(fieldBoundaries: Feature<Polygon>[]) {
  displayStats = async (fieldBoundaries: Feature<Geometry>[]) => {
    let totalAcres = 0;
    let totalSamples = 0;
    let numValidAcres = 0;
    let numValidSamples = 0;
    let totalFields = 0;
    const jobLinks: { name: string; link: string }[] = [];
    const baseLink = `https://airtable.com/${import.meta.env.VITE_AIRTABLE_BASE_ID}/tblXij9xpNwUCLRIM/viwzbSmFpjFlulGg8`;

    const existIds: string[] = [];
    for (const fieldBoundary of fieldBoundaries) {
      // @ts-ignore
      const jobId = fieldBoundary.properties.job_id as string;
      if (existIds.includes(jobId)) {
        continue;
      }

      const job = await Airtable.getRecord<Jobs>('Jobs', jobId);
      if (!job) {
        continue;
      }

      const jobLink = {
        name: job.get('Field from Form') || 'No Field Name',
        link: `${baseLink}/${jobId}`,
      };

      jobLinks.push(jobLink);
      const acres = parseFloat(job.get('Boundary Acres')?.toString() || '0');
      const samples = parseInt(job.get('# Samples')?.toString() || '0');
      totalAcres += acres;
      totalSamples += samples;

      if (acres) numValidAcres++;
      if (samples) numValidSamples++;

      totalFields++;
      existIds.push(jobId);
    }

    totalAcres = Math.round(totalAcres);
    totalSamples = Math.round(totalSamples);

    const averageSamples = Math.round(totalSamples / numValidSamples) || 0;
    const averageAcres = Math.round(totalAcres / numValidAcres) || 0;

    this.setState({
      showStats: true,
      totalAcres,
      totalSamples,
      averageSamples,
      averageAcres,
      totalFields,
      jobLinks,
      fieldBoundaries,
    });
  };

  setWeatherDays = async (weatherDays: number) => {
    const weatherDayPromises: Promise<void>[] = [];
    for (const fieldBoundary of this.state.fieldBoundaries) {
      // @ts-ignore
      const job = await Airtable.getRecord<Jobs>('Jobs', fieldBoundary.properties.job_id);
      const currentWeatherDays = job.get('Weather Days (Customer Approved)') || 0;
      await job.set('Weather Days (Customer Approved)', currentWeatherDays + weatherDays);
      const syncPromise = job.sync();
      weatherDayPromises.push(syncPromise);
    }
    await Promise.all(weatherDayPromises);
  };

  deselectAll() {
    this.setState({ selectedFeatures: new Collection([]) });
  }

  render() {
    return (
      <BaseMap
        buttons={[
          <MapButton onClick={this.toggleStatsDraw} toggled={this.state.statsDrawActive}>
            <IoIosStats size={20} />
          </MapButton>,
          this.state.selectedFeatures.getArray().length > 0 && <AssignmentButton onClick={this.assignShift} />,
          this.state.selectedFeatures.getArray().length > 0 && <DeselectAllButton onClick={this.deselectAll} />,
          this.state.showStats && (
            <SideBarInfoFields
              totalAcres={this.state.totalAcres}
              totalSamples={this.state.totalSamples}
              averageAcres={this.state.averageAcres}
              averageSamples={this.state.averageSamples}
              totalFields={this.state.totalFields}
              jobLinks={this.state.jobLinks}
              setWeatherDays={this.setWeatherDays}
              onClose={this.toggleStatsDraw}
            />
          ),
          // <PlaybackControls></PlaybackControls>,

          // <LayerCheckContainer>
          //     <LayerCustomCheckBox
          //         label={'Weather layer'}
          //         onChange={(e) => {
          //             this.setState({ showWeatherTiles: e.target.checked });
          //         }}
          //         checked={this.state.showWeatherTiles}
          //     />

          // </LayerCheckContainer>
        ]}
        layers={[
          <SchedulingLayer
            // @ts-ignore
            scheduleFeatures={this.state.scheduleFeatures}
            updateSelectedFeatures={this.updateSelectedFeatures}
            selectedFeatures={this.state.selectedFeatures}
            displayStats={this.displayStats}
            statsDrawActive={this.state.statsDrawActive}
          />,
          <LiveRobotLayer allRobots={this.props.allRobots} robotRefreshRate={this.props.robotRefreshRate} />,
        ]}
      />
    );
  }
}
