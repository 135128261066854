import { Grid, Modal } from '@material-ui/core';
import { PhotoCapture } from '../utils';

interface Props {
  photoModalActive: boolean;
  loadingCapture: boolean;
  handleCapture: (file: File) => Promise<void>;
  handleLoadFailure: (error: Error) => void;
  closeCapture: () => void;
  onCanPlay: () => void;
}
export function PhotoCaptureModal({
  photoModalActive,
  loadingCapture,
  handleCapture,
  handleLoadFailure,
  closeCapture,
  onCanPlay,
}: Props) {
  if (!photoModalActive) {
    return null;
  }

  return (
    <Modal
      key={'modal 2'}
      style={{ height: '100%', width: '100%' }}
      open={true}
      className={loadingCapture ? 'hide' : ''}
    >
      <div>
        <Grid container justifyContent="center">
          <PhotoCapture
            handleCapture={handleCapture}
            handleLoadFailure={handleLoadFailure}
            closeCapture={closeCapture}
            onCanPlay={onCanPlay}
          />
        </Grid>
      </div>
    </Modal>
  );
}
