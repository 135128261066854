import { KMLDocument, KMLElement } from '../kml';
import { JobLoader, JobChecker } from '@rogoag/kml-error-checker';
import { fromLatLon } from 'utm';
import { DownloadOptions, ZipOptions, zip as shpZip } from '@mapbox/shp-write';
import LineString from 'ol/geom/LineString';
import { getStyleText } from '../style';
import { getVersionNumber } from '../version';
import * as turf from '@turf/turf';
import * as Sentry from '@sentry/react';
import {
  AirtableRecord,
  Boundary,
  CorePoint,
  GridPatterns,
  Job,
  Mission,
  PathPoint,
  Sample,
  SampleBox,
  SampleCentroid,
  SampleSite,
  SamplingSpec,
  SamplingTypes,
  SoilCore,
  SoilDumpEvent,
  Waypoint,
  Zone,
  ZoneType,
} from '../db';
import shp from 'shpjs';
import Airtable from '../airtable';
import { cleanSet, isclose, convertProjection4329, formatDateAsString, uuidv4 } from '../utils';
import { ft_to_m, METERS_BETWEEN_CORE_LINES_FOR_MULTI_LAB } from '../constants';
import { getField, getFieldArrayIncludes } from '../dataModelHelpers';
import { flattenDeep, orderBy, flatten } from 'lodash';
import { alertError, alertInfo, alertWarn, alertWarnConfirm } from '../alertDispatcher';
import hash from 'object-hash';
import logger from '../logger';
import { dispatchMissionUpdated } from '../missionEvents';
import {
  SampleChangeType,
  ConfirmColumn,
  CoordinatePoint,
  KMLFeature,
  RogoShapefile,
  RogoShapefileType,
  SampleSource,
  TakenCore,
  CoreSource,
  KMLSoilCoreFeatureProperties,
} from '../types/types';
import { FrameID, RosBoundary, RosMissionMsgWithFieldParam, RosSample, RosWaypoint } from '../types/rosmsg';
import { FeatureCollection, MultiPolygon, Point, Polygon, Position } from '@turf/helpers/dist/js/lib/geojson';
import { PRINTER_CONFIG } from '../components/robot/configs/PrinterConfig';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Coordinate } from 'ol/coordinate';
import GeometryType from 'ol/geom/GeometryType';
import { KmlFeatureType } from '../featureTypes';
import {
  BD_CORE_COMMAND,
  CREATE_NEW_POLYGON as CREATE_NEW_BOUNDARY,
  FieldParams,
  KM_PER_MILE,
  MM_PER_FT,
  REORDER_TOLERANCE_METERS,
} from '../constants';
import { getCurrentSession } from '../dataModelHelpers';
import { genPdfCheckinOld } from '../pdfcheckin_old';
import { BarcodeTypes, Companies, Deals, Jobs, Labs } from '@rogoag/airtable';
import {
  MapCalculationPositionStorage,
  MapDebugStorage,
  MapShowPulledCoreLocation,
  RobotArmOffsetX,
  RobotArmOffsetY,
} from '../db/local_storage';
import SoilCoreClass, { SoilCoreList } from '../db/SoilCoreClass';
import PathGridGenerator from '../services/PathGridGenerator';
import OriginalPointsOrZonesFileProcessor from '../services/mission-loading/original/from-shapes/OriginalPointsOrZonesFileProcessor';
import { AirtableRecordFields } from '../db/types';

const Y_LIMIT_FT = 10;
export const CORE_STOP_MAX_DISTANCE_FT = 2;

export function assign_id(this: Mission) {
  const outer_boundary_ids = cleanSet(flatten(this.getZones().map((sel) => sel.getOuterZoneBoundarys()))).map(
    (sel) => sel.boundary_id,
  );

  const inner_boundary_ids = cleanSet(flatten(this.getZones().map((sel) => sel.getInnerZoneBoundarys()))).map(
    (sel) => sel.boundary_id,
  );

  const outer_poly_ids = cleanSet(flatten(this.getZones().map((sel) => sel.getOuterZoneBoundarys()))).map(
    (sel) => sel.poly_id,
  );

  const inner_poly_ids = cleanSet(flatten(this.getZones().map((sel) => sel.getInnerZoneBoundarys()))).map(
    (sel) => sel.poly_id,
  );

  const all_ids = outer_boundary_ids.concat(inner_boundary_ids, outer_poly_ids, inner_poly_ids);

  return all_ids.length > 0 ? Math.max(...all_ids) + 1 : 1;
}

export function to_kml(this: Mission, { use_pulled_locations = false, insert_boxes = true, safe_mode = false }) {
  let success = false;
  try {
    const kml = this._to_kml({
      use_pulled_locations,
      insert_boxes,
      safe_mode,
    });
    success = true;
    const doc = new KMLDocument(kml);
    return doc;
  } catch (e) {
    console.error('Could not download KML. Trying in safe mode.', e);
    logger.log('TO_KML', e);
    Sentry.captureException(e);
  }

  if (!success) {
    try {
      const kml = this._to_kml({ insert_boxes, use_pulled_locations: false, safe_mode: true });
      return new KMLDocument(kml);
    } catch (e) {
      console.error('Could not download KML in safe mode.', e);
      logger.log('TO_KML_SAFE', e);
      Sentry.captureException(e);
    }
  }
}

function createFolder(parent: KMLElement, name: string, open: boolean = true, visibility: boolean = true) {
  const newFolder = KMLElement.subelement(parent, 'Folder');
  const folder_name = KMLElement.subelement(newFolder, 'name');
  folder_name.setText(name);
  const folder_open = KMLElement.subelement(newFolder, 'open');
  folder_open.setText(open ? '1' : '0');
  const folder_vis = KMLElement.subelement(newFolder, 'visibility');
  folder_vis.setText(visibility ? '1' : '0');

  return newFolder;
}

/**
 *
 * @param this
 * @param param1
 * @returns
 *
 * This function is used to convert the mission to a KML document
 *
 * There are some subtle aspects to its design
 *
 * First, the loading mechanism in the app assumes there is some order to the serialized items
 * When the soil cores are loaded, it is assumed that centroids will exist to match to.
 * Therefore, the order of the items is important. (At least soil cores after centroids).
 *
 * This is the current order
 * - collected boundaries (if any)
 * - boundaries
 * - zones
 * - paths
 * - centroids
 * - cores
 */
export function _to_kml(this: Mission, { use_pulled_locations = false, insert_boxes = true, safe_mode = false } = {}) {
  const kml = new KMLDocument();
  const doc = KMLElement.subelement(kml.getRoot(), 'Document');
  const doc_open = KMLElement.subelement(doc, 'open');
  doc_open.setText('1');
  const nm = KMLElement.subelement(doc, 'name');
  nm.setText(this.name);
  const descrip = KMLElement.subelement(doc, 'description');
  descrip.setText(this.description);
  if (this.pullin_lat !== 0.0 || this.pullin_lon !== 0.0) {
    const placemark = KMLElement.subelement(doc, 'Placemark');
    const placemark_vis = KMLElement.subelement(placemark, 'visibility');
    placemark_vis.setText('1');
    const nm = KMLElement.subelement(placemark, 'name');
    nm.setText('pullin');
    const point = KMLElement.subelement(placemark, 'Point');
    const coords = KMLElement.subelement(point, 'coordinates');
    coords.setText(`${this.pullin_lon},${this.pullin_lat},0`);
    const style = KMLElement.subelement(placemark, 'styleUrl');
    style.setText('#pullin_location');
  }

  const job = this.getJob();
  if (!job) {
    alertError('No job found for mission!');

    return;
  }

  doc.putExtendedData('rogo_version', getVersionNumber());
  doc.putExtendedData('job_id', this.job_id || '');
  doc.putExtendedData('attachment_id', job.attachment_id);
  doc.putExtendedData('test_package', job.test_package);
  doc.putExtendedData('event_id', job.event_id);
  doc.putExtendedData('client', job.client);
  doc.putExtendedData('grower', job.grower);
  doc.putExtendedData('grower_id', job.grower_id);
  doc.putExtendedData('farm', job.farm);
  doc.putExtendedData('field', job.field);
  doc.putExtendedData('billing_account', job.billing_account);
  doc.putExtendedData('sampling_company_name', job.sampling_company_name);
  doc.putExtendedData('sampling_company_id', job.sampling_company_id);
  doc.putExtendedData('response_email', job.response_email);
  doc.putExtendedData('lab_name', job.lab_name.replace(/"/g, ''));
  doc.putExtendedData('lab_code', job.lab_code);
  doc.putExtendedData('in_field_notes_ops', job.in_field_notes_ops);
  doc.putExtendedData('tilled_field_at_sampling', job.tilled_field_at_sampling);
  doc.putExtendedData('boundary_change_type', job.boundary_change_type);
  doc.putExtendedData('boundary_change_notes', job.boundary_change_notes);
  doc.putExtendedData('lab_address', job.lab_address);
  doc.putExtendedData('lab_primary_delivery', job.lab_primary_delivery);
  doc.putExtendedData('field_id', job.field_id);
  doc.putExtendedData('add_on_freq', job.add_on_freq);
  doc.putExtendedData('sample_order_type', job.sample_order_type);
  doc.putExtendedData('lab_submittal_id', job.lab_submittal_id);
  doc.putExtendedData('lab_qrs_required', job.lab_qrs_required);
  doc.putExtendedData('auto_renumber', job.auto_renumber);
  doc.putExtendedData('lab_instructions', job.lab_instructions);
  doc.putExtendedData('submitter_notified_id', job.submitter_notified_id);
  doc.putExtendedData('sites_type', job.sites_type);
  doc.putExtendedData('job_flags', JSON.stringify(job.job_flags));
  doc.putExtendedData('last_modified', this.last_modified);
  doc.putExtendedData('sample_date', this.sample_date);
  doc.putExtendedData('nav_checksum', this.nav_checksum);
  doc.putExtendedData('active', Boolean(this.getSession()));
  doc.putExtendedData('settings_changes', this.settings_changes);
  doc.putExtendedData('settings_start', this.settings_start);
  doc.putExtendedData('settings_end', this.settings_end);
  doc.putExtendedData('cores', this.cores);
  doc.putExtendedData('required_fields', job.required_fields);
  doc.putExtendedData('allowed_to_renumber', job.allowed_to_renumber);
  doc.putExtendedData('allowed_to_create', job.allowed_to_create);
  doc.putExtendedData('allowed_to_move', job.allowed_to_move);
  doc.putExtendedData('core_diameter', job.core_diameter);
  doc.putExtendedData('field_aligned_sample_path', job.field_aligned_sample_path);
  doc.putExtendedData('manual_hole_depth_measurement', job.manual_hole_depth_measurement);
  // doc.putExtendedData('manual_plunge_depth_measurement', job.manual_plunge_depth_measurement);
  doc.putExtendedData('manual_core_length_measurement', job.manual_core_length_measurement);
  doc.putExtendedData('manual_core_loss_measurement', job.manual_core_loss_measurement);
  doc.putExtendedData('use_original_sample_id', job.use_original_sample_id);
  doc.putExtendedData('plot_settings', job.plot_settings);
  doc.putExtendedData('plot_mission', job.plot_mission);
  doc.putExtendedData('disable_core_line_alteration', job.disable_core_line_alteration);
  doc.putExtendedData('entrance_interview_residue', job.entrance_interview_residue);
  doc.putExtendedData('entrance_interview_crop', job.entrance_interview_crop);
  doc.putExtendedData('sampling_tolerance_ft', job.sampling_tolerance_ft);
  doc.putExtendedData('sampling_type_special', job.sampling_type_special);
  doc.putExtendedData('implement_centerted_navigation', job.implement_centerted_navigation);
  doc.putExtendedData('zone_interleave', job.zone_interleave);
  doc.putExtendedData('strict_core_enforcement', job.strict_core_enforcement);
  doc.putExtendedData('enable_manual_drive_aid', job.enable_manual_drive_aid);
  doc.putExtendedData('auto_zoom_near_sample', job.auto_zoom_near_sample);
  doc.putExtendedData('lab_short_name', job.lab_short_name);
  doc.putExtendedData('open_time', job.open_time);
  doc.putExtendedData('offset_x', RobotArmOffsetX.get());
  doc.putExtendedData('offset_y', RobotArmOffsetY.get());

  if (insert_boxes) {
    // puts boxes as JSON strings into extended data
    SampleBox.toKml(this._instance_id, doc);

    // // puts boxes as XML elements in the file
    // const boxes_folder = KMLElement.element('SampleBoxes');
    // this.getAllSampleBoxes().forEach(box => {
    //   boxes_folder.append(box.to_kml());
    // });
    // doc.putExtendedData('SampleBoxes', boxes_folder);
  }

  SoilDumpEvent.toKml(this._instance_id, doc);

  SamplingSpec.toKml(this._instance_id, doc);

  // const specsFolder = KMLElement.element('SamplingSpecs');
  // this.getSamplingSpecs().forEach(spec => {
  //   const spec_kml = spec.to_kml();
  //   if (spec_kml) {
  //     specsFolder.append(spec_kml);
  //   }
  // });
  // doc.putExtendedData('SamplingSpecs', specsFolder);

  const target_folder = createFolder(doc, 'target', true, true);
  let collected_boundaries_folder: KMLElement | null = null;
  for (const collected_boundary of cleanSet(
    orderBy(
      this.getZones().filter((sel) => sel.zone_type === ZoneType.COLLECTED_FIELD),
      ['zone_name'],
      ['asc'],
    ),
  )) {
    const collected_boundary_kml = collected_boundary.to_kml();
    if (collected_boundary_kml && collected_boundary_kml.length > 0) {
      // I believe we add the folder inside the loop so that if there are none of these elements
      // we don't create an empty folder
      if (!collected_boundaries_folder) {
        collected_boundaries_folder = createFolder(target_folder, 'collected boundaries');
      }
      for (const bnd of collected_boundary_kml) {
        collected_boundaries_folder.append(bnd);
      }
    }
  }

  if (!safe_mode) {
    let boundaries_folder: KMLElement | null = null;
    for (const boundary of cleanSet(
      orderBy(
        this.getZones().filter(
          (sel) => sel.zone_type !== ZoneType.SAMPLE_ZONE && sel.zone_type !== ZoneType.COLLECTED_FIELD,
        ),
        ['zone_name'],
        ['asc'],
      ),
    )) {
      const boundary_kml = boundary.to_kml();
      if (boundary_kml && boundary_kml.length > 0) {
        if (!boundaries_folder) {
          boundaries_folder = createFolder(target_folder, 'boundaries');
        }
        for (const bnd of boundary_kml) {
          boundaries_folder.append(bnd);
        }
      }
    }

    let zones_folder: KMLElement | null = null;
    for (const zone of cleanSet(
      orderBy(
        this.getZones().filter((sel) => sel.zone_type === ZoneType.SAMPLE_ZONE),
        ['zone_name'],
        ['asc'],
      ),
    )) {
      const zone_kml = zone.to_kml();
      if (zone_kml && zone_kml.length > 0) {
        if (!zones_folder) {
          zones_folder = createFolder(target_folder, 'zones');
        }
        for (const zn of zone_kml) {
          zones_folder.append(zn);
        }
      }
    }

    let paths_folder: KMLElement | null = null;
    let path_placemark: KMLElement | null = null;
    let path_coords: KMLElement | null = null;
    for (const [i, waypoint] of cleanSet(orderBy(this.getWaypoints(), ['waypoint_number'], ['asc'])).entries()) {
      if (!path_placemark) {
        if (!paths_folder) {
          paths_folder = createFolder(target_folder, 'paths');
        }
        path_placemark = KMLElement.subelement(paths_folder, 'Placemark');
        const path_placemark_vis = KMLElement.subelement(path_placemark, 'visibility');
        path_placemark_vis.setText('1');
        const path_nm = KMLElement.subelement(path_placemark, 'name');
        path_nm.setText('path');
        const path_ls = KMLElement.subelement(path_placemark, 'LineString');
        path_coords = KMLElement.subelement(path_ls, 'coordinates');
        path_coords.setText('');
        const style = KMLElement.subelement(path_placemark, 'styleUrl');
        style.setText('#navigation_path');
      }
      if (path_coords) {
        path_coords.setText(`${path_coords.getText()}${i > 0 ? ' ' : ''}${waypoint.lon},${waypoint.lat},0`);
      }
    }
  }

  for (const layer of [SamplingTypes.REGULAR, SamplingTypes.ISNT_1, SamplingTypes.ISNT_2, SamplingTypes.CYST]) {
    let layer_folder: KMLElement | null = null;
    const _create_layer = () => {
      layer_folder = KMLElement.subelement(target_folder, 'Folder');
      const layer_folder_nm = KMLElement.subelement(layer_folder, 'name');
      if (layer === SamplingTypes.REGULAR) {
        layer_folder_nm.setText('Regular');
      } else if (layer === SamplingTypes.ISNT_1) {
        layer_folder_nm.setText('ISNT 1');
      } else if (layer === SamplingTypes.ISNT_2) {
        layer_folder_nm.setText('ISNT 2');
      } else if (layer === SamplingTypes.CYST) {
        layer_folder_nm.setText('Cyst');
      }
      const layer_folder_open = KMLElement.subelement(layer_folder, 'open');
      layer_folder_open.setText('1');
      return layer_folder;
    };

    let centroids_folder: KMLElement | null = null;
    const allSites = cleanSet(
      flatten(
        this.getSampleSites()
          .map((site) => site.getSampleCentroid())
          .map((centroid) => centroid && centroid.getSampleSite()),
      ),
    );
    const allSamples = cleanSet(
      // TODO we are forcing site to be non-null right now, is that always true?
      flatten(allSites.map((site) => site!.getSamples())).filter((sample) => sample.sample_type === layer),
    );
    for (const sample of orderBy(
      allSamples.filter((sel) => sel.sample_type === layer),
      ['order'],
      ['asc'],
    )) {
      const sample_kml = sample.to_kml({ use_pulled_locations });
      if (sample_kml) {
        if (!centroids_folder) {
          if (!layer_folder) {
            layer_folder = _create_layer();
          }
          centroids_folder = createFolder(layer_folder, 'centroids');
        }
        centroids_folder.append(sample_kml);
      }
    }

    if (!safe_mode) {
      let cores_folder: KMLElement | null = null;
      const allCores = cleanSet(
        flatten(
          this.getSampleSites()
            .map((site) => site.getSamples())
            .map((sample) => sample.getSoilCores()),
        ),
      );

      const filteredCores = allCores.filter((sel) => sel.getSample()?.sample_type === layer);
      const orderedCores = orderBy(filteredCores, [(core) => core.getSample()?.order, 'core_number'], ['asc']);
      for (const core of orderedCores) {
        const core_kml = core.to_kml(use_pulled_locations);
        if (core_kml) {
          if (!cores_folder) {
            if (!layer_folder) {
              layer_folder = _create_layer();
            }
            cores_folder = createFolder(layer_folder, 'cores');
          }
          cores_folder.append(core_kml);
        }
      }
    }
  }

  // build the style string
  const style_zones = cleanSet(this.getZones().filter((sel) => sel.zone_type === ZoneType.SAMPLE_ZONE)).map((sel) => ({
    id: `sample_zone_${sel.zone_name}`,
    color: sel.get_color(),
  }));
  doc.extendFromText(getStyleText(style_zones));
  return kml;
}

export async function unskipAllSkipped(this: Mission) {
  const samples = this.getSamples();
  await Promise.all(
    samples.map(async (sample) => {
      if (sample.change_type === 'Skip') {
        sample.change_type = 'None';
        sample.change_reason = '';
        // await sample.removeFromPath();
      }
    }),
  );
  // for (const sample of samples) {
  //   if (sample.change_type === 'Skip') {
  //     sample.change_type = 'None';
  //     sample.change_reason = '';
  //   }
  // }
  await this.calculate_path_restore_cores();
}

export async function skipUnsampled(this: Mission, change_type: SampleChangeType, change_reason: string) {
  // get all samples
  const samples = this.getSamples();

  for (const sample of samples) {
    if (sample.bag_id) {
      continue;
    }

    sample.change_type = change_type;
    sample.change_reason = change_reason;

    for (const wp of this.getWaypoints()) {
      if (wp.getCorePoint()?.getSoilCore()?.Sample_id === sample.instance_id) {
        wp.dispose();
      }
    }

    sample.resetPulled();
  }

  await this.calculate_path_restore_cores();
}

export function getCoresTaken(this: Mission) {
  const coresText = this.cores;
  if (!coresText) {
    return [];
  }
  // parse the coresText as JSON
  const cores = JSON.parse(coresText) as TakenCore[];
  if (!cores) {
    return [];
  }
  return cores;
}

export async function reorder(
  this: Mission,
  coords: Coordinate[],
  renumber: boolean,
  sampling: boolean,
  reorderCentroids?: Set<CoordinatePoint>,
) {
  const error_messages: string[] = [];
  const patternType = this.getPrimarySpec()?.pattern_type;

  // grid sampling
  let re_ordered_samples: Sample[] = [];

  let re_ordered_cores: SoilCore[] =
    reorderCentroids && this.getJob()?.zone_interleave ? ([...reorderCentroids] as SoilCore[]) : [];
  if (patternType !== GridPatterns.ZONE) {
    // TODO this will get weird if there are multiple samples at the same site
    const samples = cleanSet(flatten(this.getSampleSites().map((site) => site.getSamples())));
    for (const [lat, lon] of coords) {
      // search for the closest sample point within 20 meters
      const utm_loc = fromLatLon(lat, lon);
      let shortest_distance = -1;
      let sample_index = -1;

      // This code finds the index of the sample that is closest to the given UTM location
      // The code loops through all the samples in the samples array, and calculates the distance between the UTM location and the sample location
      // If the distance is less than the shortest distance found so far, set the shortest distance to the current distance, and set the sample index to the current index
      // The code returns the sample index of the sample that is closest to the given UTM location

      for (const [i, sample] of samples.entries()) {
        const sample_centroid = sample.getSampleSite().getSampleCentroid();
        if (!sample_centroid) {
          throw new Error('Sample centroid not found');
        }
        const sample_utm_loc = fromLatLon(sample_centroid.lat, sample_centroid.lon);
        const distance = Math.sqrt(
          (sample_utm_loc.easting - utm_loc.easting) ** 2 + (sample_utm_loc.northing - utm_loc.northing) ** 2,
        );
        if (distance < REORDER_TOLERANCE_METERS && (shortest_distance < 0 || distance < shortest_distance)) {
          shortest_distance = distance;
          sample_index = i;
        }
      }

      // add the closest point to the new samples
      if (sample_index >= 0) {
        re_ordered_samples.push(samples[sample_index]);
        if (sample_index === 0) {
          samples.shift();
        } else if (sample_index === samples.length - 1) {
          samples.pop();
        } else {
          samples.splice(sample_index, 1);
        }
      }
    }

    // add any leftover samples
    for (const sample of samples) {
      error_messages.push(`WARNING: missed sample in order: ${sample.sample_id}`);
    }
    re_ordered_samples = re_ordered_samples.concat(samples);

    // set the sample order
    for (const [i, sample] of re_ordered_samples.entries()) {
      sample.order = i + 1;
    }

    if (renumber) {
      await this.renumber_samples(sampling);
    }
  }

  // zone sampling
  else {
    let cores = cleanSet(
      flatten(flatten(this.getSampleSites().map((site) => site.getSamples())).map((sample) => sample.getSoilCores())),
    );
    if (!this.getJob()?.zone_interleave) {
      // TODO this will get weird if there are multiple samples at the same site
      let cores = cleanSet(
        flatten(flatten(this.getSampleSites().map((site) => site.getSamples())).map((sample) => sample.getSoilCores())),
      );
      let coresMap = new Map(cores.map((i) => [i, false]));
      let zone_counts: { [sample_id: string]: number } = {};
      for (const [core] of coresMap) {
        if (!core.sample_id) {
          throw new Error('Core sample_id not found');
        }
        if (zone_counts.hasOwnProperty(core.sample_id)) {
          zone_counts[core.sample_id] += 1;
        } else {
          zone_counts[core.sample_id] = 1;
        }
      }
      let active_sample_id: string | undefined = undefined;
      for (const [lat, lon] of coords) {
        const utm_loc = fromLatLon(lat, lon);
        let shortest_distance = -1;
        let closest_core: SoilCore | null = null;
        let point_in_new_zone = false;
        if (active_sample_id) {
          for (const szone of cleanSet(flatten(this.getSampleSites().map((site) => site.getSampleZone())))) {
            if (szone?.sample_id === active_sample_id) {
              const zone = szone.getZone();
              if (!zone?.contains(lat, lon)) {
                point_in_new_zone = true;
              }
              break;
            }
          }
        }
        for (const [core, found] of coresMap) {
          const core_utm_loc = fromLatLon(core.lat, core.lon);
          const distance = Math.sqrt(
            (core_utm_loc.easting - utm_loc.easting) ** 2 + (core_utm_loc.northing - utm_loc.northing) ** 2,
          );
          // If not in middle of a zone, find closest core within 20 meters
          // Do not check if already found here to protect against extra clicks at last core in zone
          // Found check included when adding closest point to new cores
          if (!active_sample_id) {
            if (distance < 20 && (shortest_distance < 0 || distance < shortest_distance)) {
              shortest_distance = distance;
              closest_core = core;
            }
            // reorder path has moved to a new zone, reset so error messages only display
            // cores that were missed
          } else if (point_in_new_zone) {
            if (distance < 20 && (shortest_distance < 0 || distance < shortest_distance)) {
              active_sample_id = core.sample_id;
              shortest_distance = distance;
              closest_core = core;
            }
            // if in middle of a zone, find closest core that is within 20 meters, in the zone,
            // and has not been found yet, helps prevent missing cores near each other
          } else {
            if (
              distance < 20 &&
              (shortest_distance < 0 || distance < shortest_distance) &&
              active_sample_id === core.sample_id &&
              !found
            ) {
              shortest_distance = distance;
              closest_core = core;
            }
          }
        }
        // add the closest point to the new cores
        if (closest_core && !coresMap.get(closest_core)) {
          re_ordered_cores.push(closest_core);
          coresMap.set(closest_core, true);
          if (!active_sample_id) {
            active_sample_id = closest_core.sample_id;
          }
          if (active_sample_id) {
            zone_counts[active_sample_id] -= 1;
            if (zone_counts[active_sample_id] === 0) {
              active_sample_id = undefined;
            }
          }
        }
      }

      // add any leftover cores
      for (const [core, found] of coresMap) {
        if (!found) {
          error_messages.push(`WARNING: missed core in order: ${core.sample_id}.${core.core_number}`);
          re_ordered_cores.push(core);
        }
      }
      // if any zones are interleaved, do not reorder
      const sample_ids: string[] = [];
      let num_transitions = 0;
      let current_sample_id: string | undefined = undefined;
      for (const core of re_ordered_cores) {
        if (core.sample_id !== current_sample_id) {
          if (core.sample_id && sample_ids.includes(core.sample_id)) {
            error_messages.push(
              `ERROR: already did sample ${core.sample_id} (coming from sample ${current_sample_id})`,
            );
          }
          current_sample_id = core.sample_id;
          num_transitions += 1;
        }
        if (core.sample_id && !sample_ids.includes(core.sample_id)) {
          sample_ids.push(core.sample_id);
        }
      }

      if (sample_ids.length === num_transitions) {
        // set the sample and core order
        current_sample_id = undefined;
        let order = 0;
        let core_num = 1;
        for (const core of re_ordered_cores) {
          if (core.sample_id !== current_sample_id) {
            current_sample_id = core.sample_id;
            order += 1;
            const sample = core.getSample();
            if (sample) {
              sample.order = order;
            }
            core_num = 1;
          }
          core.core_number = core_num;
          core_num += 1;
        }
      } else {
        error_messages.push('ERROR: cannot reorder mission - order creates interleaved samples');
      }
    } else {
      if (re_ordered_cores.length < cores.length) {
        const missedCores = cores.filter((core) => !re_ordered_cores.includes(core));
        for (const core of missedCores) {
          // TODO insert the cores in a place that makes more sense given the existing order or re_ordered_cores
          error_messages.push(`WARNING: missed core in order: ${core.sample_id}.${core.core_number}`);
          re_ordered_cores.push(core);
        }
      }
    }
  }

  await this.calculate_path_restore_cores(
    re_ordered_samples.length ? re_ordered_samples : re_ordered_cores,
    null,
    true, // For reordering - when core numbers change, we need to match by position instead of core numbers
  );

  return error_messages;
}

export async function calculate_path_zone_interleave(
  mission: Mission,
  spec: SamplingSpec,
  re_ordered_cores: SoilCore[],
) {
  // delete all "close" cores"
  const samples = flatten(mission.getSampleSites().map((site) => site && site.getSamples())).filter(
    (sample) => !sample.skipped_or_deleted,
  );

  if (re_ordered_cores.length === 0) {
    // get all cores for each sample
    re_ordered_cores = orderBy(flatten(samples.map((sample) => sample.getSoilCores())), ['waypoint_index'], ['asc']);
  }

  const ordered_samples = orderBy(samples, ['order'], ['asc']);

  const soilCoresForDeletion = await getAllCloseCores(ordered_samples);
  // remove the deleted soil cores from the order list
  const remainingCores = re_ordered_cores.filter((core) => !soilCoresForDeletion.includes(core));
  for (const core of soilCoresForDeletion) {
    core.dispose();
  }

  // dispose all existing waypoints
  const waypoints = cleanSet(mission.getWaypoints());
  if (waypoints.length > 0) {
    for (const wp of waypoints) {
      wp.dispose();
    }
  }

  // genereate a map of all zones and the core counts per zone
  // this will be our remainder logic as we add cores
  const coreCountBySample: {
    [sampleId: string]: [
      numberOfSampleCores: number,
      quotient: number,
      remainder: number,
      coreIndex: number,
      coreStopIndex: number,
    ];
  } = {};
  for (const sample of ordered_samples) {
    const sampleCores = sample.getSoilCores();
    const numberOfSampleCores = sampleCores.length;
    // Quotient and remainder used for calculating extra cores that need added
    const quotient = Math.max(0, Math.floor((spec.cores - numberOfSampleCores) / numberOfSampleCores));
    let remainder = Math.max(0, (spec.cores - numberOfSampleCores) % numberOfSampleCores);
    coreCountBySample[sample.sample_id] = [numberOfSampleCores, quotient, remainder, 1, 0];
  }

  const extraCoresNeeded = (sampleId: string, coreStopIndex: number) => {
    return coreCountBySample[sampleId][1] + (coreCountBySample[sampleId][2] >= coreStopIndex ? 1 : 0);
  };

  let waypoint_index = 1;
  // iterate through all re_ordered_cores as "core stops" since we deleted all close cores already
  for (const [coreStopIndex, coreStop] of remainingCores.entries()) {
    const sample = coreStop.getSample();
    if (!sample) {
      throw new Error('Core sample not found');
    }

    // increment the core number for this sample and re-assign it to this sample
    coreStop.core_number = coreCountBySample[sample.sample_id][3]++;

    await add_stop_core_waypoint(coreStop, mission.instance_id, waypoint_index++, coreStop.core_number);

    // increment the core stop count for the purpose of making extra cores
    coreCountBySample[sample.sample_id][4]++;

    // this sample needs more added?
    const extraCoresAtThisStop = extraCoresNeeded(sample.sample_id, coreCountBySample[sample.sample_id][4]);
    // TODO could this function replace the normal function? Who knows...
    const nextCoreStop =
      coreStopIndex === remainingCores.length - 1
        ? remainingCores[coreStopIndex - 1]
        : remainingCores[coreStopIndex + 1];

    const line_string = new LineString([
      [coreStop.lat, coreStop.lon],
      [nextCoreStop.lat, nextCoreStop.lon],
    ]);
    const line_distance = calc_line_distance(coreStop, nextCoreStop);
    for (let extraCoreIndex = 0; extraCoreIndex < extraCoresAtThisStop; extraCoreIndex++) {
      //const lat_lon = [coreStop.lat, coreStop.lon];
      const lat_lon = line_string.getCoordinateAt(
        ((extraCoreIndex + 1) * ft_to_m(CORE_STOP_MAX_DISTANCE_FT)) / line_distance,
      );

      await create_new_core(
        lat_lon,
        mission.instance_id,
        sample.instance_id,
        waypoint_index,
        coreCountBySample[sample.sample_id][3]++,
        // TODO if sampling, field ops
        'Rogo Map Maker',
      );
      waypoint_index++;
    }
  }
}

export async function calculate_path_zone(mission: Mission, spec: SamplingSpec, re_ordered_cores: SoilCore[]) {
  // Remove any cores within 10 feet of each other
  const samples = flatten(mission.getSampleSites().map((site) => site && site.getSamples())).filter(
    (sample) => !sample.skipped_or_deleted,
  );
  const ordered_samples = orderBy(samples, ['order'], ['asc']);
  const soilCoresForDeletion = await getAllCloseCores(ordered_samples);
  // remove the deleted soil cores from the order list
  //const remainingCores = re_ordered_cores.filter(core => !soilCoresForDeletion.includes(core));
  for (const core of soilCoresForDeletion) {
    core.dispose();
  }

  // Clean out waypoints as they will be rebuilt
  await deleteAllWaypoints(mission);

  // TODO alternate reorder implementation
  // let waypoint_index = 1;
  // let core_index = 1;
  // for (const [i, core] of re_ordered_cores.entries()) {
  //   await add_stop_core_waypoint(core, mission.instance_id, waypoint_index++, core_index++);
  // }

  let waypoint_index = 1;
  for (const sample of ordered_samples) {
    const ordered_cores = orderBy(sample.getSoilCores(), ['core_number'], ['asc']);
    const sample_num_cores = ordered_cores.length;
    // Quotient and remainder used for calculating extra cores that need added
    const quotient = Math.max(0, Math.floor((spec.cores - sample_num_cores) / sample_num_cores));
    let remainder = Math.max(0, (spec.cores - sample_num_cores) % sample_num_cores);
    let core_index = 1;
    for (const [i, core] of ordered_cores.entries()) {
      // For all cores except the last add the original core point and then
      // all others on path to next core point
      if (i < sample_num_cores - 1) {
        // Add new core waypoint
        await add_stop_core_waypoint(core, mission.instance_id, waypoint_index, core_index);
        waypoint_index++;
        core_index++;
        // Add extra cores
        const end_core = ordered_cores[i + 1];
        const line_string = new LineString([
          [core.lat, core.lon],
          [end_core.lat, end_core.lon],
        ]);
        const line_distance = calc_line_distance(core, end_core);
        const extra_core = remainder > 0 ? 1 : 0;
        for (let j = 1; j < quotient + extra_core + 1; j++) {
          const lat_lon = line_string.getCoordinateAt((j * ft_to_m(CORE_STOP_MAX_DISTANCE_FT)) / line_distance);
          await create_new_core(
            lat_lon,
            mission.instance_id,
            sample.instance_id,
            waypoint_index,
            core_index,
            // TODO if sampling, field ops
            'Rogo Map Maker',
          );
          waypoint_index++;
          core_index++;
        }
        remainder--;
      } else {
        // Last stop core points
        const prev_core = ordered_cores[i - 1];
        if (prev_core) {
          const line_string = new LineString([
            [core.lat, core.lon],
            [prev_core.lat, prev_core.lon],
          ]);
          const line_distance = calc_line_distance(prev_core, core);
          const extra_core = remainder > 0 ? 1 : 0;
          for (let j = quotient + extra_core; j > 0; j--) {
            const lat_lon = line_string.getCoordinateAt((j * ft_to_m(CORE_STOP_MAX_DISTANCE_FT)) / line_distance);
            await create_new_core(
              lat_lon,
              mission.instance_id,
              sample.instance_id,
              waypoint_index,
              core_index,
              'Rogo Map Maker',
            );
            waypoint_index++;
            core_index++;
          }
        }

        // Always a a core stop waypoint
        await add_stop_core_waypoint(core, mission.instance_id, waypoint_index, core_index);
        waypoint_index++;
      }
    }
  }
}

function deleteAllWaypoints(mission: Mission) {
  const waypoints = cleanSet(mission.getWaypoints());
  if (waypoints.length > 0) {
    for (const wp of waypoints) {
      wp.dispose();
    }
  }
}

async function getAllCloseCores(ordered_samples: Sample[], tolerance = ft_to_m(Y_LIMIT_FT)) {
  const cores_for_deletion: SoilCore[] = [];
  for (const sample of ordered_samples) {
    const ordered_cores = orderBy(sample.getSoilCores(), ['core_number'], ['asc']);
    let i = 0;
    let iter = 0;
    while (i < ordered_cores.length - 1) {
      let distance = calc_line_distance(ordered_cores[iter], ordered_cores[iter + 1]);
      while (distance <= tolerance) {
        if (iter + 1 < ordered_cores.length - 1) {
          cores_for_deletion.push(ordered_cores[iter + 1]);
        } else {
          // At last stop, remove first core in stop as cores added in reverse here
          cores_for_deletion.push(ordered_cores[i]);
          break;
        }
        iter++;
        distance = calc_line_distance(ordered_cores[iter], ordered_cores[iter + 1]);
      }
      iter++;
      i = iter;
    }
  }
  return cores_for_deletion;
}

async function calculate_path_grid(mission: Mission, spec: SamplingSpec, initialPrevPoint: Coordinate | null = null) {
  const pathGridGenerator = new PathGridGenerator(mission, spec, initialPrevPoint);
  pathGridGenerator.generate();
}

export async function reverse_path(this: Mission) {
  // First reverse all the waypoints
  const waypoints = orderBy(this.getWaypoints(), ['waypoint_number'], ['desc']);
  for (const [waypointIndex, waypoint] of waypoints.entries()) {
    waypoint.waypoint_number = waypointIndex + 1;
    const corePoint = waypoint.getCorePoint();
    const soilCore = corePoint?.getSoilCore();
    if (soilCore) {
      soilCore.waypoint_index = waypoint.waypoint_number;
    }
  }

  for (const [i, sample] of orderBy(this.getSamples(), ['order'], ['desc']).entries()) {
    sample.order = i;
  }

  // // recalculate checksum
  await this.to_ros_msg();
  dispatchMissionUpdated();
}

export function calculate_path_distance(mission: Mission) {
  const waypoints = mission.getWaypoints();
  let total_distance = 0;
  for (let i = 0; i < waypoints.length - 1; i++) {
    const waypoint1 = waypoints[i];
    const waypoint2 = waypoints[i + 1];
    total_distance += calc_line_distance(waypoint1, waypoint2);
  }

  return total_distance;
}

// This is a hack function until cores are a first class citizen
// The problem right now is that when calculating a grid path, all
// cores are deleted and removed and then recreated
// while this has positives in terms of not dealing with stale cores,
// we lose data that we store on the core, which we now do more regularly
export async function calculate_path_restore_cores(
  this: Mission,
  reordered_points_of_interest: Sample[] | SoilCore[] = [],
  initial_prev_point: Coordinate | null = null,
  matchCoresByPosition: boolean = false,
) {
  let backupPulledCores: Record<string, string> = {};

  // save cores temporarily

  const samples = this.getSamples();
  for (const sample of samples) {
    backupPulledCores[sample.sample_id] = JSON.stringify(sample.getPulledSoilCores());
  }

  let reorderItems: Sample[] | SoilCore[] = reordered_points_of_interest;
  if (reorderItems.length === 0) {
    reorderItems = this.getWaypoints()
      .map((waypoint) => waypoint.getCorePoint()?.getSoilCore())
      .filter((core) => !!core);
  }

  const waypoints = cleanSet(this.getWaypoints());
  if (waypoints.length > 0) {
    for (const wp of waypoints) {
      wp.dispose();
    }
  }

  await this.calculate_path(reorderItems);

  for (const sample of samples) {
    // so these aren't *really* SoilCoreClass objects in that they aren't instances of the class
    // but they are objects that have the same properties as the class
    const sampleBackupCores: SoilCoreClass[] = JSON.parse(backupPulledCores[sample.sample_id]);
    for (const soilCore of sample.getSoilCores()) {
      let backupCore: SoilCoreClass | undefined;
      if (matchCoresByPosition) {
        backupCore = sampleBackupCores.find(
          (backupCore) => backupCore.lat === soilCore.lat && backupCore.lon === soilCore.lon,
        );
      } else {
        backupCore = sampleBackupCores.find((backupCore) => backupCore.core_number === soilCore.core_number);
      }

      if (backupCore) {
        soilCore.restorePulledInfo(backupCore);
      }
    }
  }
}

export async function calculate_path(
  this: Mission,
  reordered_points_of_interest: Sample[] | SoilCore[] = [],
  initial_prev_point: Coordinate | null = null,
) {
  const spec = this.getPrimarySpec();
  if (!spec) {
    alertError('Cannot calculate path without a sampling spec');

    return;
  }

  const interleave = this.getJob()?.zone_interleave;
  if (spec.pattern_type === GridPatterns.ZONE) {
    // if interleaving, do something totally different?
    if (interleave) {
      await calculate_path_zone_interleave(this, spec, reordered_points_of_interest as SoilCore[]);
    } else {
      await calculate_path_zone(this, spec, reordered_points_of_interest as SoilCore[]);
    }
  } else {
    await calculate_path_grid(this, spec, initial_prev_point);
  }

  this.path_distance = calculate_path_distance(this);

  // show distance in miles
  alertInfo(`Path is ${Math.round(this.path_distance / 100 / KM_PER_MILE) / 10} miles long`);

  // call 'to_ros_msg' to re-calculate the nav checksum
  this.to_ros_msg();

  dispatchMissionUpdated();
}

export async function get_airtable_job_record(this: Mission): Promise<AirtableRecord<Jobs> | undefined> {
  if (!this.job_id) {
    return;
  }
  let record = await Airtable.getRecord<Jobs>('Jobs', this.job_id);

  if (!record) {
    await Airtable.search<Jobs>('Jobs', 'App View - Run Missions', `{Rogo Job ID} = '${this.job_id}'`, true);
    record = await Airtable.getRecord('Jobs', this.job_id);
  }

  return record;
}

// WARNING: This should be made consistent with exit-interview-lambda:file_gen_ops.js:toCSV(...)
// if updating this, please update that file as well
// TODO would be better if this was functionality we could capture
// in the kml-error-checker for better consistency
export function to_csv(this: Mission) {
  const rows: string[][] = [];
  rows.push([
    'sample barcode',
    'sample id name',
    'tot number of samples',
    'test package',
    'sample event id',
    'gps lat',
    'gps lon',
    'date sampled',
    'client',
    'grower name',
    'farm name',
    'field name',
    'starting sample depth (in)',
    'ending sample depth (in)',
    'billing account number',
    'sampling company name',
    'sampling company ID',
    'Rogo job id',
    'email to send results to',
    'field ID',
    'submitter notified ID',
    'Lab Job ID',
    'box ID',
    'core length (cm)',
    'core inner diameter (in)',
    'external reference id',
  ]);
  const job = this.getJob();
  if (!job) {
    alertError('Mission has no job');
    return;
  }
  const spec = cleanSet(this.getSamplingSpecs())[0];
  const samples = cleanSet(
    orderBy(
      flatten(this.getSampleSites().map((site) => site.getSamples())).filter((sel) => !sel.skipped_or_deleted),
      ['order'],
      ['asc'],
    ),
  );
  for (const [i, sample] of samples.entries()) {
    const row: string[] = [];
    if (!sample) {
      continue;
    }
    row.push(sample.bag_id!);
    if (job.use_original_sample_id) {
      row.push(sample.getSampleSite().original_sample_id);
    } else {
      row.push(sample.sample_id);
    }
    row.push(samples.length.toString());
    const sample_tests: string[] = [];
    for (const t of job.test_package.split('+')) {
      if (!t.endsWith('*')) {
        sample_tests.push(t);
      } else if (i % job.add_on_freq === 0) {
        sample_tests.push(t.slice(0, -1));
      }
    }
    row.push(sample_tests.join('+'));
    row.push(job.event_id);
    const sample_centroid = sample.getSampleSite().getSampleCentroid();
    if (sample_centroid) {
      row.push(sample_centroid.lat.toString());
      row.push(sample_centroid.lon.toString());
    } else {
      const core = sample.getSoilCores()[0];
      if (core) {
        row.push(core.lat.toString());
        row.push(core.lon.toString());
      } else {
        row.push('');
        row.push('');
      }
    }
    row.push(sample.pulled_at > 0.0 ? new Date(sample.pulled_at * 1000).toISOString() : '');
    row.push(job.client);
    row.push(job.grower);
    row.push(job.farm);
    row.push(job.field);
    row.push(spec.start_depth.toString());
    row.push(spec.end_depth.toString());
    row.push(job.billing_account);
    row.push(job.sampling_company_name);
    row.push(job.sampling_company_id);
    row.push(job.job_id!);
    row.push(job.response_email);
    // TODO SVH 2023-10-11: Set to blank because some labs can't ingress
    // the field ID and it is not needed right now
    row.push(''); //row.push(props.field_id);
    row.push(job.submitter_notified_id);
    row.push(job.lab_submittal_id);
    row.push(sample.getSampleBox()?.uid || '');

    const firstCore = sample.getSoilCores().length ? sample.getSoilCores()[0] : null;
    if (job.manual_core_length_measurement || job.manual_core_loss_measurement || job.manual_hole_depth_measurement) {
      row.push(firstCore?.core_length_cm.toString() || '');
    } else {
      row.push('');
    }

    row.push(firstCore?.core_diameter_inches.toString() || '');

    row.push(sample.getSampleSite().external_reference_id || '');
    rows.push(row);
  }
  return rows.map((row) => row.join(',')).join('\n');
}

export async function to_shapefile(this: Mission, pts: boolean, bnds: boolean) {
  // generate geoJSON
  const [geoJSON, options] = await this._to_shapefile(pts, bnds);

  try {
    // return zip contents
    const result: Blob = await shpZip<'blob'>(geoJSON as FeatureCollection<any, any>, options);
    return result;
  } catch (e) {
    console.error('to_shapefile error: ', e);
  }
}

export function _to_shapefile(this: Mission, pts: boolean, bnds: boolean) {
  const job = this.getJob();
  if (!job) {
    throw new Error('Mission has no job');
  }
  const spec = this.getPrimarySpec();
  if (!spec) {
    throw new Error('Mission has no spec');
  }
  const samples = cleanSet(
    orderBy(flatten(this.getSampleSites().map((site) => site.getSamples())), ['order'], ['asc']),
  );

  const geoJSON: FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };

  if (pts) {
    // sample sites
    for (const [i, sample] of samples.entries()) {
      const sample_centroid = sample.getSampleSite().getSampleCentroid();
      const sample_tests: string[] = [];
      const feature: turf.Feature<Point> = {
        type: 'Feature',
        properties: {},
        id: sample.sample_id,
        geometry: { type: 'Point', coordinates: [] },
      };

      for (const t of job.test_package.split('+')) {
        if (!t.endsWith('*')) {
          sample_tests.push(t);
        } else if (i % job.add_on_freq === 0) {
          sample_tests.push(t.slice(0, -1));
        }
      }

      if (sample_centroid) {
        feature.geometry.coordinates = [sample_centroid.lon, sample_centroid.lat];
      } else {
        feature.geometry.coordinates = [];
      }

      feature.properties = {
        'spl brcd': sample.bag_id,
        'spl id': sample.sample_id,
        'no spl': samples.length.toString(),
        'test pkg': sample_tests.join('+'),
        'spl ev id': job.event_id,
        date: this.sample_date > 0.0 ? new Date(this.sample_date * 1000).toISOString() : '',
        client: job.client,
        grower: job.grower,
        farm: job.farm,
        field: job.field,
        'st dpth': spec.start_depth.toString(),
        'end dpth': spec.end_depth.toString(),
        'b acct no': job.billing_account,
        'co name': job.sampling_company_name,
        'co ID': job.sampling_company_id,
        'Rogo id': job.job_id,
        email: job.response_email,
        'fld ID': job.field_id,
        'o spl id': sample.getSampleSite()?.original_sample_id || '',
        'spl ref': sample.getSampleSite()?.external_reference_id || '',
      };

      geoJSON.features.push(feature);
    }
  }

  if (bnds) {
    // zones
    const collected_zones = cleanSet(
      orderBy(
        this.getZones().filter((sel) => sel.zone_type === ZoneType.COLLECTED_FIELD),
        ['zone_name'],
        ['asc'],
      ),
    );
    const field_zones = cleanSet(
      orderBy(
        this.getZones().filter((sel) => sel.zone_type === ZoneType.FIELD),
        ['zone_name'],
        ['asc'],
      ),
    );
    let zones: Zone[];
    // default to collected zones otherwise fall back to field zones
    if (collected_zones.length > 0) {
      zones = collected_zones;
    } else {
      zones = field_zones;
    }

    for (const zone of zones) {
      // outer polys
      const outer_boundaries = cleanSet(orderBy(zone.getOuterZoneBoundarys(), ['poly_id'], ['asc']));

      for (const outer_boundary of outer_boundaries) {
        const feature: turf.Feature<Polygon> = {
          type: 'Feature',
          properties: {
            field: job.field,
            farm: job.farm,
            grower: job.grower,
            id: 'ob' + outer_boundary.poly_id,
          },
          geometry: { type: 'Polygon', coordinates: [] },
        };

        const outer_coordinates: Coordinate[] = [];

        for (const [, outerCoords] of outer_boundary.coordinates.entries()) {
          outer_coordinates.push([outerCoords[1], outerCoords[0]]);
        }

        feature.geometry.coordinates.push(outer_coordinates);

        const inner_boundaries = cleanSet(orderBy(zone.getInnerZoneBoundarys(), ['boundary_id'], ['asc']));

        // inner polys
        for (const inner_boundary of inner_boundaries) {
          const inner_coordinates: Coordinate[] = [];

          for (const [, innerCoords] of inner_boundary.coordinates.entries()) {
            inner_coordinates.push([innerCoords[1], innerCoords[0]]);
          }

          feature.geometry.coordinates.push(inner_coordinates);
        }

        geoJSON.features.push(feature);
      }
    }
  }

  // name zipped folder and files
  const options: DownloadOptions & ZipOptions = {
    folder: this.name,
    types: {
      point: `${this.name}_pts`,
      polygon: `${this.name}_bnd`,
    },
    compression: 'DEFLATE',
    outputType: 'blob',
  };

  return [geoJSON, options] as const;
}

export function to_pdf(this: Mission, logo: string | ArrayBuffer, pdfSampleOrder: string, config: PRINTER_CONFIG) {
  // const mission = [this];
  return {
    info: { title: `${this.job_id}.pdf` },
    // @ts-ignore
    content: [genPdfCheckinOld([this], logo, pdfSampleOrder, null, this.getAllSamples(), config)],
    pageSize: config.pageSize,
    defaultStyle: config.defaultStyle,
    pageMargins: config.lab.margin,
  } as TDocumentDefinitions;
}

export async function create_zone_cores(this: Mission, coords: Coordinate[]) {
  const primarySpec = this.getPrimarySpec();
  if (!primarySpec) {
    throw new Error('Creating zone cores - primary sampling spec not found');
  }

  let order = 1;
  for (const [lat, lon] of coords) {
    // get sample zone that this point is in
    let sample_site: SampleSite | undefined = undefined;
    const sampleSites = this.getSampleSites();
    const sampleZones = sampleSites.map((site) => site.getSampleZone());
    const zones = cleanSet(sampleZones.map((szone) => szone?.getZone()));
    for (const zone of zones) {
      if (!zone) {
        continue;
      }

      if (!zone.hasValidBoundaries()) {
        const errorMessage = `Zone ${zone.zone_name} has invalid boundaries`;
        alertError(errorMessage);

        throw new Error(errorMessage);
      }

      if (zone.contains(lat, lon)) {
        sample_site = zone.getSampleZone()?.getSampleSite();
        break;
      }
    }

    if (!sample_site) {
      continue;
    }

    let sample = cleanSet(sample_site.getSamples())[0];
    if (!sample) {
      sample = Sample.createSample(
        this,
        sample_site,
        primarySpec,
        sample_site.original_sample_id,
        SamplingTypes.REGULAR,
      );
    }
    sample.order = order++; // make the sample order the same as the cores path

    const core = SoilCore.create();
    core.Sample_id = sample.instance_id;
    core.core_number = Math.max(...cleanSet(sample.getSoilCores()).map((sel) => sel.core_number)) + 1;
    core.lat = lat;
    core.lon = lon;
    core.source = 'Rogo Map Maker';

    // update the number of cores
    const spec = sample.getSamplingSpec();
    if (spec) {
      spec.cores = cleanSet(sample.getSoilCores()).length;
    }
  }
}

function isPolygonOrMultiPolygon(geometry): geometry is Polygon | MultiPolygon {
  return geometry.type === 'Polygon' || geometry.type === 'MultiPolygon';
}

function isPolygon(geometry): geometry is Polygon {
  return geometry.type === 'Polygon';
}

function isPoint(geometry): geometry is Point {
  return geometry.type === 'Point';
}

export async function generate_from_shapes(
  name: string,
  shpfiles: RogoShapefile[],
  jobId: string,
  confirmColumn: (item: ConfirmColumn) => Promise<string>,
  mission?: Mission,
): Promise<[Mission, string[]]> {
  const error_messages: string[] = [];

  if (!mission) {
    mission = Mission.create();
  }

  let job = mission.getJob();
  if (!job) {
    job = Job.create();
  }

  mission.name = name;
  mission.job_id = jobId;
  job.Mission_id = mission.instance_id;

  try {
    // load the shapefiles in the kml error
    const shapeData = shpfiles.map((shpfile) => {
      return { filename: shpfile.name, data: shpfile.data };
    });

    const jobLoader = new JobLoader(shapeData);
    const job = await jobLoader.parse();
    const jobChecker = new JobChecker(job);
    const errors = jobChecker.validateSubmittable();

    errors.forEach((err) => {
      if (err && typeof err === 'object' && 'rogo' in err && 'message' in err) {
        alertWarn(err.message);
      } else {
        alertWarn(JSON.stringify(err));
      }
    });
  } catch (err) {
    if (!!err && typeof err === 'object' && 'rogo' in err && 'message' in err) {
      alertWarn(`Found mission problem: ${err.message}`);
    } else {
      alertWarn(`Unknown error hit during validation: ${JSON.stringify(err)}`);
    }
  }

  const _shpfiles = await Promise.all(
    shpfiles.map(async (shpfile) => {
      try {
        return { ...shpfile, data: await shp(shpfile.data) };
      } catch (err) {
        alertWarn(`Error parsing shapefile ${shpfile.name}: ${err}`);
      }
    }),
  );

  const _cleanShapefiles = _shpfiles.filter((file) => !!file);
  // analyze each feature set
  for (const shpfile of _cleanShapefiles) {
    const shpDataArray = Array.isArray(shpfile.data) ? shpfile.data : [shpfile.data];
    if (shpfile.mode === 'bnd') {
      processBoundaryFile(shpDataArray, mission, error_messages);
    } else if (shpfile.mode === 'pts') {
      await processPointsOrZonesFile(shpDataArray, confirmColumn, shpfile, error_messages, mission);
    } else {
      console.error(`Unknown mode: ${shpfile.mode}`);
      error_messages.push(`Unknown mode: ${shpfile.mode}`);
    }
  }

  // set pullin to a point on the boundary
  const field = mission.getZones().find((sel) => sel.zone_type === ZoneType.FIELD);
  const field_bnd = field ? field.getOuterZoneBoundarys()[0] : undefined;
  if (field_bnd && field_bnd.coordinates.length > 0) {
    mission.pullin_lat = field_bnd.coordinates[0][0];
    mission.pullin_lon = field_bnd.coordinates[0][1];
  }

  // initialize the drive order
  let missionSamples = cleanSet(flatten(mission.getSampleSites().map((sel) => sel.getSamples())));
  // get < -1,0,1 > result with substraction
  missionSamples.sort((a, b) => a.sample_id!.localeCompare(b.sample_id!, 'en', { numeric: true }));
  for (const [i, sample] of missionSamples.entries()) {
    sample.order = i + 1;
  }

  return [mission, error_messages];
}

async function processPointsOrZonesFile(
  shpDataArray: shp.FeatureCollectionWithFilename[],
  confirmColumn: (item: ConfirmColumn) => Promise<string>,
  shpfile: {
    data: shp.FeatureCollectionWithFilename | shp.FeatureCollectionWithFilename[];
    mode: RogoShapefileType;
    source: string;
    name: string;
  },
  error_messages: string[],
  mission: Mission,
) {
  const originalPointsOrZonesFileProcessor = new OriginalPointsOrZonesFileProcessor(
    shpDataArray,
    confirmColumn,
    shpfile,
    error_messages,
    mission,
  );

  await originalPointsOrZonesFileProcessor.process();
}

function processBoundaryCoordinates(
  mission: Mission,
  geomCoordinates: Position[][][],
  field: Zone,
  error_messages: string[],
) {
  let poly_id: number | undefined = undefined;
  for (const poly_coordinates of geomCoordinates) {
    poly_id = undefined;
    for (const [i, coordinates] of Object.entries(poly_coordinates)) {
      const validBoundary = coordinates.length > 3;
      if (!validBoundary) {
        error_messages.push('Boundary must have at least 4 coordinates');

        continue;
      }

      const new_boundary = Boundary.create();
      new_boundary.boundary_id = mission.assign_id();
      new_boundary.coordinates = coordinates.map((coord) => [coord[1], coord[0]]);
      poly_id = mission.assign_id();
      if (parseInt(i) === 0) {
        new_boundary.poly_id = poly_id;
        new_boundary.OuterZone_id = field.instance_id;
      } else {
        new_boundary.poly_id = poly_id;
        new_boundary.InnerZone_id = field.instance_id;
      }
      new_boundary.simplify(0.5); // simplify boundary with a tolerance of 0.5m
    }
  }
}

function validateBoundaryGeometry(feature: turf.Feature<turf.Geometry, turf.Properties>, error_messages: string[]) {
  if (!feature.geometry) {
    console.error('Feature has no geometry', feature);
    error_messages.push('Feature has no geometry');

    return false;
  }

  if (!isPolygonOrMultiPolygon(feature.geometry)) {
    console.error('Feature has invalid geometry type for boundaries', feature);
    error_messages.push(`Feature has invalid geometry type for boundaries: ${feature.geometry.type}`);

    return false;
  }

  return true;
}

function processBoundaryFile(
  shpDataArray: shp.FeatureCollectionWithFilename[],
  mission: Mission,
  error_messages: string[],
) {
  for (const shpdata of shpDataArray) {
    if (shpdata.type !== 'FeatureCollection') {
      console.error('Shapefile does not contain a feature collection', shpdata);
      error_messages.push(`Shapefile does not contain a feature collection: ${shpdata.fileName}.shp`);

      continue;
    }

    for (const feature of shpdata.features) {
      // @ts-ignore turf vs ol
      if (!validateBoundaryGeometry(feature, error_messages)) {
        continue;
      }

      let field = mission.getZones().find((sel) => sel.zone_type === ZoneType.FIELD);
      if (!field) {
        field = Zone.create();
        field.Mission_id = mission.instance_id;
        field.zone_name = 'field';
        field.zone_type = ZoneType.FIELD;
      }

      let geom_coordinates: Position[][][];
      if (isPolygon(feature.geometry)) {
        geom_coordinates = [feature.geometry.coordinates];
      } else {
        // @ts-ignore unsure why this wouldn't be a valid geom
        geom_coordinates = feature.geometry.coordinates;
      }

      processBoundaryCoordinates(mission, geom_coordinates, field, error_messages);
    }
  }
}

// Pullin | Zones | Path | (Samples | Soil Cores)...
export function to_feature(
  this: Mission,
  prevFeatures: {
    waypoints?: {
      waypointData: string;
      waypointFeatures: KMLFeature;
    };
    settings?: {
      showPulledCoreLocation?: boolean;
    };
  },
) {
  let features: KMLFeature[] = [];

  if (this.pullin_lat !== 0.0 || this.pullin_lon !== 0.0) {
    const pullin: KMLFeature = {
      type: 'Feature',
      geometry: {
        type: GeometryType.POINT,
        coordinates: [],
      },
      properties: {
        name: '',
        visibility: true,
      },
    };
    pullin.properties.name = 'pullin';
    pullin.properties.visibility = true;
    pullin.geometry = {
      type: GeometryType.POINT,
      coordinates: convertProjection4329([this.pullin_lon, this.pullin_lat]),
    };

    features.push(pullin);
  }

  ///////////////////////////////////////////////////////////////////////////
  // get all zones (includes sample, unsafe, slow, pullin, field)
  ///////////////////////////////////////////////////////////////////////////
  const zones = this.getZones();
  for (const zone of zones) {
    features = features.concat(zone.to_feature());
  }

  ///////////////////////////////////////////////////////////////////////////
  // get waypoint/path
  ///////////////////////////////////////////////////////////////////////////
  const wps = this.getWaypoints();
  const wpsJson = JSON.stringify(wps);
  const waypointDataChanged = wpsJson !== prevFeatures?.waypoints?.waypointData;
  if (!prevFeatures.waypoints || waypointDataChanged) {
    if (wps.length > 0) {
      const path: KMLFeature = {
        type: 'Feature',
        geometry: {},
        properties: {
          name: 'path',
          visibility: true,
        },
      };
      path.geometry = {
        type: GeometryType.LINE_STRING,
        coordinates: orderBy(wps, ['waypoint_number'], ['asc']).map((wp) => convertProjection4329([wp.lon, wp.lat])),
      };
      features.push(path);
      prevFeatures.waypoints = { waypointData: JSON.stringify(wps), waypointFeatures: path };
    }
  } else {
    features.push(prevFeatures.waypoints.waypointFeatures);
  }

  ///////////////////////////////////////////////////////////////////////////
  // get all sample features, including soil cores
  ///////////////////////////////////////////////////////////////////////////
  const allSamples = this.getAllSamples();
  const showPulledCoreLocation = MapShowPulledCoreLocation.get();

  for (const sample of allSamples) {
    const feature = sample.to_feature(showPulledCoreLocation);
    if (feature) {
      features.push(feature);
    }
    const soilCores = sample.getSoilCores();
    if (shouldRedrawCores(showPulledCoreLocation, sample, soilCores)) {
      const soilFeatures: KMLFeature<KMLSoilCoreFeatureProperties>[] = [];
      for (let soilCore of soilCores) {
        const feature = soilCore.to_feature(showPulledCoreLocation);
        if (!feature) {
          continue;
        }
        soilFeatures.push(feature);
      }
      features = features.concat(soilFeatures);
      prevFeatures[sample.sample_id] = {
        soilData: JSON.stringify(soilCores),
        soilFeatures,
        skipped_or_deleted: sample.skipped_or_deleted,
      };
    } else {
      features = features.concat(prevFeatures[sample.sample_id].soilFeatures);
    }
  }

  prevFeatures.settings = {
    ...prevFeatures.settings,
    showPulledCoreLocation: showPulledCoreLocation,
  };

  ///////////////////////////////////////////////////////////////////////////
  // get dumps
  ///////////////////////////////////////////////////////////////////////////
  if (MapDebugStorage.get()) {
    const dumps = this.getSoilDumpEvents();
    for (const dump of dumps) {
      features.push(dump.to_feature());
    }
  }

  return {
    type: 'FeatureCollection',
    features: features.filter((f) => f),
  };

  function shouldRedrawCores(currentShowPulledCoreLocation: boolean, sample: Sample, soilCores: SoilCoreList) {
    if (currentShowPulledCoreLocation !== prevFeatures.settings?.showPulledCoreLocation) {
      return true;
    }

    if (!prevFeatures[sample.sample_id]) {
      return true;
    }

    const prevSampleData = prevFeatures[sample.sample_id];

    return (
      prevSampleData.skipped_or_deleted !== sample.skipped_or_deleted ||
      prevSampleData.soilData !== JSON.stringify(soilCores)
    );
  }
}

export async function update_boundary(
  this: Mission,
  poly_id: number,
  zone_type: KmlFeatureType,
  points: Coordinate[][],
) {
  if (poly_id === CREATE_NEW_BOUNDARY) {
    // create new boundary
    if (['unsafe', 'slow', 'field', 'pullin', 'collected field', 'pullin_zone'].includes(zone_type.toString())) {
      const [zone] = await Zone.new_zone(zone_type, points, this.instance_id);
      await zone.normalize();
    }
  } else {
    // update or delete boundaries
    const allObs = cleanSet(flatten(this.getZones().map((zone) => zone.getOuterZoneBoundarys())));
    const ob = allObs.find((sel) => sel.poly_id === poly_id);
    if (ob) {
      const allIbs = cleanSet(flatten(this.getZones().map((zone) => zone.getInnerZoneBoundarys())));
      const ibs = orderBy(
        allIbs.filter((sel) => sel.poly_id === poly_id),
        ['boundary_id'],
        ['asc'],
      );

      if (points.length > 0) {
        for (const [i, ring] of points.entries()) {
          // The 0th ring is the outer boundary
          // The 1st+ rings are the inner boundaries
          const bnd = i === 0 ? ob : ibs[i - 1];
          if (ring.length < 4) {
            bnd.dispose();
          } else {
            bnd.coordinates = ring;
          }
        }
      } else {
        // for sample zones get a list of cores to delete
        const zone = ob.getOuterZone()!;
        if (zone.zone_type === ZoneType.SAMPLE_ZONE) {
          const allSamples = cleanSet(flatten(this.getSampleSites().map((site) => site.getSamples())));
          const allCores = cleanSet(flatten(allSamples.map((sample) => sample.getSoilCores())));
          for (const core of allCores) {
            let contained = ob.contains(core.lat, core.lon);
            for (const ib of ibs) {
              contained = contained && !ib.contains(core.lat, core.lon);
            }
            if (contained) {
              core.dispose();
            }
          }
          // renumber waypoints
          for (const [i, waypoint] of cleanSet(orderBy(this.getWaypoints(), ['waypoint_number'], ['asc'])).entries()) {
            waypoint.waypoint_number = i + 1;
          }
        }

        // delete all inner boundaries
        for (const ib of ibs) {
          ib.dispose();
        }
        // delete outer boundary
        ob.dispose();
      }
    }
  }
  // call 'to_ros_msg' to re-calculate the nav checksum
  await this.to_ros_msg();
}

export function update_pullin(this: Mission, point: Coordinate) {
  this.pullin_lat = point[0];
  this.pullin_lon = point[1];
  dispatchMissionUpdated();
}

// TODO points is really likely coordinates but number[][] is better than nothing right now
export async function update_path(this: Mission, points: number[][]) {
  await logger.log('UPDATE_PATH', `points=${JSON.stringify(points.length)}`);
  // A path update either adds a point, deletes a point, or moves a point.
  // There is no edit in in which two or more of these will happen at once.
  let waypoints = cleanSet(orderBy(this.getWaypoints(), ['waypoint_number'], ['asc']));
  if (points.length !== waypoints.length) {
    if (points.length > waypoints.length) {
      // add a point
      for (const [i, waypoint] of waypoints.entries()) {
        if (!isclose(waypoint.lat, points[i][0]) || !isclose(waypoint.lon, points[i][1])) {
          // the first waypoint that does not match was added
          const new_waypoint = Waypoint.create();
          new_waypoint.Mission_id = this.instance_id;
          new_waypoint.waypoint_number = i + 0.5; // waypoint number non-integer here to enable sorting
          new_waypoint.lat = points[i][0];
          new_waypoint.lon = points[i][1];
          const pathpoint = PathPoint.create();
          pathpoint.Waypoint_id = new_waypoint.instance_id;
          break; // only one point can be added at a time
        }
      }
    } else if (points.length < waypoints.length) {
      // delete a point
      const patternType = this.getPrimarySpec()?.pattern_type;
      for (const [i, waypoint] of waypoints.entries()) {
        if (i >= points.length || !isclose(waypoint.lat, points[i][0]) || !isclose(waypoint.lon, points[i][1])) {
          const corePoint = waypoint.getCorePoint();
          if (!corePoint || (corePoint && patternType === GridPatterns.ZONE)) {
            // do not delete core points
            // the first waypoint that does not match was deleted
            if (corePoint) {
              corePoint?.getSoilCore()?.dispose();
            } else {
              waypoint.dispose();
            }
          }
          break; // only one point can be added at a time
        }
      }
    }
    // reorder waypoints
    waypoints = cleanSet(orderBy(this.getWaypoints(), ['waypoint_number'], ['asc']));
    for (const [i, waypoint] of waypoints.entries()) {
      waypoint.waypoint_number = i + 1;
    }
  } else {
    // move a point
    // TODO
    const patternType = this.getPrimarySpec()?.pattern_type;
    if (!patternType) {
      throw Error('Primary spec not found');
    }
    for (const [i, waypoint] of waypoints.entries()) {
      if (!isclose(waypoint.lat, points[i][0]) || !isclose(waypoint.lon, points[i][1])) {
        // Currently assumes only one spec in the mission
        if (!waypoint.getCorePoint()) {
          // allow moving path points
          waypoint.lat = points[i][0];
          waypoint.lon = points[i][1];
        } else if (i === 0 || i === waypoints.length - 1) {
          // if it is the first or last waypoint, create a new point before/after the core location
          const wp_num = i === 0 ? 0 : waypoints.length + 1;
          const new_waypoint = Waypoint.create();
          new_waypoint.Mission_id = this.instance_id;
          new_waypoint.waypoint_number = wp_num;
          new_waypoint.lat = points[i][0];
          new_waypoint.lon = points[i][1];
          const pathpoint = PathPoint.create();
          pathpoint.Waypoint_id = new_waypoint.instance_id;
          // reorder waypoints
          waypoints = cleanSet(orderBy(this.getWaypoints(), ['waypoint_number'], ['asc']));
          for (const [i, waypoint] of waypoints.entries()) {
            waypoint.waypoint_number = i + 1;
          }
        } else if (patternType === GridPatterns.ZONE) {
          // allow moving of core locations for zone sampling, but if it is the first core,
          // still create a lead in point instead of moving the core.
          // reject the edit if it moves it out of the zone
          const core = waypoint.getCorePoint()?.getSoilCore();
          const zone = core?.getSample()?.getSampleSite()?.getSampleZone()?.getZone();
          if (core && zone?.contains(points[i][0], points[i][1])) {
            waypoint.lat = points[i][0];
            waypoint.lon = points[i][1];
            core.lat = waypoint.lat;
            core.lon = waypoint.lon;
          }
        }
      }
    }
  }
  // call 'to_ros_msg' to re-calculate the nav checksum
  await this.to_ros_msg();
  dispatchMissionUpdated();
}

async function add_stop_core_waypoint(core: SoilCore, instance_id: number, waypoint_index: number, core_index: number) {
  const waypoint = Waypoint.create();
  waypoint.Mission_id = instance_id;
  waypoint.waypoint_number = waypoint_index;
  waypoint.lat = core.lat;
  waypoint.lon = core.lon;
  const core_point = CorePoint.create();
  core_point.Waypoint_id = waypoint.instance_id;
  core_point.SoilCore_id = core.instance_id;
  core.core_number = core_index;
}

function calc_line_distance(start_core: CoordinatePoint, end_core: CoordinatePoint) {
  const start_loc = fromLatLon(start_core.lat, start_core.lon);
  const end_loc = fromLatLon(end_core.lat, end_core.lon);
  return Math.sqrt((end_loc.easting - start_loc.easting) ** 2 + (end_loc.northing - start_loc.northing) ** 2);
}

async function create_new_core(
  lat_lon: number[],
  mission_instance_id: number,
  sample_instance_id: number,
  waypoint_index: number,
  core_index: number,
  source: CoreSource = 'Unknown',
) {
  const next_core = SoilCore.create();
  next_core.Sample_id = sample_instance_id;
  next_core.core_number = core_index;
  next_core.source = source;
  next_core.lat = lat_lon[0];
  next_core.lon = lat_lon[1];
  const waypoint = Waypoint.create();
  waypoint.Mission_id = mission_instance_id;
  waypoint.waypoint_number = waypoint_index;
  waypoint.lat = lat_lon[0];
  waypoint.lon = lat_lon[1];
  const core_point = CorePoint.create();
  core_point.Waypoint_id = waypoint.instance_id;
  core_point.SoilCore_id = next_core.instance_id;
}

export function to_ros_msg(this: Mission, frameID: FrameID = 'latlon') {
  // build boundaries
  const outer_boundaries: RosBoundary[] = [];
  const inner_boundaries: RosBoundary[] = [];
  const waypoints: RosWaypoint[] = [];
  if (!this) {
    return;
  }

  const missionJob = this.getJob();
  const coreDiameter = missionJob?.core_diameter || '0.75 in';
  const usingCarbonProbe = coreDiameter === '2 in';
  const CORE_COMMAND = usingCarbonProbe ? BD_CORE_COMMAND : 1;
  const CORE_AND_DUMP_COMMAND = usingCarbonProbe ? BD_CORE_COMMAND : 2;
  for (const ob of cleanSet(
    flatten(
      this.getZones()
        .filter((sel) => sel.zone_type === ZoneType.FIELD)
        .map((z) => z.getOuterZoneBoundarys()),
    ),
  )) {
    outer_boundaries.push({ points: ob.coordinates.map((coord) => ({ x: coord[1], y: coord[0], z: 0 })) });
  }

  for (const ib of cleanSet(
    flatten(
      this.getZones()
        .filter((sel) => sel.zone_type === ZoneType.FIELD)
        .map((z) => z.getInnerZoneBoundarys()),
    ),
  )) {
    inner_boundaries.push({ points: ib.coordinates.map((coord) => ({ x: coord[1], y: coord[0], z: 0 })) });
  }

  // TODO was this variable name `ib` intentional or unintentaional? Presumably just a copied statement and we didn't use a better name
  // since we are getting out boundaries here, not inner
  for (const ib of cleanSet(
    flatten(
      this.getZones()
        .filter((sel) => sel.zone_type === ZoneType.UNSAFE)
        .map((z) => z.getOuterZoneBoundarys()),
    ),
  )) {
    inner_boundaries.push({ points: ib.coordinates.map((coord) => ({ x: coord[1], y: coord[0], z: 0 })) });
  }

  // build waypoints
  const missionWaypoints = cleanSet(orderBy(this.getWaypoints(), ['waypoint_number'], ['asc']));
  const primarySpec = this.getPrimarySpec();
  const numCoresPerSample = primarySpec?.cores || 0;

  let doubleSoilDump = false;
  // if (this.getJob().client.toLowerCase().includes('andes ag')) {
  if (!this.getJob()?.zone_interleave && numCoresPerSample >= 10) {
    doubleSoilDump = true;
  }

  const coreMap: Record<string, SoilCore[]> = {};
  for (const [index, wp] of missionWaypoints.entries()) {
    const cp = wp.getCorePoint();
    const core = cp ? cp.getSoilCore() : null;

    // if this waypoint is also a core point, then we either need to take a core (1) or dump (2)
    if (core) {
      const nextCoreWaypoint = missionWaypoints.find(
        (wp, nextIndex) => nextIndex > index && !!wp.getCorePoint()?.getSoilCore(),
      );
      // if there is no more core waypoints, the last core should be a dump command anyways
      let command = CORE_COMMAND;

      // otherwise, if we know the next core point, we should see if its sample ID is different than our current sample ID
      const currentSampleId = core.getSample()?.sample_id;
      const nextCoreSampleId = nextCoreWaypoint?.getCorePoint()?.getSoilCore()?.getSample()?.sample_id;

      if (!currentSampleId) {
        throw new Error('Core does not have a sample ID');
      }

      const changingSampleIds = currentSampleId !== nextCoreSampleId;
      if (changingSampleIds) {
        command = CORE_AND_DUMP_COMMAND;
      }

      if (!(currentSampleId in coreMap)) {
        coreMap[currentSampleId] = [];
      }

      coreMap[currentSampleId].push(core);

      const coresSeen = coreMap[currentSampleId] || [];
      const coreNumber = coresSeen.length;

      if (doubleSoilDump && coreNumber === Math.round(numCoresPerSample / 2)) {
        command = CORE_AND_DUMP_COMMAND;
      }

      const sample_id = core.sample_id;
      if (!sample_id) {
        alertWarn(`Core does not have a sample ID`);
        continue;
      }

      waypoints.push({
        sample_id: core.sample_id,
        command: command,
        position: {
          x: wp.lon,
          y: wp.lat,
          z: 0,
        },
      });
    } else {
      // otherwise this is just a normal navigation waypoint (command = 0)
      waypoints.push({
        sample_id: '',
        command: 0,
        position: {
          x: wp.lon,
          y: wp.lat,
          z: 0,
        },
      });
    }
  }
  // generate the nav checksum
  this.nav_checksum = hash.sha1({ outer_boundaries, inner_boundaries, waypoints });

  const sampling_tolerance_mm = missionJob ? Math.round(missionJob.sampling_tolerance_ft * MM_PER_FT) : 0;

  // build the mission
  const spec = this.getPrimarySpec();
  const utm_loc = fromLatLon(this.pullin_lat, this.pullin_lon);
  const localSetUseArmCenteredNav = MapCalculationPositionStorage.get() === 'Arm';
  const useArmCenteredNav = missionJob?.implement_centerted_navigation || localSetUseArmCenteredNav;
  const mission: RosMissionMsgWithFieldParam = {
    header: {
      frame_id: frameID,
    },
    name: this.name,
    job_id: this.job_id || '',
    last_modified: this.last_modified,
    utm_zone: `${utm_loc.zoneNum}${utm_loc.zoneLetter}`,
    nav_checksum: this.nav_checksum,
    outer_boundaries: outer_boundaries,
    inner_boundaries: inner_boundaries,
    waypoints,
    samples: cleanSet(flatten(this.getSampleSites().map((ss) => ss.getSamples()))).map(
      (s) =>
        ({
          header: {
            stamp: { secs: 0, nsecs: 0 },
          },
          job_id: this.job_id,
          sample_id: s.sample_id,
          barcode_id: s.bag_id,
          start_depth: 0, // TODO hard coded for single depth sampling
          end_depth: spec?.end_depth || 0,
        }) as RosSample,
    ),
    field_param: [
      (missionJob?.field_aligned_sample_path || 0) |
        (missionJob?.plot_mission ? FieldParams.NoBoundaryTolerance : 0) |
        (useArmCenteredNav ? FieldParams.ImplementCenteredNavigation : 0),
      sampling_tolerance_mm,
    ],
  };

  return mission;
}

export async function delete_mission(this: Mission) {
  this.dispose();
}

export function is_zone_mission(this: Mission) {
  // TODO this is hard coded 'get the 0th sampling spec' logic, but this is actually
  // an instance where it's probably okay because upstream logic would not allow grid and
  // modgrid to be mixed with zone specs
  const missionSpec = this.getPrimarySpec();
  return missionSpec?.pattern_type === GridPatterns.ZONE || false;
}

const searchForRecord = async <T extends AirtableRecordFields = AirtableRecordFields>(
  table: string,
  view: string,
  query: string,
) => {
  try {
    const searchResult = await Airtable.search<T>(table, view, query, true);
    if (!searchResult) {
      console.error(`Failed to get record with query ${query} from table ${table} in ${view}`);
      return undefined;
    }
    await searchResult.refresh();
    const records = searchResult.getRecords();
    if (records.length === 1) {
      return records[0];
    }
  } catch (err) {
    console.error(`Failed to get record with query ${query} from table ${table} in ${view}`);
    return undefined;
  }
};

const getRecordById = async <T extends AirtableRecordFields = AirtableRecordFields>(
  table: string,
  view: string,
  recordId: string,
) => searchForRecord<T>(table, view, `RECORD_ID() = '${recordId}'`);

export async function update_job_details_with_airtable(mission: Mission, missionJob: Job) {
  if (!mission.job_id) {
    return;
  }

  const job = await Airtable.getRecord<Jobs>('Jobs', mission.job_id);

  if (!job) {
    return;
  }

  mission.name = getField(job, 'Mission Name');
  missionJob.test_package = getField(job, 'Test Pckg - Final');
  missionJob.event_id = getField(job, 'Event ID');
  missionJob.sampling_company_name = 'Rogo';
  missionJob.sampling_company_id = '';
  missionJob.response_email = getField(job, 'Email for Results');
  missionJob.client = getField(job, 'Client');
  missionJob.grower = getField(job, 'Grower');
  missionJob.farm = getField(job, 'Farm Name Clean');
  missionJob.field = getField(job, 'Field Name Clean');
  missionJob.in_field_notes_ops = getField(job, 'In-Field Notes #ops');
  missionJob.tilled_field_at_sampling = job.get('Tilled Field at Sampling?') || false;

  // @ts-ignore TODO not sure why this isn't typed correctly
  missionJob.boundary_change_type = job.get('Boundary Change Type') || '';
  missionJob.boundary_change_notes = job.get('Boundary Change Notes') || '';
  missionJob.field_id = getField(job, 'Field ID');
  missionJob.add_on_freq = Number.parseInt(getField(job, 'Final Freq of Add-On Test Pckg', '1'));
  missionJob.sample_order_type = getField(job, 'PDF order type');
  missionJob.submitter_notified_id = getField(job, 'Submitter ID Final');
  missionJob.sites_type = getField(job, 'Deal Sites Type');
  missionJob.map_making_rules = '';

  // this field MUST be set before we look at points_submitted_from_customer or zones_interleave because
  // they are both just shadow properties that look at this field
  missionJob.required_fields = getField(job, 'Required Fields Job + Deal', '');
  missionJob.job_flags = getField(job, 'Job Flags', [], { returnArray: true });

  const fieldNotes = await job.get_attachments('Field Notes');
  if (fieldNotes.length > 0) {
    missionJob.map_making_rules += `Field Notes: `;
    let count = 1;
    for (const note of fieldNotes) {
      // format each attachment as an href link with the text being #1, #2, etc on the same line
      // open the attachment in a new tab
      missionJob.map_making_rules += `<a href="${note.url}" target="_blank">#${count++}</a> `;
    }
    missionJob.map_making_rules += '\n';
  }

  return job;
}

export async function update_job_with_lab_details(missionJob: Job, job: AirtableRecord<Jobs>) {
  const labName = getField(job, 'Lab - Final')?.toString().replace(/"/g, '');
  missionJob.lab_name = labName || missionJob.lab_name;

  // update lab detaiils
  // TODO update with sampling spec?
  missionJob.billing_account = getField(job, 'Final Lab Acc #');
  missionJob.lab_short_name = getField(job, 'Lab Shortname')!.toString().replace(/"/g, '');
  missionJob.lab_code = getField(job, 'Lab Code');
  missionJob.lab_instructions = getField(job, 'Lab Instructions');
  missionJob.lab_submittal_id = getField(job, 'Final Lab Presubmission Code');
  missionJob.lab_address = getField(job, 'Lab Address');

  const labRecord = await searchForRecord<Labs>('Labs', 'App View - Labs', `{Name} = '${labName}'`);

  if (!labRecord) {
    alertWarn(`Unable to retrieve lab record when updating mission details`);

    return;
  }

  missionJob.lab_address = getField(labRecord, 'Address') || missionJob.lab_address;

  missionJob.lab_name = getField(labRecord, 'Name');
  missionJob.lab_short_name = getField(labRecord, 'Lab Shortcut Name');
  missionJob.lab_code = getField(labRecord, 'Lab Code');
  missionJob.lab_address = getField(labRecord, 'Address');
  missionJob.lab_primary_delivery =
    getField(labRecord, 'Primary Delivery Method') || getField(job, 'Lab Primary Delivery Method');
  missionJob.lab_qrs_required = getFieldArrayIncludes(labRecord, 'Lab Options', 'QR Required');
  missionJob.auto_renumber = getFieldArrayIncludes(labRecord, 'Lab Options', 'Auto Renumber');

  return labRecord;
}

export async function update_job_with_company_details(missionJob: Job, job: AirtableRecord<Jobs>) {
  const companyRecordId = getField(job, 'Client original name') as string | undefined;
  if (!companyRecordId) {
    return;
  }

  const companyRecord = await getRecordById<Companies>(
    'Companies',
    'Active Companies - Deals OR Customer Status Current',
    companyRecordId,
  );

  if (!companyRecord) {
    alertWarn(`Unable to retrieve company record when updating mission details`);

    return;
  }

  const companyMapMakingRules = getField(companyRecord, 'Mapmaking Rules');
  if (companyMapMakingRules) {
    missionJob.map_making_rules += 'Company Mapmaking Rules\n';
    missionJob.map_making_rules += getField(companyRecord, 'Mapmaking Rules');
    missionJob.allowed_to_renumber = getField(companyRecord, 'Renumber Points?', false);
    missionJob.allowed_to_create = getField(companyRecord, 'Create Points?', false);
    missionJob.allowed_to_move = getField(companyRecord, 'Move Points?', false);
  }

  missionJob.external_reference_id_column = getField(companyRecord, 'External Sample Reference ID Column');

  return companyRecord;
}

async function update_job_with_deal(missionJob: Job, job: AirtableRecord<Jobs>) {
  const dealId: string = getField(job, 'Deal');

  const primaryDeal = await getRecordById<Deals>('Deals', 'App View - Active Deals', dealId);
  if (!primaryDeal) {
    return [];
  }

  missionJob.core_diameter = getField(primaryDeal, 'Core Diameter') || '0.75 in';
  const dealFieldParams: string[] = getField(primaryDeal, 'Field Parameters', [], { returnArray: true });
  const useFieldAlignedSamplePath = dealFieldParams.includes('Field Aligned Sample Path');
  if (useFieldAlignedSamplePath) {
    missionJob.field_aligned_sample_path = FieldParams.FieldAlignedSamplePath;
  }
  missionJob.manual_hole_depth_measurement = dealFieldParams.includes('Manual Hole Depth Measurement');
  // missionJob.manual_plunge_depth_measurement = dealFieldParams.includes('Manual Plunge Depth Measurement');
  missionJob.manual_core_length_measurement = dealFieldParams.includes('Manual Core Length Measurement');
  missionJob.manual_core_loss_measurement = dealFieldParams.includes('Manual Core Loss Measurement');
  missionJob.plot_mission = dealFieldParams.includes('Plot Missions');
  missionJob.disable_core_line_alteration = dealFieldParams.includes('Disable Core Line Alteration');
  missionJob.use_original_sample_id = dealFieldParams.includes('Use Original Sample ID');
  missionJob.sampling_tolerance_ft = getField(primaryDeal, 'Sampling Tolerance (ft)');
  missionJob.sampling_type_special = getField(primaryDeal, 'Sampling Type Special');
  missionJob.implement_centerted_navigation = dealFieldParams.includes('Implement Centered Navigation');
  missionJob.strict_core_enforcement = dealFieldParams.includes('Strict Core Enforcement');
  missionJob.enable_manual_drive_aid = dealFieldParams.includes('Manual Drive Aids');
  missionJob.auto_zoom_near_sample = dealFieldParams.includes('Auto Zoom Near Sample');

  const secondaryDealIds = getField(primaryDeal, 'Additional Sampling Configurations', [], { returnArray: true });
  const secondaryDeals = await Promise.all(
    secondaryDealIds.map((dealId) => getRecordById<Deals>('Deals', 'App View - Active Deals', dealId)),
  );

  return [primaryDeal, ...secondaryDeals.filter((deal) => !!deal)];
}

async function update_mapmaking_rules(missionJob: Job, job: AirtableRecord<Jobs>) {
  const submissionNotes = getField(job, 'Submission Notes');
  const samplingRestrictionNotes = getField(job, 'Sampling Restriction Notes');
  const parkingFieldAccessNotes = getField(job, 'Parking / Field Access Notes');
  const jobReadySubmissionNotes = getField(job, 'Job Ready Submission Notes');
  const boundaryCollectioon = getField(job, 'Major Boundary Collection?');
  if (submissionNotes) {
    missionJob.map_making_rules += `Submission Notes:\n-${submissionNotes}\n\n`;
  }
  if (missionJob.points_submitted_from_customer) {
    missionJob.map_making_rules += `Points are or should be submitted; if not showing up, flag issue.\n\n`;
  }
  if (missionJob.zone_interleave) {
    missionJob.map_making_rules += `Zones CAN be interleaved.\n\n`;
  }
  if (boundaryCollectioon === 1) {
    missionJob.map_making_rules += `BOUNDARY COLLECTION: Be extra careful of boundary & safe zones\n\n`;
  }
  if (samplingRestrictionNotes) {
    missionJob.map_making_rules += `Sampling Restriction Notes:\n-${samplingRestrictionNotes}\n\n`;
  }
  if (parkingFieldAccessNotes) {
    missionJob.map_making_rules += `Parking / Field Access Notes:\n-${parkingFieldAccessNotes}\n\n`;
  }
  if (jobReadySubmissionNotes) {
    missionJob.map_making_rules += `Job Ready Submission Notes:\n-${jobReadySubmissionNotes}\n\n`;
  }
  if (missionJob.lab_instructions) {
    missionJob.map_making_rules += `Lab Instructions:\n-${missionJob.lab_instructions}\n\n`;
  }

  const fieldNotes = await job.get_attachments('Field Notes');
  if (fieldNotes.length > 0) {
    missionJob.map_making_rules += `Field Notes: `;
    let count = 1;
    for (const note of fieldNotes) {
      // format each attachment as an href link with the text being #1, #2, etc on the same line
      // open the attachment in a new tab
      missionJob.map_making_rules += `<a href="${note.url}" target="_blank">#${count++}</a> `;
    }
    missionJob.map_making_rules += '\n';
  }

  const branchRecordId = getField(job, 'Branch / Location #form') as string;
  if (branchRecordId) {
    const branchSearch = await Airtable.search('Branches', '', "RECORD_ID() = '" + branchRecordId + "'", true);
    await branchSearch?.refresh();
    const branch = await Airtable.getRecord('Branches', branchRecordId);
    const mobileNumber = getField(branch, 'Mobile Phone (from Branch Contact)');
    const email = getField(branch, 'Branch Contact Immediate Job Creation Confirmation Emails');
    if (missionJob.map_making_rules && (email || mobileNumber)) {
      missionJob.map_making_rules += '\n\n';
    }
    if (email || mobileNumber) {
      missionJob.map_making_rules += 'Branch Contact:\n';
    }
    if (email) {
      missionJob.map_making_rules += `- Email: ${email}`;
    }
    if (mobileNumber) {
      missionJob.map_making_rules += `\n- Mobile: ${mobileNumber}`;
    }
  }
}

function update_sample_types(mission: Mission, job: AirtableRecord<Jobs>) {
  if ('Sampling Type Final' in job) {
    const samplingType = getField(job, 'Sampling Type Final');
    for (const sample of cleanSet(flatten(mission.getSampleSites().map((sel) => sel.getSamples())))) {
      if (samplingType === 'ISNT 1') {
        sample.sample_type = SamplingTypes.ISNT_1;
      } else if (samplingType === 'ISNT 2') {
        sample.sample_type = SamplingTypes.ISNT_2;
      } else if (samplingType === 'Cyst') {
        sample.sample_type = SamplingTypes.CYST;
      } else {
        sample.sample_type = SamplingTypes.REGULAR;
      }
    }
  }
}

async function create_sampling_specs(mission: Mission, deals: AirtableRecord<Deals>[]) {
  if (!deals.length) {
    return;
  }

  const createSpecFromDeal = async (deal: AirtableRecord<Deals>, primary_spec = false) => {
    const zoneMission = getField(deal, 'Sites Type #serviceinfo') === 'Zone';
    const pattern = getField(deal, 'Pattern #serviceinfo');
    const circlePattern = pattern === 'Circle';
    const dealBarcodeType = await getDealBarcodeType(deal);
    let patternType: GridPatterns = GridPatterns.LINE;
    if (pattern && zoneMission) {
      // this combination of configurations is not possible
      throw new Error(`Zone mission with pattern ${pattern} is not possible`);
    }
    if (zoneMission) {
      patternType = GridPatterns.ZONE;
    } else if (circlePattern) {
      patternType = GridPatterns.CIRCLE;
    } else {
      patternType = GridPatterns.LINE;
    }

    const labNames = deal.get('Lab Name #lab');
    const labShortNames = deal.get('Lab Shortname');
    const labAddresses = deal.get('Lab Address');
    const labCodes = deal.get('Lab Code');

    const spec = SamplingSpec.createWithParams({
      spec_id: uuidv4(),
      deal_id: deal.id,
      primary_spec,
      Mission_id: mission.instance_id,
      pattern_type: patternType,
      cores: Number.parseInt(getField(deal, 'Cores #serviceinfo', '0')),
      radius: Number.parseFloat(getField(deal, 'Pattern Size (ft) #serviceinfo', '0.0')),
      length: Number.parseFloat(getField(deal, 'Pattern Size (ft) #serviceinfo', '0.0')),
      angle: Number.parseFloat(getField(deal, 'Pattern Angle (deg from East) #serviceinfo', '0.0')),
      start_depth: 0.0,
      end_depth: Number.parseFloat(getField(deal, 'Depth (in.) #serviceinfo', '0.0')),
      lab_name: Array.isArray(labNames) ? labNames[0] : '',
      lab_short_name: Array.isArray(labShortNames) ? labShortNames[0] : '',
      lab_address: Array.isArray(labAddresses) ? labAddresses[0] : '',
      lab_code: Array.isArray(labCodes) ? labCodes[0] : '',
      test_package: deal.get('Test Pckg Display') as string,
      barcode_regex: dealBarcodeType ? getField(dealBarcodeType, 'Regex') : '',
      parallel_line_distance_ft: METERS_BETWEEN_CORE_LINES_FOR_MULTI_LAB,
    });

    return spec;
  };

  const primaryDeal = deals[0];
  await createSpecFromDeal(primaryDeal, true);

  // Create additional specs
  if (deals.length > 1) {
    const secondaryDeals = deals.slice(1);
    for (const deal of secondaryDeals) {
      await createSpecFromDeal(deal);
    }
  }
}

function updateMutableSamplingSpecPropsFromAt(mission: Mission, job: AirtableRecord<Jobs>) {
  const zoneMission = getField(job, 'Deal Sites Type') === 'Zone';

  for (const spec of cleanSet(mission.getSamplingSpecs())) {
    if (!zoneMission) {
      spec.radius = Number.parseFloat(getField(job, 'Deal Pattern size', '0.0'));
      spec.length = Number.parseFloat(getField(job, 'Deal Pattern size', '0.0'));
      spec.angle = Number.parseFloat(getField(job, 'Final Angle (CCW from E)', '0.0'));
    }

    spec.cores = Number.parseInt(getField(job, 'Deal Cores', '0'));
  }
}

function updateImmutableSamplingSpecPropsFromAt(mission: Mission, job: AirtableRecord<Jobs>) {
  const patternStr = getField(job, 'Deal Pattern');
  const zoneMission = getField(job, 'Deal Sites Type') === 'Zone';

  for (const spec of cleanSet(mission.getSamplingSpecs())) {
    if (zoneMission) {
      spec.pattern_type = GridPatterns.ZONE;
    } else {
      spec.pattern_type = patternStr === 'Circle' ? GridPatterns.CIRCLE : GridPatterns.LINE;
    }
    // Depths are not really immutable - a user can change them, however per Stephen:
    // "Operators have a depth override, but it's not used regularly and we don't necessarily want to persist that value"
    spec.start_depth = 0.0;
    spec.end_depth = Number.parseFloat(getField(job, 'Depth', '0.0'));
  }
}

/**
 * Update the mission details from the corresponding airtable record.
 * Create sampling specs if loading from shapefiles, i.e. for the first time.
 */
export async function update_mission_details(
  this: Mission,
  { sampling = false, loading_from_shapefiles = false } = {},
): Promise<boolean> {
  if (!this.job_id) {
    const msg = `Unable to update mission details without a job ID`;
    alertWarn(msg);

    throw new Error(msg);
  }

  const missionJob = this.getJob();

  if (!missionJob) {
    throw new Error(`Mission job not found`);
  }

  const atJob = await update_job_details_with_airtable(this, missionJob);
  if (!atJob) {
    throw new Error(`Failed to retrieve job record for job ID ${this.job_id}`);
  }

  await update_job_with_lab_details(missionJob, atJob);

  await update_job_with_company_details(missionJob, atJob);

  const deals = await update_job_with_deal(missionJob, atJob);
  if (!deals.length) {
    throw new Error('No active deals were found for this job');
  }

  if (!sampling) {
    await update_mapmaking_rules(missionJob, atJob);
  }

  update_sample_types(this, atJob);

  if (loading_from_shapefiles) {
    await create_sampling_specs(this, deals);

    // We only want to call this when loading from shapefiles (i.e. loading a mission for the 1st time)
    // because otherwise, a user changes spec parameters (e.g. angle), and this function would override it.
    updateMutableSamplingSpecPropsFromAt(this, atJob);
  }

  // Should be called after creating specs (or when the specs already exist)
  updateImmutableSamplingSpecPropsFromAt(this, atJob);

  // We should always have a primary spec for the mission.
  if (!this.getPrimarySpec()) {
    throw new Error(`No primary spec found for job ${this.job_id}`);
  }

  return true;
}

export async function renumber_samples(this: Mission, sampling: boolean = true, recalculate_checksum = false) {
  logger.log(`RENUMBER_SAMPLES`, `sampling=${sampling} recalculate_checksum=${recalculate_checksum}`);
  const job = this.getJob();

  if (job && !job.allowed_to_renumber) {
    if (sampling) {
      alertError(
        'Cannot renumber samples while sampling is in progress. Please contacts ops if you need to renumber samples.',
      );
      return;
    }

    // mapmaking mode
    const definitelyRenumber = await alertWarnConfirm(
      'This job does not allow renumbering. Are you SURE you want to renumber?',
    );

    if (!definitelyRenumber) {
      return;
    }
  }

  const samples = cleanSet(flatten(this.getSampleSites().map((site) => site.getSamples())));
  const orderedSamples = orderBy(samples, ['order'], ['asc']);
  const renumberedSamples = new Set<SampleSite>();
  let sampleIndex = 1;
  for (const sample of orderedSamples) {
    const sampleSite = sample.getSampleSite();
    const newSampleId = sampleIndex.toString();
    if (sample.sample_id !== newSampleId) {
      renumberedSamples.add(sampleSite);
    }
    sample.sample_id = sampleIndex.toString();
    sampleIndex++;
  }
  this.last_modified = Date.now() / 1000;

  if (sampling) {
    const session = getCurrentSession();
    for (const sampleSite of renumberedSamples) {
      for (const sample of sampleSite.getSamples()) {
        const box = sample.getSampleBox();
        if (box && box.closed) {
          // set box reprint session ID if this renumbered sample already belonged to a box
          logger.log('RENUMBER_SAMPLES', `Need to reprint box ${box.uid}`);
          box.ReprintSession_id = session?.instance_id;
          box.reprint_reason = 'Samples were renumbered';
        }
      }
    }
  }

  if (recalculate_checksum) {
    await this.to_ros_msg();
  }
}

export async function add_sample(
  this: Mission,
  point: { coords: { lat: number; lon: number } },
  sample_id: string,
  sample_source: SampleSource,
) {
  await logger.log(`ADD_SAMPLE`, `Adding sample ${sample_id} ${sample_source} ${point.coords.lat} ${point.coords.lon}`);
  const sample = Sample.create();
  sample.sample_id = sample_id;

  const sample_site = SampleSite.create();
  sample_site.original_sample_id = sample_id;
  sample_site.Mission_id = this.instance_id;
  sample_site.sample_site_source = sample_source;

  const sample_centroid = SampleCentroid.create();
  sample_centroid.SampleSite_id = sample_site.instance_id;
  sample_centroid.lat = point.coords.lat;
  sample_centroid.lon = point.coords.lon;

  sample.SampleSite_id = sample_site.instance_id;
  sample.sample_type = SamplingTypes.REGULAR;

  const sampling_spec = this.getPrimarySpec();
  if (sampling_spec) {
    sample.SamplingSpec_id = sampling_spec.instance_id;
  }

  const samples_in_site = cleanSet(flattenDeep(this.getSampleSites().map((site) => site.getSamples())));
  sample.order = Math.max(...samples_in_site.map((sel) => sel.order)) + 1;

  return sample;
}

export async function add_core(
  this: Mission,
  point: { coords: { lat: number; lon: number } },
  core_id: string,
  sample_source: SampleSource,
) {
  await logger.log(`ADD_CORE`, `Adding core ${core_id} ${sample_source} ${point.coords.lat} ${point.coords.lon}`);
  const [sampleId, coreId] = core_id.split('.'); // TODO THIS NEEDS TO BE BETTER
  const matchingSamples = this.getAllSamples().filter((sample) => sample.sample_id === sampleId);
  if (!matchingSamples.length) {
    const message = `Could not find sample ${sampleId}`;
    alertError(message);

    throw new Error(message);
  }

  const sample = matchingSamples[0];

  const core = SoilCore.create();
  core.Sample_id = sample.instance_id;
  core.core_number = parseInt(coreId);
  core.lat = point.coords.lat;
  core.lon = point.coords.lon;
  core.source = sample_source;

  return sample;
}

export function getBarcodes(this: Mission) {
  const samples = this.getSampleSites().getSamples();
  samples.sort((a, b) => a.sample_id?.localeCompare(b.sample_id || '', 'en', { numeric: true }) || 0);
  const barcodes: string[] = [];
  for (const sample of samples) {
    if (sample.bag_id && !sample.skipped_or_deleted) {
      barcodes.push(sample.bag_id);
    }
  }
  return barcodes;
}

export function getMissionName(this: Mission, type: string) {
  const lastMod = new Date(this.last_modified * 1000);
  const lastModTimestamp = formatDateAsString(lastMod);

  // Attempt to resolve mission name file upload bug by removing more special charcters. This should be more
  // universal across the app but it is not, so this is a bandaid
  return `${this.name}_${lastModTimestamp}_${type}`.replaceAll(':', '-').replaceAll('(', '').replaceAll(')', '');
}

export function getCentroidPath(this: Mission) {
  //let centroidPath: Coordinate[] = [];
  const mission_samples = cleanSet(
    orderBy(flatten(this.getSampleSites().map((site) => site.getSamples())), ['order'], ['asc']),
  );
  return mission_samples.map((sample) => {
    const sample_centroid = sample.getSampleSite().getSampleCentroid();
    if (!sample_centroid) {
      throw new Error(`Sample ${sample.sample_id} does not have a centroid`);
    }
    return { coord: [sample_centroid.lat, sample_centroid.lon] as Coordinate, sample_id: sample.sample_id };
  });
}

export function insertPathWaypoint(coordinate: Coordinate) {}

async function getDealBarcodeType(deal: AirtableRecord<Deals>): Promise<AirtableRecord<BarcodeTypes> | null> {
  const dealRestrictedBarcodeType = getField(deal, 'Restricted Barcode Type Final');
  if (!dealRestrictedBarcodeType) {
    return null;
  }

  let barcodeType: AirtableRecord<BarcodeTypes> | undefined = undefined;
  const barcodeTypesQuery = await Airtable.search<BarcodeTypes>(
    'Barcode Types',
    '',
    `Name = '${dealRestrictedBarcodeType}'`,
    true,
  );
  const records = barcodeTypesQuery?.getRecords() || [];
  if (records.length === 0) {
    return null;
  }

  barcodeType = records[0];
  await barcodeType.refresh();

  return barcodeType;
}
