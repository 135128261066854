import { Fragment } from 'react/jsx-runtime';
import { AirtableRecord } from '../../db';
import { Box } from '@rogoag/airtable';
import { Grid, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { Tick } from 'react-crude-animated-tick';
import TakePhotoOrUpload from './TakePhotoOrUpload';
import NoScanOrPicExcuse from './NoScanOrPicExcuse';

interface Props {
  box: AirtableRecord<Box>;
  excludedBoxes: string[];
  expandedBoxes: string[];
  group: any;
  dropoffName: string;
  collapseBox: (boxId: string) => void;
  expandBox: (boxId: string) => void;
  excludeBox: (boxId: string) => void;
  scannedBoxIds: string[];
  cameraError: string;
  scannedBoxPicture?: string;
  activateCaptureModal: () => void;
  onUploadedBoxPictureChange: (boxId: string, file: File) => Promise<void>;
  loading: boolean;
  captureLoading: boolean;
  noScanOrPicExcuse?: string;
  onNoScanOrPicExcuseChange: (excuse: string | undefined) => void;
}

export function ShippingGroupBox({
  box,
  excludedBoxes,
  expandedBoxes,
  group,
  dropoffName,
  collapseBox,
  expandBox,
  excludeBox,
  scannedBoxIds,
  cameraError,
  scannedBoxPicture,
  activateCaptureModal,
  onUploadedBoxPictureChange,
  loading,
  captureLoading,
  noScanOrPicExcuse,
  onNoScanOrPicExcuseChange,
}: Props) {
  return (
    <Fragment>
      {!excludedBoxes.includes(box.get('Box ID') ?? '') && (
        <Fragment key={box.id}>
          <TableRow style={!group.get(dropoffName).valid ? { opacity: '25%' } : {}}>
            <TableCell>
              <Grid item style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <IconButton
                  key={'Collapse/Expand'}
                  onClick={(event) => {
                    event.stopPropagation();
                    const boxId = box.get('Box ID');
                    if (!boxId) {
                      throw new Error('Box ID is not defined');
                    }
                    if (expandedBoxes.includes(boxId)) {
                      collapseBox(boxId);
                    } else {
                      expandBox(boxId);
                    }
                  }}
                >
                  {expandedBoxes.includes(box.get('Box ID') ?? '') ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>

                <IconButton
                  key={'Exclude'}
                  size={'small'}
                  disabled={!group.get(dropoffName).valid}
                  onClick={() => excludeBox(box.get('Box ID') ?? '')}
                  style={{ padding: 6, marginRight: 5 }}
                >
                  <AiOutlineMinusCircle />
                </IconButton>
                <Typography
                  variant="body2"
                  color={
                    scannedBoxIds.find((scannedBoxID) => scannedBoxID === box.get('Box ID')) ? 'textPrimary' : 'error'
                  }
                >
                  Box {box.get('Box ID')}
                </Typography>
              </Grid>
            </TableCell>

            <TableCell>
              <TakePhotoOrUpload
                cameraError={cameraError}
                picture={scannedBoxPicture}
                activateCaptureModal={activateCaptureModal}
                onUploadedFileChange={(file) => onUploadedBoxPictureChange(box.get('Box ID') || '', file)}
                loading={loading}
                captureLoading={captureLoading}
              />
            </TableCell>

            <TableCell>
              <NoScanOrPicExcuse excuse={noScanOrPicExcuse || ''} onExcuseChange={onNoScanOrPicExcuseChange} />
            </TableCell>

            {scannedBoxIds.find((scannedBoxID) => scannedBoxID === box.get('Box ID')) ? (
              <TableCell align="right" style={{ padding: 8 }}>
                <Grid
                  container
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginRight: 7.5,
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid item style={{ flex: 1 }}>
                    <Typography variant="body2">Scanned</Typography>
                  </Grid>

                  <Grid item style={{ marginLeft: 10, marginRight: 10, marginBottom: 4 }}>
                    <Tick size={24} />
                  </Grid>
                </Grid>
              </TableCell>
            ) : (
              <TableCell style={{ padding: 0 }} align="right">
                <Typography variant="body2" style={{ marginRight: 10, fontStyle: 'italic' }} color="error">
                  Needs scanning
                </Typography>
              </TableCell>
            )}
          </TableRow>

          {expandedBoxes.includes(box.get('Box ID') ?? '') &&
            Object.entries(JSON.parse(box.get('Samples') ?? '{}')).map(([key, sampleObj]) => (
              <TableRow key={key} style={{ backgroundColor: '#f2f2f2' }}>
                <TableCell key={'jobInfo'}>{sampleObj.jobInfo.field}</TableCell>
                <TableCell key={'samples'} align="right" colSpan={3}>
                  {sampleObj.samples.length}
                </TableCell>
              </TableRow>
            ))}
        </Fragment>
      )}
    </Fragment>
  );
}
