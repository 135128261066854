import { IKMLElement, KMLElement } from '../kml';
import { fromLatLon, toLatLon } from 'utm';
import md5 from 'md5';

import {
  Boundary,
  GridPatterns,
  Mission,
  Sample,
  SampleSite,
  SampleZone,
  SamplingSpec,
  SamplingTypes,
  Zone,
  ZoneType,
} from '../db';

import { ZoneRecordingType } from '../db/ZoneTypesDatatype';

import { cleanSet, convertProjection4329 } from '../utils';

import { Feature, MultiPolygon, Polygon, polygon } from '@turf/helpers';
import difference from '@turf/difference';

import { orderBy, flatten } from 'lodash';
import ZoneClass from '../db/ZoneClass';
import { Coordinate } from 'ol/coordinate';
import { KMLFeature, KMLZoneFeatureProperties } from '../types/types';
import GeometryType from 'ol/geom/GeometryType';

export function to_kml(this: ZoneClass) {
  const polys: IKMLElement[] = [];
  for (const outer_boundary of cleanSet(orderBy(this.getOuterZoneBoundarys(), ['poly_id'], ['asc']))) {
    const placemark = KMLElement.element('Placemark');
    const nm = KMLElement.subelement(placemark, 'name');
    nm.setText(this.zone_name);
    const placemark_vis = KMLElement.subelement(placemark, 'visibility');
    placemark_vis.setText('1');
    placemark.putExtendedData('poly_id', outer_boundary.poly_id);
    placemark.putExtendedData('zone_id', outer_boundary.poly_id); // for backwards compatibility TODO
    if (this.zone_type === ZoneType.PULLIN) {
      placemark.putExtendedData('generic_zone', true); // for backwards compatibility TODO
    } else if (this.zone_type === ZoneType.SAMPLE_ZONE) {
      placemark.putExtendedData('sample_zone', true);
      const sampleSite = this.getSampleZone()?.getSampleSite();
      placemark.putExtendedData('change_reason', sampleSite?.change_reason);
      placemark.putExtendedData('change_type', sampleSite?.change_type);
    }
    const poly = KMLElement.subelement(placemark, 'Polygon');

    // create outer boundary
    const ob = KMLElement.subelement(poly, 'outerBoundaryIs');
    const lr = outer_boundary.to_kml();
    ob.append(lr);

    // create inner boundaries
    const orderedInnerBoundaries = cleanSet(
      orderBy(
        this.getInnerZoneBoundarys(),
        ['boundary_id'],
        ['asc'],
      ),
    );

    for (const inner_boundary of orderedInnerBoundaries) {
      const ib = KMLElement.subelement(poly, 'innerBoundaryIs');
      const lr = inner_boundary.to_kml();
      ib.append(lr);
    }

    // set style
    const style = KMLElement.subelement(placemark, 'styleUrl');
    if (this.zone_type === ZoneType.FIELD) {
      style.setText('#field_boundary');
    } else if (this.zone_type === ZoneType.UNSAFE) {
      style.setText('#unsafe_zone');
    } else if (this.zone_type === ZoneType.SLOW) {
      style.setText('#slow_zone');
    } else if (this.zone_type === ZoneType.PULLIN) {
      style.setText('#generic_zone'); // TODO
    } else if (this.zone_type === ZoneType.COLLECTED_FIELD) {
      style.setText('#collected_field_boundary');
    } else if (this.zone_type === ZoneType.SAMPLE_ZONE) {
      style.setText(`#sample_zone_${this.zone_name}`);
    }

    // add polygon to output list
    polys.push(placemark);
  }
  return polys;
}

export async function load_kml(poly: IKMLElement, mission: Mission): Promise<[Zone, string[]]> {
  const primarySpec = mission.getPrimarySpec();
  if (!primarySpec) {
    throw new Error('Primary sampling spec not found');
  }

  let error_messages: string[] = [];
  const kmlZone = poly.find('name');
  let name = kmlZone ? kmlZone.getText() : '';
  if (['field', 'unsafe', 'slow', 'pullin', 'collected field', 'pullin_zone'].includes(name.toLowerCase())) {
    name = name?.toLowerCase();
  }

  let zone = cleanSet(mission.getZones()).find((sel) => sel.zone_name === name);
  if (!zone) {
    // create a new zone
    zone = Zone.create();
    zone.Mission_id = mission.instance_id;
    zone.zone_name = name;
    if (name.toLowerCase() === 'field') {
      zone.zone_type = ZoneType.FIELD;
    } else if (name.toLowerCase() === 'unsafe') {
      zone.zone_type = ZoneType.UNSAFE;
    } else if (name.toLowerCase() === 'slow') {
      zone.zone_type = ZoneType.SLOW;
    } else if (name.toLowerCase() === 'pullin' || name.toLowerCase() === 'pullin_zone') {
      zone.zone_type = ZoneType.PULLIN;
    } else if (name.toLowerCase() === 'collected field') {
      zone.zone_type = ZoneType.COLLECTED_FIELD;
    } else if (name.match(/^[a-zA-Z0-9]+$/)) {
      zone.zone_type = ZoneType.SAMPLE_ZONE;
    } else {
      error_messages.push(`ERROR: cannot create unsupported zone '${name}`);
    }

    // if this zone has an alphanumeric name, create a sample zone
    if (
      !['field', 'unsafe', 'slow', 'pullin', 'collected field', 'pullin_zone'].includes(name.toLowerCase()) &&
      name.match(/^[a-zA-Z0-9]+$/)
    ) {
      const sample_site = SampleSite.create();
      sample_site.original_sample_id = name;
      sample_site.Mission_id = mission.instance_id;

      const sample_zone = SampleZone.create();
      sample_zone.SampleSite_id = sample_site.instance_id;
      sample_zone.Zone_id = zone.instance_id;

      Sample.createSample(mission, sample_site, primarySpec, name, SamplingTypes.REGULAR);
    }
  }

  // load the outer boundary
  const ed = poly.getExtendedData();
  const poly_id = ed.poly_id ? parseInt(ed.poly_id) : mission.assign_id() * -1; // multiply by -1 to avoid collisions later
  const ob = poly.find('outerBoundaryIs', true);
  const lr = ob ? ob.find('LinearRing') : null;
  if (lr) {
    const [, err] = await Boundary.load_kml(lr, zone, true, poly_id);
    error_messages = error_messages.concat(err);
  } else {
    error_messages.push(`ERROR: polygon '${name}' has no outer boundary`);
  }

  // load any inner boundaries
  const ibs = poly.findAll('innerBoundaryIs', true);
  for (const ib of ibs) {
    const lrs = ib.findAll('LinearRing', true);
    for (const lr of lrs) {
      const [, err] = await Boundary.load_kml(lr, zone, false, poly_id);
      error_messages = error_messages.concat(err);
    }
  }

  // return loaded zone and error/warning messages
  // console.log(`zone_ops.ts:load_kml`, zone.getOuterZoneBoundarys()[0]?.coordinates);
  return [zone, error_messages];
}

export function get_color(this: ZoneClass) {
  const [r, g, b] = this.get_color_array();
  return `${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

export function contains(this: ZoneClass, lat: number, lon: number) {
  return this.getContainingBoundaries(lat, lon).length > 0;
  // let contained = false;
  // for (const ob of cleanSet(this.getOuterZoneBoundarys())) {
  //   if (ob.contains(lat, lon)) {
  //     contained = true;
  //     break;
  //   }
  // }
  // for (const ib of cleanSet(this.getInnerZoneBoundarys())) {
  //   if (ib.contains(lat, lon)) {
  //     contained = false;
  //     break;
  //   }
  // }
  // return contained;
}

export function getContainingBoundaries(this: ZoneClass, lat: number, lon: number) {
  const boundaries: Boundary[] = [];
  const outerZoneBoundaries = this.getOuterZoneBoundarys();
  const innerZoneBoundaries = this.getInnerZoneBoundarys();

  let skipZone = false;
  for (const boundary of cleanSet(innerZoneBoundaries)) {
    if (boundary.contains(lat, lon)) {
      skipZone = true;
      break;

      // if this matches with an inner boundary ID, we will likely be able to just skip this. But there is an edge case.
      // If this is the ONLY inner boundary for this zone, then we can move on because it will be a different sample.
      // if (innerZoneBoundaries.length === 1) {
      //   skipZone = true;
      //   break;
      // }

      // If there is a boundary in a boundary, would they both be boundaries of the outer boundary? Not sure...
      // for now, we will skip as this scenarios is incredibly rare in our maps
      // A A A A A
      // B B B B A
      // A B A B A
      // A B A B A
      // B B B B A
      // A A A A A
    }
  }

  if (skipZone) {
    return boundaries;
  }

  for (const boundary of cleanSet(outerZoneBoundaries)) {
    if (boundary.contains(lat, lon)) {
      boundaries.push(boundary);
    }
  }
  return boundaries;
}

type UtmLoc = {
  easting: number;
  northing: number;
  zoneNum: number;
  zoneLetter: string;
};

export async function normalize(this: ZoneClass) {
  // check if two outer boundaries have the same polygon id
  let normalized = true;
  const obs = cleanSet(this.getOuterZoneBoundarys());
  for (const ob of obs) {
    if (obs.filter((sel) => sel.poly_id === ob.poly_id).length > 1) {
      normalized = false;
      break;
    }
  }

  if (!normalized) {
    let zone_polys: (Feature<Polygon | MultiPolygon> | null)[] = [];
    let utm_loc: UtmLoc | undefined = undefined;
    for (const ob of obs) {
      const utm_coords: Coordinate[] = [];
      for (const coord of ob.coordinates) {
        utm_loc = fromLatLon(coord[0], coord[1]);
        utm_coords.push([utm_loc.easting, utm_loc.northing]);
      }
      zone_polys.push(polygon([utm_coords]));
    }

    const old_inner_boundaries = cleanSet(this.getInnerZoneBoundarys());
    for (const ib of old_inner_boundaries) {
      const utm_coords: Coordinate[] = [];
      for (const coord of ib.coordinates) {
        utm_loc = fromLatLon(coord[0], coord[1]);
        utm_coords.push([utm_loc.easting, utm_loc.northing]);
      }
      if (zone_polys.length > 0) {
        const ib_poly = polygon([utm_coords]);
        zone_polys = zone_polys.map((poly) => (poly ? difference(poly, ib_poly) : null));
      }
    }

    if (zone_polys.length > 0 && utm_loc) {
      // recreate all boundaries
      for (const poly of zone_polys) {
        if (!poly) {
          continue;
        }
        const ob = Boundary.create();
        ob.boundary_id = this.getMission()?.assign_id() || 0;
        ob.poly_id = this.getMission()?.assign_id() || 0;
        ob.OuterZone_id = this.instance_id;
        ob.coordinates = poly.geometry.coordinates[0].map((coord) => [
          toLatLon(coord[0], coord[1], utm_loc.zoneNum, utm_loc.zoneLetter).latitude,
          toLatLon(coord[0], coord[1], utm_loc.zoneNum, utm_loc.zoneLetter).longitude,
        ]);

        for (const interior of poly.geometry.coordinates.slice(1)) {
          const ib = Boundary.create();
          ib.boundary_id = this.getMission()?.assign_id() || 0;
          ib.poly_id = ob.poly_id;
          ib.InnerZone_id = this.instance_id;
          ib.coordinates = interior.map((coord) => [
            toLatLon(coord[0], coord[1], utm_loc.zoneNum, utm_loc.zoneLetter).latitude,
            toLatLon(coord[0], coord[1], utm_loc.zoneNum, utm_loc.zoneLetter).longitude,
          ]);
        }
      }
    }
    // dispose all old boundaries
    for (const bnd of obs.concat(old_inner_boundaries)) {
      bnd.dispose();
    }
  }
}

export function to_feature(this: ZoneClass) {
  const features: KMLFeature<KMLZoneFeatureProperties, { coordinates: Coordinate[][] }>[] = []; //Feature<Polygon>[] = [];
  for (const outer_boundary of cleanSet(this.getOuterZoneBoundarys())) {
    const feature: KMLFeature<KMLZoneFeatureProperties, { coordinates: Coordinate[][] }> = {
      type: 'Feature',
      geometry: {
        type: GeometryType.POLYGON,
        coordinates: [] as Coordinate[][],
      },
      properties: {
        name: this.zone_name,
        visibility: true,
        poly_id: outer_boundary.poly_id,
        // TODO this seems to be a bug... but this is how I found it
        // is the zone_id really the poly_id?
        zone_id: outer_boundary.poly_id,
        generic_zone: this.zone_type === ZoneType.PULLIN,
        sample_zone: this.zone_type === ZoneType.SAMPLE_ZONE,
        zone_color: this.get_color_array(),
      },
    };

    if (this.zone_type === ZoneType.PULLIN) {
      delete feature.properties.sample_zone;
      delete feature.properties.zone_color;
    } else if (this.zone_type === ZoneType.SAMPLE_ZONE) {
      delete feature.properties.generic_zone;
    }

    // create outer boundary
    feature.geometry.coordinates!.push(
      outer_boundary.coordinates.map((coord) => convertProjection4329([coord[1], coord[0]])),
    );

    // create inner boundaries
    const orderedInnerBoundaries = cleanSet(
      orderBy(
        this.getInnerZoneBoundarys(),
        ['boundary_id'],
        ['asc'],
      ),
    );
    for (const inner_boundary of orderedInnerBoundaries) {
      feature.geometry.coordinates!.push(
        inner_boundary.coordinates.map((coord) => convertProjection4329([coord[1], coord[0]])),
      );
    }

    // add polygon to output list
    features.push(feature);
  }
  return features;
}

export async function new_zone(
  name: ZoneRecordingType | string,
  points: Coordinate[][],
  mission_id: number,
): Promise<[Zone, any[]]> {
  console.log(`zone_ops.ts:new_zone`);
  const mission = Mission.get(mission_id);
  if (!mission) {
    throw new Error(`Mission with id ${mission_id} not found`);
  }
  // TODO merge this with 'load_kml' to be maintained in one place
  let error_messages: string[] = [];
  if (['field', 'unsafe', 'slow', 'pullin_zone', 'pullin', 'collected field'].includes(name.toLowerCase())) {
    //name = name.toLowerCase();
    // if (name === 'pullin') {
    //   name = 'pullin_zone';
    // }
  }
  let zone = cleanSet(mission.getZones()).find((sel) => sel.zone_name === name);
  if (!zone) {
    // create a new zone
    zone = Zone.create();
    zone.Mission_id = mission.instance_id;
    zone.zone_name = name;
    if (name.toLowerCase() === 'field') {
      zone.zone_type = ZoneType.FIELD;
    } else if (name.toLowerCase() === 'unsafe') {
      zone.zone_type = ZoneType.UNSAFE;
    } else if (name.toLowerCase() === 'slow') {
      zone.zone_type = ZoneType.SLOW;
    } else if (name.toLowerCase() === 'pullin' || name.toLowerCase() === 'pullin_zone') {
      zone.zone_type = ZoneType.PULLIN;
    } else if (name.toLowerCase() === 'collected field') {
      zone.zone_type = ZoneType.COLLECTED_FIELD;
    } else if (name.match(/^[a-zA-Z0-9]+$/)) {
      zone.zone_type = ZoneType.SAMPLE_ZONE;
    } else {
      error_messages.push(`ERROR: cannot create unsupported zone '${name}`);
    }

    // if this zone has an alphanumeric name, create a sample zone
    // TOOD this feels complex, also it appears that only the boundary/zone collection
    // code calls this, so I don't think this code is ever hit?
    if (
      !['field', 'unsafe', 'slow', 'pullin', 'collected field', 'pullin_zone'].includes(name.toLowerCase()) &&
      name.match(/^[a-zA-Z0-9]+$/)
    ) {
      const sample_site = SampleSite.create();
      sample_site.original_sample_id = name;
      sample_site.Mission_id = mission.instance_id;
      const sample_zone = SampleZone.create();
      sample_zone.SampleSite_id = sample_site.instance_id;
      sample_zone.Zone_id = zone.instance_id;
    }
  }

  // load boundaries
  const poly_id = mission.assign_id() * -1; // multiply by -1 to avoid collisions later
  for (const [i, ring] of points.entries()) {
    const [, err] = await Boundary.new_boundary(ring, zone, i === 0, poly_id);
    error_messages = error_messages.concat(err);
  }

  // return loaded zone and error/warning messages
  return [zone, error_messages];
}

export function get_color_array(this: ZoneClass): [number, number, number] {
  /*
  Hash and extract value 0-15 for each color. Multiply by 8 and add 128.
  This produces a value in the range 0-255 for each color component.
  Resulting colors are dissimilar enough and no dark colors.
  */
  const h = md5(this.zone_name).slice(0, 3);
  const r = parseInt(h[0], 16) * 8 + 128;
  const g = parseInt(h[1], 16) * 8 + 128;
  const b = parseInt(h[2], 16) * 8 + 128;
  return [r, g, b];
}

export async function finish_zone(this: ZoneClass) {
  const mission = Mission.get(this.Mission_id);
  if (!mission) {
    return;
  }
  for (const poly_bnd of cleanSet(this.getOuterZoneBoundarys().filter((sel) => sel.poly_id < 0))) {
    const outerZone = poly_bnd.getOuterZone();
    if (!outerZone) {
      continue;
    }
    const ibs = cleanSet(outerZone.getInnerZoneBoundarys().filter((sel) => sel.poly_id === poly_bnd.poly_id));
    poly_bnd.poly_id = mission.assign_id();
    for (const ib of ibs) {
      ib.poly_id = poly_bnd.poly_id;
    }
  }
  await this.normalize();
}
