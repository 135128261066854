import Airtable from '../airtable';

import { AirtableRecord, AirtableSearch, SelectedRecord } from '../db';
import { AirtableRecordFields } from '../db/types';

import { cleanSet } from '../utils';

export async function search(tableName: string, viewName: string, query: string, refreshOnCreate = true) {
  // Search Airtable and cache the records and attachements
  // return an array of records found

  // find existing instance of this search. if none exists, create it
  let search: AirtableSearch | undefined = await AirtableSearch.findOne(
    (sel) => !!sel && sel.table === tableName && sel.view === viewName && sel.query === query,
  );
  if (!search) {
    // create a new search
    search = AirtableSearch.create();
    search.table = tableName;
    search.view = viewName;
    search.query = query;

    // refresh the search
    if (refreshOnCreate) {
      await search.refresh();
    }

    // TODO register for periodic sync
  }
}

export async function refresh<T extends AirtableRecordFields = AirtableRecordFields>(
  this: AirtableSearch<T>,
  { waitForAttachments = true } = {},
) {
  //console.log(`!!! airtable_search_ops.refresh(${waitForAttachments})`);
  if (!this.currentRefresh) this.currentRefresh = Promise.resolve();
  this.currentRefresh = new Promise<void>(async (res, rej) => {
    // don't allow starting one sync before other sync finishes
    try {
      await this.currentRefresh;
    } catch (e) {
      console.error('Error in previous refresh', e);
    }

    try {
      // search airtable for records
      const rs = await Airtable._select(this.table, this.view, this.query);

      // for each record that no longer matches the AT search, remove from local search
      const rIds = rs.map((r) => r.id);
      const disposes = [];
      for (const sr of cleanSet(this.getSelectedRecords())) {
        if (!rIds.includes(sr.record_id!)) {
          sr.dispose();
        }
      }

      // for each record from airtable, create or update a record
      for (const r of rs) {
        let record = cleanSet(this.getSelectedRecords().map((sr) => sr.get_AirtableRecord())).find(
          (sel) => sel?.id === r.id,
        );
        if (!record) {
          // see if the record exists in a different search
          record = AirtableRecord.findOne<AirtableRecord<T>>(
            (sel) => !!sel && sel.table === this.table && sel.id === r.id,
          );
          if (!record) {
            // create new record
            record = AirtableRecord.create();
            record.table = this.table;
            record.id = r.id;
            record.modified_fields = new Set<string>();
          }
          const selected_record = SelectedRecord.create();
          selected_record.AirtableRecord_id = record.instance_id;
          selected_record.AirtableSearch_id = this.instance_id;
        }

        // if ((record.fields['Name'] as string).includes("Newdale")) {
        //   console.log("I care about this one");
        // }

        // if a record isn't dirty, update it with the remote fields
        if (!record.dirty) {
          // @ts-ignore TODO translation between FieldSet (airtable library) and AirtableRecordFields (our types)
          record.fields = r.fields;

          // if a record is dirty, check if the modified fields conflict
          // if they don't, then update with the remote fields
        } else {
          // compared locally modified fields to remote fields
          // if they are the same, clear the dirty flag
          // if they are different, do nothing
          let different = false;
          for (const f of record.modified_fields) {
            // @ts-ignore TODO translation between FieldSet (airtable library) and AirtableRecordFields (our types)
            if (record.fields[f] !== r.fields[f]) {
              console.log(`skipping dirty record, instance_id: ${record._instance_id}`);
              different = true;
              break;
            }
          }
          if (different) continue;
          record.dirty = false;
          // @ts-ignore TODO translation between FieldSet (airtable library) and AirtableRecordFields (our types)
          record.fields = r.fields;
        }

        if (waitForAttachments) {
          await record.refresh_attachments();
        }
      }
    } catch (err) {
      console.log(err);
      rej(err);
    }
    res();
  });
  return this.currentRefresh;
}

export function getRecords<T extends AirtableRecordFields = AirtableRecordFields>(this: AirtableSearch<T>) {
  return cleanSet(this.getSelectedRecords().map((sr: SelectedRecord) => sr.get_AirtableRecord())).filter(
    (sel) => !!sel,
  );
}
