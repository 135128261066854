import React, { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MyAccordion from '../utils/MyAccordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import LinearProgress from '@material-ui/core/LinearProgress';
import Airtable from '../../airtable';
import Slider from '@material-ui/core/Slider';
import LoadingButton from '../utils/LoadingButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { debounce } from 'lodash';
import { generateColor, generateScheduledKey, updateState } from '../../utils';
import { alertError } from '../../alertDispatcher';
import { getCurrentSession } from '../../dataModelHelpers';
import JobList from './JobList';
import DropoffList from './DropoffList';
import { CustomCheckbox } from '../utils/CustomCheckbox';
import { AirtableRecord } from '../../db';
import { ScheduleMap, SchedulingRecordTypes } from '../../types/types';
import EventBus from '../../EventBus';
import _ from 'lodash';
import { Dropoffs, Jobs, Robot, ShiftDropoffs, Shifts, Team } from '@rogoag/airtable';
import { AirtableRecordFields } from '../../db/types';

interface SchedulingListProps {
  scheduled: ScheduleMap;
  filtered: AirtableRecord[];
  robotRefreshRate: number;
  showDropoffs: boolean;
  showJobs: boolean;
  selectedShift: string;
  loading: boolean;
  hiddenScheduled: string[];
  unassigned: AirtableRecord[];
  shiftDropoffs: AirtableRecord<ShiftDropoffs>[];
  allRobots: AirtableRecord<Robot>[];
  allDropoffs: AirtableRecord[];
  availableShifts: AirtableRecord[];
  toggleShowJobs: () => void;
  toggleShowDropoffs: () => void;
  updateZoomField: (zoomField: AirtableRecord) => void;
  updateScheduled: (scheduled: ScheduleMap) => void;
  updateUnassigned: (unassigned: AirtableRecord[]) => void;
  updateDisplayFilter: (filtered: AirtableRecord[]) => void;
  processScheduling: (unassigned: AirtableRecord[], scheduled: ScheduleMap) => Promise<void>;
  updateLoading: (loading: boolean) => void;
  updateAvailableShifts: (shifts: AirtableRecord<Shifts>[]) => void;
  updateShiftDropoffs: (dropoffs: AirtableRecord<ShiftDropoffs>[]) => void;
  updateAllRobots: (robots: AirtableRecord<Robot>[]) => void;
  updateAllDropoffs: (dropoffs: AirtableRecord<Dropoffs>[]) => void;
  updateHiddenScheduled: (hidden: string[]) => void;
  updateRobotRefreshRate: (rate: number) => void;
  updateSelectedShift: (shift: string) => void;
}

type FilterByLate = '<' | '>' | '==';
type FilterName = 'contains' | '!contains';

interface SchedulingListState {
  filterName: FilterName;
  filterByLate: FilterByLate;
  filterByPriority: string;
  filterTextName: string;
  nameFilter: boolean;
  dateFilter: boolean;
  lateFilter: boolean;
  usePriorityFilter: boolean;
  filterTextLate: string;
  isResizing: boolean;
  lastDownX: number;
  width: number;
  allOperators: AirtableRecord<Team>[];
  robot: string;
  operator: string;
  schedDate: string;
  nameFilterDropoff: boolean;
  dropoffContainsFilter: FilterName;
  filterDropoffName: string;
}

export default class SchedulingList extends PureComponent<SchedulingListProps, SchedulingListState> {
  FILTER_KEYS = [
    'dateFilter',
    'nameFilter',
    'lateFilter',
    'filterByLate',
    'filterName',
    'filterTextLate',
    'filterTextName',
    'filterDropoffName',
    'dropoffContainsFilter',
    'nameFilterDropoff',
    'usePriorityFilter',
    'filterByPriority',
  ];

  SCHEDULE_VIEW_NAME = 'App View - Schedule Jobs';
  DROPOFF_VIEW_NAME = 'App View - Scheduled Dropoffs';
  container: React.RefObject<HTMLDivElement>;

  constructor(props: SchedulingListProps) {
    super(props);
    const prevWidth = localStorage.getItem('scheduleWidth');
    this.state = {
      filterName: 'contains',
      filterByLate: '==',
      filterTextName: '',
      filterByPriority: '',
      nameFilter: false,
      dateFilter: false,
      lateFilter: false,
      usePriorityFilter: false,
      filterTextLate: '',
      isResizing: false,
      lastDownX: 0,
      width: prevWidth ? parseInt(prevWidth) : 400,
      allOperators: [],
      robot: '',
      operator: '',
      schedDate: '',
      nameFilterDropoff: false,
      dropoffContainsFilter: 'contains',
      filterDropoffName: '',
    };
    this.container = React.createRef();
  }

  async componentDidMount() {
    await this.refreshJobs();
    EventBus.on('SCHEDULING:REFRESH_JOBS', this.refreshJobs);
    document.addEventListener('mousemove', (e) => this.handleMousemove(e));
    document.addEventListener('mouseup', () => this.handleMouseup());
  }

  componentWillUnmount(): void {
    EventBus.remove('SCHEDULING:REFRESH_JOBS', this.refreshJobs);
  }

  componentDidUpdate(prevProps: SchedulingListProps, prevState: SchedulingListState) {
    this.FILTER_KEYS.forEach((key) => {
      if (this.state[key] !== prevState[key] && prevState) {
        this.applyFilters();
      }
    });

    if (this.props.showDropoffs !== prevProps.showDropoffs) {
      this.applyFilters();
    }

    if (this.props.showJobs !== prevProps.showJobs) {
      this.applyFilters();
    }

    if (
      JSON.stringify(this.props.unassigned) !== JSON.stringify(prevProps.unassigned) ||
      JSON.stringify(this.props.scheduled) !== JSON.stringify(prevProps.scheduled)
    ) {
      this.applyFilters();
    }
  }

  async queryJobsTable(forceRefresh: boolean, viewName: string, query?: string) {
    try {
      const search = await Airtable.search<Jobs>('Jobs', viewName, query, false);
      if (forceRefresh || search.getRecords().length === 0) {
        try {
          await search.refresh({ waitForAttachments: false });
        } catch (err) {
          console.error(`Could not refresh airtable Jobs.`, err);
          return undefined;
        }
      }
      return search.getRecords();
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  refreshJobs = async () => {
    const session = getCurrentSession();
    if (session) {
      this.props.updateLoading(true);
      const jobs = await this.queryJobsTable(true, this.SCHEDULE_VIEW_NAME); // query jobs table
      // For testing purposes, we can use the following line - otherwise loading all jobs is too slow
      //const jobs = await this.queryJobsTable(true, this.SCHEDULE_VIEW_NAME, `SEARCH("F24-Kerbau", {name})`); 

      // these are just individual AirTable queries and thus could be executed in parallel...
      await Promise.all([
        this.loadShifts(),
        this.loadShiftDropoffs(),
        this.loadOperators(),
        this.loadRobots(),
        this.loadDropoffLocations(),
      ]);
      if (jobs) {
        await this.structureJobs(jobs); // structure jobs for processing
      }
      await this.applyFilters();
      this.props.updateLoading(false);
    }
  };

  async loadShiftDropoffs() {
    const search = await Airtable.search<ShiftDropoffs>('Shift Dropoffs', this.DROPOFF_VIEW_NAME);
    await search.refresh();
    const shiftDropoffs = search.getRecords();
    this.props.updateShiftDropoffs(shiftDropoffs);
  }

  async loadShifts() {
    const search = await Airtable.search<Shifts>('Shifts', 'App View - Valid Schedule Shifts');
    await search.refresh();
    const availableShifts = search.getRecords();
    this.props.updateAvailableShifts(availableShifts);
  }

  async loadDropoffLocations() {
    const search = await Airtable.search<Dropoffs>('Dropoffs');
    await search.refresh();
    const allDropoffs = search.getRecords();
    this.props.updateAllDropoffs(allDropoffs);
  }

  async loadRobots() {
    const search = await Airtable.search<Robot>('Robot');
    await search.refresh();

    let allRobots = search.getRecords();
    allRobots = allRobots.filter((robot) => robot.get('Name')?.includes('K')); // Only get Kubotas
    allRobots = allRobots.sort((a, b) => {
      const aName = a.get('Name');
      const bName = b.get('Name');
      if (typeof aName === 'undefined') {
        return 1;
      }

      if (typeof bName === 'undefined') {
        return -1;
      }

      return Number(aName.replace(/\D/g, '')) - Number(bName.replace(/\D/g, ''));
    });

    const robotColorMap = {};
    allRobots.forEach((robot) => {
      const robotName = robot.get('Name') ?? '';
      robotColorMap[robotName] = generateColor(robotName);
    });

    this.props.updateAllRobots(allRobots);
  }

  async loadOperators() {
    const search = await Airtable.search<Team>('Team', 'Valid Operators');
    await search.refresh();
    const allOperators = search.getRecords().sort((a, b) => a.get('Name')!.localeCompare(b.get('Name')!));
    this.setState({ allOperators });
  }

  async createShift() {
    const selectedOperator = this.state.operator;
    const selectedRobot = this.state.robot;
    const schedDate = this.state.schedDate;
    if (!selectedOperator || !schedDate || schedDate === '' || !selectedRobot) {
      alertError('Did not input all fields');
      return;
    }
    const robotObj = this.props.allRobots.filter((robot) => robot.get('Name') === selectedRobot);
    const robotId = [robotObj[0].id];

    const operatorObj = this.state.allOperators.filter((operator) => operator.get('Name') === selectedOperator);
    const operatorId = [operatorObj[0].id];

    const newShift = await Airtable.createRecord('Shifts', {
      'Scheduled Robot': robotId,
      Operator: operatorId,
      'Sched Date': schedDate,
    });

    this.props.scheduled[generateScheduledKey(schedDate, selectedRobot, selectedOperator)] = [];
    const availableShifts = this.props.availableShifts.slice();
    availableShifts.push(newShift);
    this.props.updateAvailableShifts(availableShifts);
    this.props.updateScheduled(this.props.scheduled);

    this.setState({ schedDate: '', operator: '', robot: '' });
  }

  handleJobs(jobs: AirtableRecord<Jobs>[], scheduled: ScheduleMap, unassigned: AirtableRecord[]) {
    jobs.forEach((job) => {
      const isScheduled = job.get('Scheduled?');
      if (isScheduled) {
        // if assigned, we need to organize it by robot and schedule date
        const robot = job.get('Robot');
        const schedDate = job.get('Sched Date')[0];
        const operatorName = job.get('Operator Name')[0];
        const key = generateScheduledKey(schedDate, robot ?? '', operatorName);
        if (key in scheduled) {
          // @ts-ignore this is complaining about incosistencies between Jobs and Types, so might need to retype this...
          scheduled[key].push(job);
        }
      } else {
        unassigned.push(job); // if unassigned no structure needed
      }
    });

    return { scheduled: scheduled, unassigned: unassigned };
  }

  handleShiftDropoffs(scheduled: ScheduleMap, unassigned) {
    this.props.shiftDropoffs.forEach((dropoff) => {
      const robot = dropoff.get('Robot') ? dropoff.get('Robot')[0] : '';
      const schedDate = dropoff.get('Sched Date') ? dropoff.get('Sched Date')[0] : '';
      const operatorName = dropoff.get('Operator Name') ? dropoff.get('Operator Name')[0] : '';
      const key = generateScheduledKey(schedDate, robot, operatorName);
      if (scheduled[key]) {
        scheduled[key].push(dropoff);
      }
    });

    return { scheduled: scheduled, unassigned: unassigned };
  }

  handleShifts() {
    const scheduled: ScheduleMap = {};
    this.props.availableShifts.forEach((shift) => {
      // lookups/links return arrays
      const robot = shift.get('Robot')[0];
      const schedDate = shift.get('Sched Date');
      const operatorName = shift.get('Operator Name')[0];

      scheduled[generateScheduledKey(schedDate, robot, operatorName)] = [];
    });

    return scheduled;
  }

  async structureJobs(jobs: AirtableRecord<Jobs>[]) {
    let unassigned: AirtableRecord[] = [];
    let scheduled = this.handleShifts(); // initialize the possible shifts for jobs
    const jobsAddedObj = this.handleJobs(jobs, scheduled, unassigned);
    scheduled = jobsAddedObj.scheduled;
    unassigned = jobsAddedObj.unassigned;
    // We pull shift dropoffs but we only want to show them if the shift is within the valid range
    const shiftDropoffsAdded = this.handleShiftDropoffs(scheduled, unassigned);
    scheduled = shiftDropoffsAdded.scheduled;
    unassigned = shiftDropoffsAdded.unassigned;
    // we want dropoffs(not shift dropoffs) to be apart of the unassigned array
    unassigned = unassigned.concat(this.props.allDropoffs);
    // Airtable does not pull in order, we have to order it
    Object.keys(scheduled).forEach((key) => {
      scheduled[key] = scheduled[key].sort((a, b) => {
        const aOrder = a.get('Order');
        const bOrder = b.get('Order');

        if (typeof aOrder === 'undefined') {
          return 1;
        }

        if (typeof bOrder === 'undefined') {
          return -1;
        }

        // @ts-ignore TODO need to figure out how to type this correctly
        return aOrder - bOrder;
      });
    });

    await this.props.processScheduling(unassigned, scheduled); // kick off processing boundaries in the db worker
    this.props.updateUnassigned(unassigned);
    this.props.updateScheduled(scheduled);
  }

  filterScheduled(scheduled, filterFunc) {
    for (let key of scheduled.keys()) {
      scheduled.set(key, filterFunc(scheduled[key]));
    }
    return scheduled;
  }

  filterName(filterText: string, filterContains: FilterName, unassigned: SchedulingRecordTypes[], table: string) {
    filterText = filterText.toLowerCase();
    const contains = filterContains === 'contains';
    if (contains) {
      unassigned = unassigned.filter((job) => {
        if (job.table !== table) {
          return true;
        }
        const lowercaseName = job.get('Name')?.toLowerCase() || '';
        return lowercaseName.includes(filterText);
      });
    } else {
      unassigned = unassigned.filter((job) => {
        if (job.table !== table) {
          return true;
        }
        const lowercaseName = job.get('Name').toLowerCase();
        return !lowercaseName.includes(filterText);
      });
    }
    return unassigned;
  }

  filterUnassignedDate(unassigned) {}

  filterByPriority(filterText: string, unassigned: AirtableRecord<AirtableRecordFields>[]) {
    unassigned = unassigned.filter((job) => {
      if (job.table !== 'Jobs') {
        return true;
      }
      return job.get('Priority Final') === filterText;
    });
    return unassigned;
  }

  filterLate(filterText: string, filterByLate: FilterByLate, unassigned: SchedulingRecordTypes[], table: string) {
    if (filterByLate === '==') {
      unassigned = unassigned.filter((job) => {
        if (job.table !== table) {
          return true;
        }
        return job.get('Late (days)') === parseInt(filterText);
      });
    } else if (filterByLate === '>') {
      unassigned = unassigned.filter((job) => {
        if (job.table !== table) {
          return true;
        }
        // @ts-ignore TODO need to figure out how to type this correctly
        return job.get('Late (days)') > parseInt(filterText);
      });
    } else {
      unassigned = unassigned.filter((job) => {
        if (job.table !== table) {
          return true;
        }
        // @ts-ignore TODO need to figure out how to type this correctly
        return job.get('Late (days)') < parseInt(filterText);
      });
    }
    return unassigned;
  }

  async updateFilter(stateKey: keyof SchedulingListState, value: string | boolean) {
    this.setState(updateState(stateKey, value));
    //this.setState({ [stateKey]: value });
  }

  applyFilters() {
    let unassigned = this.props.unassigned.slice();

    if (this.state.nameFilter && this.state.filterTextName) {
      unassigned = this.filterName(this.state.filterTextName, this.state.filterName, unassigned, 'Jobs');
    }

    if (this.state.nameFilterDropoff && this.state.filterDropoffName) {
      unassigned = this.filterName(
        this.state.filterDropoffName,
        this.state.dropoffContainsFilter,
        unassigned,
        'Dropoffs',
      );
    }

    if (this.state.lateFilter && this.state.filterTextLate) {
      unassigned = this.filterLate(this.state.filterTextLate, this.state.filterByLate, unassigned, 'Jobs');
    }

    if (this.state.usePriorityFilter && this.state.filterByPriority) {
      unassigned = this.filterByPriority(this.state.filterByPriority, unassigned);
    }

    if (!this.props.showDropoffs) {
      unassigned = unassigned.filter((job) => job.table !== 'Dropoffs');
    }

    if (!this.props.showJobs) {
      unassigned = unassigned.filter((job) => job.table !== 'Jobs');
    }

    this.props.updateDisplayFilter(unassigned);
  }

  handleMousedown(e: React.MouseEvent<HTMLDivElement>) {
    this.setState({ isResizing: true, lastDownX: e.clientX });
  }

  handleMousemove(e: MouseEvent) {
    // we don't want to do anything if we aren't resizing.
    if (!this.state.isResizing) {
      return;
    }

    const maxWidth = 1500;
    const minWidth = 0;
    const threshold = 10;
    if (
      e.x < maxWidth &&
      e.x > minWidth &&
      (e.x - threshold < this.state.width || e.x + threshold > this.state.width)
    ) {
      this.setState({ width: e.x });
    }
  }

  handleMouseup() {
    this.setState({ isResizing: false });
    localStorage.setItem('scheduleWidth', this.state.width.toString());
  }

  sortObject(obj) {
    return Object.keys(obj)
      .sort()
      .reduce(function (result, key) {
        result[key] = obj[key];
        return result;
      }, {});
  }

  render() {
    let unassigned = this.props.filtered;
    let scheduled = { ...this.props.scheduled };
    scheduled = this.sortObject(scheduled);

    const dropoffs = unassigned.filter((job) => job.table === 'Dropoffs');
    unassigned = unassigned.filter((job) => job.table === 'Jobs');

    let totalAcresUnassigned = 0;
    unassigned.forEach((job) => {
      totalAcresUnassigned += job.get('Boundary Acres') as number;
    });

    return (
      <div
        style={{
          height: '100%',
          minHeight: 0,
          bottom: '0px',
          maxWidth: this.state.width,
        }}
        ref={this.container}
      >
        <Grid
          item
          style={{
            flexDirection: 'row',
            display: 'flex',
            flex: 1,
            height: '100%',
            minHeight: 0,
          }}
        >
          <Paper
            elevation={3}
            style={{ maxHeight: 'calc(100vh - 88px)', minHeight: '100%', overflowY: 'scroll' }}
            className={'scroll'}
          >
            {this.props.loading ? <LinearProgress style={{ marginBottom: 20 }} /> : null}
            <Grid item container style={{ flexDirection: 'row', display: 'flex' }} xs={12}>
              <Grid item md={12} justify="flex-end" style={{ display: 'flex', marginRight: 20, marginTop: 10 }}>
                <IconButton color="primary" onClick={this.refreshJobs} size="small" disabled={this.props.loading}>
                  <RefreshIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ flexDirection: 'row', display: 'flex', paddingBottom: 10 }}
              justifyContent="center"
            >
              <FormControl>
                <FormControlLabel
                  label="Show Jobs"
                  control={
                    <CustomCheckbox
                      onChange={() => this.props.toggleShowJobs()}
                      checked={this.props.showJobs}
                      style={{ marginLeft: 2.5 }}
                    />
                  }
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  label="Show Dropoffs"
                  control={<CustomCheckbox onChange={this.props.toggleShowDropoffs} style={{ marginLeft: 2.5 }} />}
                />
              </FormControl>
            </Grid>
            <Grid container justifyContent="center" style={{ padding: 10 }}>
              <Typography gutterBottom>Robot Refresh Rate</Typography>
              <Slider
                style={{ width: '80%' }}
                defaultValue={30}
                step={5}
                value={this.props.robotRefreshRate}
                valueLabelDisplay="auto"
                min={10}
                max={60}
                color="secondary"
                onChange={debounce((e, value) => {
                  this.props.updateRobotRefreshRate(value);
                }, 10)}
              />
            </Grid>
            {this.props.showDropoffs && (
              <Grid container>
                <Grid
                  container
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginBottom: 20,
                  }}
                  justifyContent="center"
                >
                  <TextField
                    label="Dropoff Name"
                    style={{ width: '25%' }}
                    onBlur={(event) => this.setState({ filterDropoffName: event.target.value })}
                  />
                  <Select
                    value={this.state.dropoffContainsFilter}
                    onChange={async (event: React.ChangeEvent<{ value: unknown }>) =>
                      this.setState({ dropoffContainsFilter: event.target.value as FilterName })
                    }
                    style={{ marginLeft: 25, marginRight: 25, width: '25%' }}
                    autoWidth
                  >
                    <MenuItem value="contains">contains</MenuItem>
                    <MenuItem value="!contain">!contain</MenuItem>
                  </Select>
                  <CustomCheckbox
                    checked={this.state.nameFilterDropoff}
                    onChange={() => this.setState({ nameFilterDropoff: !this.state.nameFilterDropoff })}
                    style={{ marginLeft: 2.5 }}
                  />
                </Grid>
              </Grid>
            )}
            {this.props.showJobs && (
              <Grid container>
                <Grid
                  container
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginBottom: 20,
                  }}
                  justifyContent="center"
                >
                  <TextField
                    label="Job Name"
                    style={{ width: '25%' }}
                    onBlur={(event) => this.setState({ filterTextName: event.target.value })}
                  />
                  <Select
                    value={this.state.filterName}
                    onChange={async (event: React.ChangeEvent<{ value: unknown }>) =>
                      this.setState({ filterName: event.target.value as FilterName })
                    }
                    style={{ marginLeft: 25, marginRight: 25, width: '25%' }}
                    autoWidth
                  >
                    <MenuItem value="contains">contains</MenuItem>
                    <MenuItem value="!contain">!contain</MenuItem>
                  </Select>
                  <CustomCheckbox
                    checked={this.state.nameFilter}
                    onChange={() => this.updateFilter('nameFilter', !this.state.nameFilter)}
                    style={{ marginLeft: 2.5 }}
                  />
                </Grid>
                <Grid
                  container
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginBottom: 20,
                  }}
                  justifyContent="center"
                >
                  <TextField
                    label="Days Late"
                    style={{ width: '25%' }}
                    onBlur={(event) => {
                      this.setState({ filterTextLate: event.target.value });
                    }}
                    inputMode="numeric"
                  />
                  <Select
                    value={this.state.filterByLate}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      this.updateFilter('filterByLate', event.target.value as FilterByLate)
                    }
                    style={{ marginLeft: 25, marginRight: 25, width: '25%' }}
                    autoWidth
                  >
                    <MenuItem value=">">{'<'}</MenuItem>
                    <MenuItem value="<">{'>'}</MenuItem>
                    <MenuItem value="==">{'=='}</MenuItem>
                  </Select>
                  <CustomCheckbox
                    checked={this.state.lateFilter}
                    onChange={() => this.updateFilter('lateFilter', !this.state.lateFilter)}
                    style={{ marginLeft: 2.5 }}
                  />
                </Grid>
                <Grid
                  container
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginBottom: 20,
                  }}
                  justifyContent="center"
                >
                  <Select
                    value={this.state.filterByPriority}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      this.updateFilter('filterByPriority', event.target.value as string)
                    }
                    style={{ marginLeft: 25, marginRight: 25, width: '25%' }}
                    autoWidth
                  >
                    {_(this.props.unassigned)
                      .filter((jobOrDropoff) => jobOrDropoff.table === 'Jobs')
                      .map((job) => job.get('Priority Final'))
                      .uniq()
                      .map((priority) => <MenuItem value={priority}>{priority}</MenuItem>)
                      .value()}
                  </Select>
                  <CustomCheckbox
                    checked={this.state.usePriorityFilter}
                    onChange={() => this.updateFilter('usePriorityFilter', !this.state.usePriorityFilter)}
                    style={{ marginLeft: 2.5 }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container style={{ marginBottom: 2 }}>
              <MyAccordion style={{ width: '100%' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body1" style={{ padding: 5 }}>
                    Create Shift
                  </Typography>
                </AccordionSummary>
                <Grid
                  container
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    padding: 20,
                    borderTop: '1px solid grey',
                  }}
                  justifyContent="center"
                >
                  <TextField
                    label="Sched Date"
                    type="date"
                    style={{ width: '100%' }}
                    value={this.state.schedDate}
                    onChange={(e) => {
                      this.setState({ schedDate: e.target.value });
                    }}
                    defaultValue={undefined}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormGroup row style={{ marginTop: 20, width: '100%' }}>
                    <FormControl style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '45%' }}>
                      <InputLabel id="label" shrink>
                        Robot
                      </InputLabel>
                      <Select
                        labelId="label"
                        value={this.state.robot}
                        defaultValue={null}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          this.setState({ robot: event.target.value as string });
                        }}
                        style={{ width: '100%' }}
                      >
                        {this.props.allRobots.map((robot) => (
                          <MenuItem key={robot.get('Name')} value={robot.get('Name')}>
                            {robot.get('Name')}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl style={{ marginLeft: '2.5%', marginRight: '2.5%', width: '45%' }}>
                      <InputLabel id="operator" shrink>
                        Operator
                      </InputLabel>
                      <Select
                        value={this.state.operator}
                        defaultValue={null}
                        labelId="operator"
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          this.setState({ operator: event.target.value as string });
                        }}
                        style={{ width: '100%' }}
                      >
                        {this.state.allOperators.map((operator) => (
                          <MenuItem key={operator.get('Name')} value={operator.get('Name')}>
                            {operator.get('Name')}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>

                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: 20 }}
                    onClick={this.createShift.bind(this)}
                  >
                    Create
                  </LoadingButton>
                </Grid>
              </MyAccordion>
            </Grid>
            {this.props.showDropoffs && (
              <Grid
                container
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  marginBottom: 2,
                }}
              >
                <MyAccordion style={{ width: '100%' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" style={{ padding: 5 }}>{`Dropoffs -- ${dropoffs.length}`}</Typography>
                  </AccordionSummary>

                  <DropoffList list={dropoffs} updateZoomField={this.props.updateZoomField} />
                </MyAccordion>
              </Grid>
            )}
            {this.props.showJobs && (
              <Grid
                container
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                }}
              >
                <Grid container style={{ marginBottom: 2 }}>
                  <MyAccordion style={{ width: '100%' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <FormControlLabel
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        label={`Unassigned -- ${Math.round(totalAcresUnassigned)} Acres -- ${unassigned.length} Jobs`}
                        control={
                          <CustomCheckbox
                            checked={this.props.selectedShift === 'Unassigned'}
                            style={{ marginRight: 5 }}
                            onChange={() => {
                              this.props.selectedShift !== 'Unassigned' && this.props.updateSelectedShift('Unassigned');
                            }}
                          />
                        }
                      />
                    </AccordionSummary>

                    <JobList list={unassigned} updateZoomField={this.props.updateZoomField} />
                  </MyAccordion>
                </Grid>

                {Object.keys(scheduled).map((key) => {
                  let totalAcres = 0;
                  let numDropoffs = 0;
                  scheduled[key].forEach((job) => {
                    // TODO can we figure out how to type this correctly?...
                    if (job.table === 'Jobs') {
                      // @ts-ignore TODO need to figure out how to type this correctly
                      totalAcres += job.get('Boundary Acres');
                    } else {
                      numDropoffs += 1;
                    }
                  });
                  let isVisible = this.props.hiddenScheduled.filter((hiddenKey) => hiddenKey === key).length
                    ? true
                    : false;
                  const [date, robot, operator] = key.split('/');
                  //const dateObj = new Date(Date.parse(date));
                  const labelText = `${date} <em>${robot}</em> <b>${operator}</b> -- <b>${Math.round(totalAcres)}</b> Acres, <b>${scheduled[key].length - numDropoffs}</b> Jobs, <b>${numDropoffs}</b> Dropoffs`;
                  const label = (
                    <Typography align="left">
                      <span key={key} dangerouslySetInnerHTML={{ __html: labelText }}></span>
                    </Typography>
                  );

                  return (
                    <Grid key={key} container style={{ marginBottom: 2 }}>
                      <MyAccordion style={{ width: '100%' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <FormControlLabel
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            style={{ marginRight: 5 }}
                            label={label}
                            control={
                              <CustomCheckbox
                                checked={this.props.selectedShift === key}
                                onChange={() => {
                                  this.props.selectedShift !== key && this.props.updateSelectedShift(key);
                                }}
                              />
                            }
                          />
                          <Grid container justifyContent="center" alignContent="center" style={{ flex: 0 }}>
                            <IconButton
                              size="small"
                              style={{ maxHeight: '20px' }}
                              onClick={(event) => {
                                event.stopPropagation();
                                let hiddenScheduled = this.props.hiddenScheduled.slice();
                                if (isVisible) {
                                  hiddenScheduled = hiddenScheduled.filter((hiddenKey) => hiddenKey !== key);
                                } else {
                                  hiddenScheduled.push(key);
                                }
                                this.props.updateHiddenScheduled(hiddenScheduled);
                              }}
                            >
                              {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </Grid>
                        </AccordionSummary>
                        <JobList list={scheduled[key]} updateZoomField={this.props.updateZoomField} />
                      </MyAccordion>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Paper>
          <div
            id="dragger"
            onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
              this.handleMousedown(event);
            }}
            className={'dragger'}
          />
        </Grid>
      </div>
    );
  }
}
