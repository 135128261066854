import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { OnChangeValue } from 'react-select/dist/declarations/src';

type OptionType = {
  value: string;
  label: string;
};

const options: OptionType[] = [
  { value: 'Camera issue', label: 'Camera issue' },
  { value: 'Already shipped', label: 'Already shipped' },
];

type Props = {
  excuse: string;
  onExcuseChange: (excuse: string | undefined) => void;
};

export default function NoScanOrPicExcuse({ onExcuseChange, excuse }: Props) {
  return (
    <CreatableSelect
      onChange={(newValue: OnChangeValue<OptionType, false>) => onExcuseChange(newValue?.value)}
      value={{ value: excuse, label: excuse }}
      isClearable
      options={options}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
    />
  );
}
