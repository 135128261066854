import React, { PureComponent } from 'react';
import { IconButton } from '@material-ui/core';
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType } from '@zxing/library';

import CloseIcon from '@material-ui/icons/Close';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import PersonIcon from '@material-ui/icons/Person';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';

import { alertError } from '../../alertDispatcher';
import { FacingMode } from '../../types/types';

interface BarcodeScannerProps {
  closeScanner: () => void;
  facingMode?: FacingMode;
  fullScreen: boolean;
  handleLoadFailure: (err?: unknown) => void;
  onCanPlay?: () => void;
  progressBar?: JSX.Element[];
  sampleScanner: boolean;
  type: BarcodeFormat[];
  updateDetectedCode: (text: string, rawBytes: ArrayBuffer | File | string) => Promise<void>;
}

interface BarcodeScannerState {
  canvasSrc: string | undefined;
  facingMode: FacingMode;
  videoRef: React.RefObject<HTMLVideoElement> | null;
  canPlay?: boolean;
}

export default class BarcodeScanner extends PureComponent<BarcodeScannerProps, BarcodeScannerState> {
  reader: BrowserMultiFormatReader;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  alreadyScanned: any[];
  stream: MediaStream;

  constructor(props: BarcodeScannerProps) {
    super(props);
    const hints = new Map<DecodeHintType, BarcodeFormat[]>();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, props.type);
    this.reader = new BrowserMultiFormatReader(hints);
    this.canvasRef = React.createRef();
    this.state = {
      canvasSrc: undefined,
      facingMode: this.getStoredDirection(),
      videoRef: null,
    };
    this.alreadyScanned = [];
    this.handleVideo = this.handleVideo.bind(this);
    this.switchCamera = this.switchCamera.bind(this);
    this.initializeVideo = this.initializeVideo.bind(this);
    this.uninitializeVideo = this.uninitializeVideo.bind(this);
    this.handleLoadFailure = this.handleLoadFailure.bind(this);
    this.onCanPlay = this.onCanPlay.bind(this);
  }

  componentDidMount() {
    this.initializeVideo();

    const facingMode = this.getStoredDirection();
    this.setState({ facingMode });
  }

  componentWillUnmount() {
    this.reader.stopContinuousDecode();
    this.uninitializeVideo();
    this.setState({ videoRef: null });
  }

  getStoredDirection() {
    const storedDirection = JSON.parse(
      localStorage.getItem(`CameraDirection:${this.props.sampleScanner ? 'Sample' : 'QR'}`) ||
        `{"facingMode": "${this.props.facingMode}"}` ||
        '{"facingMode": "environment"}',
    );
    return storedDirection.facingMode;
  }

  setStoredDirection() {
    localStorage.setItem(
      `CameraDirection:${this.props.sampleScanner ? 'Sample' : 'QR'}`,
      `{"facingMode": "${this.state.facingMode}"}`,
    );
  }

  /**
   *
   * @param {unknown} err Handle if the video fails to load
   */
  handleLoadFailure(err: unknown) {
    alertError('Something went wrong while loading video!');
    console.error(err);

    this.props.handleLoadFailure(err);
  }

  /**
   * Captures screenshot of video to display to user as the camera switches
   */
  takePicture() {
    const canvas = this.canvasRef.current as HTMLCanvasElement;
    const video = this.state.videoRef?.current;
    if (video && canvas) {
      const context = canvas.getContext('2d');
      if (!context) return;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      context.drawImage(video, 0, 0, window.innerWidth, window.innerHeight);
    }
  }

  /**
   * Switch camera view, either to environment or user
   */
  switchCamera() {
    this.takePicture();
    this.reader.stopContinuousDecode();
    this.reader.reset();
    this.uninitializeVideo();
    this.setState(
      (prevState) => ({
        facingMode: prevState.facingMode === 'user' ? 'environment' : 'user',
        canPlay: false,
      }),
      async () => {
        await this.initializeVideo();
        this.setStoredDirection();
      },
    );
  }

  /**
   * Init video, get user camera and add event handler or call event handler
   */
  async initializeVideo() {
    this.setState({ videoRef: React.createRef() });
    const constraints = { video: { facingMode: this.state.facingMode }, audio: false };
    try {
      await this.handleVideo(await navigator.mediaDevices.getUserMedia(constraints));
    } catch (err: unknown) {
      this.handleLoadFailure(err);
    }
  }

  /**
   * Uninit video, stop all tracks from a steam and set the videoRef to null
   */
  uninitializeVideo() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }

    this.setState({ videoRef: null });
  }

  async handleVideo(stream: MediaStream) {
    this.stream = stream;
    const tracks = this.stream.getTracks();
    if (tracks.length) {
      const track = tracks[0];
      await track.applyConstraints();
    }
    if (this.state.videoRef && this.state.videoRef.current) {
      // TODO we can probably do this better like PhotoCapture is doing
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.videoRef.current.srcObject = stream;
    }
  }

  onCanPlay() {
    this.setState({ canPlay: true });
    if (typeof this.props.onCanPlay === 'function') {
      this.props.onCanPlay();
    }
    this.scanLoop();
  }

  async scanLoop() {
    const videoRef = this.state.videoRef;
    if (!videoRef) {
      return;
    }
    this.reader.decodeFromVideoElementContinuously(videoRef.current!, async (resultDecode) => {
      if (resultDecode) {
        try {
          console.log(resultDecode);
          let canvas = document.createElement('canvas');
          canvas.width = 1920;
          canvas.height = 1080;

          let ctx = canvas.getContext('2d');
          ctx?.drawImage(videoRef.current!, 0, 0, canvas.width, canvas.height);
          let image = canvas.toDataURL('image/jpeg');
          await this.props.updateDetectedCode(resultDecode.getText(), image);
        } catch (err) {
          console.error(err);
        }
      }
    });
  }

  render() {
    const width = this.props.fullScreen ? '100%' : '200px';
    const height = this.props.fullScreen ? '100vh' : '200px';
    const position = this.props.fullScreen ? 'absolute' : 'relative';
    const zIndex = this.props.sampleScanner ? 50 : 1500;
    return (
      <div style={{ height: height, width: width, position: position, zIndex: zIndex }}>
        {this.state.canPlay ? (
          <React.Fragment key={'BarcodeScanner'}>
            {this.props.progressBar && this.props.progressBar}

            {this.props.fullScreen && this.state.videoRef && (
              <div
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: 'white',
                  opacity: '25%',
                  borderWidth: 2,
                  borderColor: 'black',
                  borderStyle: 'solid',
                  paddingRight: 300,
                  paddingLeft: 300,
                  paddingTop: 75,
                  paddingBottom: 75,
                }}
              />
            )}
            <IconButton
              onClick={this.props.closeScanner}
              style={{ position: 'absolute', right: 10, zIndex: 200, color: 'white' }}
            >
              <CloseIcon fontSize={'large'} />
            </IconButton>

            <IconButton onClick={this.switchCamera} style={{ position: 'absolute', zIndex: 200, color: 'white' }}>
              <FlipCameraIosIcon fontSize={'large'} />
            </IconButton>
          </React.Fragment>
        ) : this.state.facingMode === 'user' ? (
          <PersonIcon
            fontSize={'large'}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
            }}
          />
        ) : (
          <FilterHdrIcon
            fontSize={'large'}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
            }}
          />
        )}
        <canvas
          ref={this.canvasRef}
          style={{
            display: this.state.canPlay ? 'none' : '',
            width: width,
            height: height,
            objectFit: 'fill',
            backgroundColor: 'black',
          }}
        />
        {this.state.videoRef && (
          <video
            ref={this.state.videoRef}
            id={`video${this.props.sampleScanner}`}
            style={{
              display: this.state.canPlay ? '' : 'none',
              width: width,
              height: height,
              objectFit: 'fill',
            }}
            playsInline={true}
            autoPlay={true}
            controls={false}
            onCanPlay={this.onCanPlay}
          />
        )}
      </div>
    );
  }
}
