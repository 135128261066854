import { AirtableRecord } from '../../db';
import { Box } from '@rogoag/airtable';
import { Card, CardContent, Grid, Typography, Button } from '@material-ui/core';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { Tick } from 'react-crude-animated-tick';
import TakePhotoOrUpload from './TakePhotoOrUpload';
import NoScanOrPicExcuse from './NoScanOrPicExcuse';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  warningButton: {
    color: '#ff9800', // orange
    borderColor: '#ff9800',
    '&:hover': {
      backgroundColor: 'rgba(255,152,0,0.08)', // light orange hover
      borderColor: '#ff9800',
    },
  },
}));

interface Props {
  box: AirtableRecord<Box>;
  excludedBoxes: string[];
  expandedBoxes: string[];
  group: any;
  dropoffName: string;
  collapseBox: (boxId: string) => void;
  expandBox: (boxId: string) => void;
  excludeBox: (boxId: string) => void;
  scannedBoxIds: string[];
  cameraError: string;
  scannedBoxPicture?: string;
  activateCaptureModal: () => void;
  onUploadedBoxPictureChange: (boxId: string, file: File) => Promise<void>;
  loading: boolean;
  captureLoading: boolean;
  noScanOrPicExcuse?: string;
  onNoScanOrPicExcuseChange: (excuse: string | undefined) => void;
}

export default function ShippingGroupBoxCard({
  box,
  excludedBoxes,
  group,
  dropoffName,
  excludeBox,
  scannedBoxIds,
  cameraError,
  scannedBoxPicture,
  activateCaptureModal,
  onUploadedBoxPictureChange,
  loading,
  captureLoading,
  noScanOrPicExcuse,
  onNoScanOrPicExcuseChange,
}: Props) {
  const boxId = box.get('Box ID') ?? '';
  const classes = useStyles();
  const isScanned = scannedBoxIds.includes(boxId);
  const boxValid = group.get(dropoffName).valid;

  if (excludedBoxes.includes(boxId)) return null;

  return (
    <Card style={{ marginBottom: 16, minHeight: 305, opacity: boxValid ? 1 : 0.25 }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center" style={{minHeight: 53}}>
          <Grid item xs>
            <Typography
              variant="body2"
              color={isScanned ? 'primary' : 'error'}
              style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: isScanned ? 'green' : undefined, // Apply green color if scanned
              }}
            >
              Box {boxId}
            </Typography>
          </Grid>

          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                {isScanned ? (
                  <Typography variant="body2">Scanned</Typography>
                ) : (
                  <Typography variant="body2" color="error" style={{ fontStyle: 'italic' }}>
                    Needs scanning
                  </Typography>
                )}
              </Grid>

              {isScanned && (
                <Grid item style={{ marginBottom: 7 }}>
                  <Tick size={20} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="space-between" style={{ marginTop: 12 }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Picture: use only if scanning fails
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} style={{ width: '100%' }}>
            <TakePhotoOrUpload
              cameraError={cameraError}
              picture={scannedBoxPicture}
              activateCaptureModal={activateCaptureModal}
              onUploadedFileChange={(file) => onUploadedBoxPictureChange(boxId, file)}
              loading={loading}
              captureLoading={captureLoading}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 12 }}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Note: use only if cannot take/upload picture
            </Typography>

            <NoScanOrPicExcuse excuse={noScanOrPicExcuse || ''} onExcuseChange={onNoScanOrPicExcuseChange} />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 12 }} justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              disabled={!boxValid}
              onClick={() => excludeBox(boxId)}
              startIcon={<AiOutlineMinusCircle />}
              className={classes.warningButton}
            >
              Exclude box from shipping
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginTop: 16 }}>
          {Object.entries(JSON.parse(box.get('Samples') ?? '{}')).map(([key, sampleObj]) => (
            <Grid item xs={12} key={key} style={{ backgroundColor: '#f2f2f2', padding: 8 }}>
              <Typography variant="subtitle2">{sampleObj.jobInfo.field}</Typography>
              <Typography variant="body2">Samples: {sampleObj.samples.length}</Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
