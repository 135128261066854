import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Mission, { MissionList } from './MissionClass';
import Boundary, { BoundaryList } from './BoundaryClass';
import SampleZone, { SampleZoneList } from './SampleZoneClass';

import {
  to_kml,
  load_kml,
  get_color,
  contains,
  normalize,
  to_feature,
  new_zone,
  get_color_array,
  finish_zone,
  getContainingBoundaries,
} from '../db_ops/zone_ops';
import { IKMLElement } from '../kml';
import { ZoneRecordingType, ZoneType } from './ZoneTypesDatatype';
import { Coordinate } from 'ol/coordinate';
import { IZone } from './types';
import { MINIMUM_COORDINATES_FOR_VALID_BOUNDARY } from '../constants';

export default class Zone extends ObjectClassGenerator<Zone>('Zone') implements IZone {
  // attributes
  #zone_name: string;
  #zone_type: ZoneType;

  // relationships
  #Mission_id: number | undefined;

  static tableName = 'Zone';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(Zone, 'zone_name');
    this.publishAttribute(Zone, 'zone_type');
    this.publishAttribute(Zone, 'Mission_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<Zone> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#zone_name = state.zone_name || '';
    this.#zone_type = state.zone_type || 0;
    this.#Mission_id = state.Mission_id;
  }

  dispose() {
    const mission = this.getMission();
    if (mission) {
      this.Mission_id = undefined;
    }

    for (const boundary of this.getOuterZoneBoundarys()) {
      boundary.OuterZone_id = undefined;
      if (!boundary.getInnerZone()) {
        boundary.dispose();
      }
    }

    for (const boundary of this.getInnerZoneBoundarys()) {
      boundary.InnerZone_id = undefined;
      if (!boundary.getOuterZone()) {
        boundary.dispose();
      }
    }

    const samplezone = this.getSampleZone();
    if (samplezone) {
      samplezone.Zone_id = undefined;
      samplezone.dispose();
    }

    Zone.delete(this.instance_id);
  }

  set zone_name(value) {
    this.#zone_name = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get zone_name() {
    return this.#zone_name;
  }

  set zone_type(value) {
    this.#zone_type = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get zone_type() {
    return this.#zone_type;
  }

  get mission_name() {
    return this.getMission()?.name;
  }

  get last_modified() {
    return this.getMission()?.last_modified;
  }

  set last_modified(value) {
    const inst = this.getMission();
    if (inst && value) {
      inst.last_modified = value;
    }
  }

  set Mission_id(value) {
    if (this.#Mission_id) {
      const relateObj = Mission.get(this.#Mission_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Zone', this.instance_id);
      }
    }
    this.#Mission_id = value;
    if (value) {
      const relateObj = Mission.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Zone', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get Mission_id() {
    return this.#Mission_id;
  }

  getMission() {
    return Mission.get(this.Mission_id);
  }

  getOuterZoneBoundarys() {
    if (this._refs && this._refs.OuterZone) {
      return new BoundaryList(
        ...Array.from(this._refs.OuterZone)
          .map((id) => Boundary.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const boundarys = Boundary.query((sel) => sel && sel.OuterZone_id === this.instance_id);
      for (const boundary of boundarys) {
        boundary.OuterZone_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new BoundaryList(...boundarys);
    }
  }

  getInnerZoneBoundarys() {
    if (this._refs && this._refs.InnerZone) {
      return new BoundaryList(
        ...Array.from(this._refs.InnerZone)
          .map((id) => Boundary.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const boundarys = Boundary.query((sel) => sel && sel.InnerZone_id === this.instance_id);
      for (const boundary of boundarys) {
        boundary.InnerZone_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new BoundaryList(...boundarys);
    }
  }

  getSampleZone() {
    if (this._refs && this._refs.SampleZone) {
      const result = Array.from(this._refs.SampleZone).map((id) => SampleZone.get(id));
      return result[0];
    } else {
      const samplezone = SampleZone.findOne((sel) => sel?.Zone_id === this.instance_id);
      if (samplezone) {
        samplezone.Zone_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return samplezone;
    }
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Zone.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.mission_name === this.mission_name &&
        sel.zone_name === this.zone_name &&
        sel.last_modified === this.last_modified,
    );
    for (const dup of id1_duplicates) {
      problems.push(`Duplicate zone found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`);
    }
    // check relationships
    for (let tableName in this._refs) {
      const refName = tableName;
      tableName = 'Boundary';
      Array.from(this._refs[refName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`zone: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getMission()) {
      problems.push(`zone: Could not find mission instance across unconditional relationship R14: ${this.instance_id}`);
    }
    if (this.getOuterZoneBoundarys().length < 1) {
      problems.push(
        `zone: Could not find boundary instance across unconditional relationship R15: ${this.instance_id}`,
      );
    }
    return problems;
  }

  // @ts-ignore TODO fix kml serialize typings
  to_kml() {
    return to_kml.bind(this)();
  }

  static async load_kml(poly: IKMLElement, mission: Mission) {
    return await load_kml(poly, mission);
  }

  get_color() {
    return get_color.bind(this)();
  }

  contains(lat: number, lon: number) {
    return contains.bind(this)(lat, lon);
  }

  hasValidBoundaries() {
    const outer = this.getOuterZoneBoundarys();
    if (outer.length < 1) {
      return false;
    }

    // Check outer boundaries
    for (const boundary of outer) {
      if (boundary.coordinates.length < MINIMUM_COORDINATES_FOR_VALID_BOUNDARY) {
        return false;
      }
    }

    // Check inner boundaries
    for (const boundary of this.getInnerZoneBoundarys()) {
      if (boundary.coordinates.length < MINIMUM_COORDINATES_FOR_VALID_BOUNDARY) {
        return false;
      }
    }

    return true;
  }

  getContainingBoundaries(lat: number, lon: number) {
    return getContainingBoundaries.bind(this)(lat, lon);
  }

  async normalize() {
    await normalize.bind(this)();
  }

  // TODO normalize this return type and make it better
  to_feature() {
    return to_feature.bind(this)();
  }

  static async new_zone(name: ZoneRecordingType | string, points: Coordinate[][], mission_id: number) {
    return await new_zone(name, points, mission_id);
  }

  get_color_array() {
    return get_color_array.bind(this)();
  }

  async finish_zone() {
    await finish_zone.bind(this)();
  }
}

export class ZoneList extends DataObjectList<Zone> {
  getMissions() {
    return new MissionList(...this.map((zone) => zone.getMission()).filter((sel) => !!sel));
  }

  getOuterBoundarys() {
    return new BoundaryList(
      ...this.reduce((zones: Boundary[], zone) => zones.concat(zone.getOuterZoneBoundarys()), []).filter(
        (sel) => !!sel,
      ),
    );
  }

  getInnerBoundarys() {
    return new BoundaryList(
      ...this.reduce((zones: Boundary[], zone) => zones.concat(zone.getInnerZoneBoundarys()), []).filter(
        (sel) => !!sel,
      ),
    );
  }

  getSampleZones() {
    return new SampleZoneList(...this.map((zone) => zone.getSampleZone()).filter((sel) => !!sel));
  }
}
