import { Avatar, Button, Grid } from '@material-ui/core';
import { AiOutlineCamera } from 'react-icons/ai';
import { MdFileUpload } from 'react-icons/md';
import { LoadingButton } from '../utils';
import { alertConfirm } from '../../alertDispatcher';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: '50%',
    boxShadow: theme.shadows[3],
  },
}));

interface Props {
  cameraError: string;
  picture?: string | Blob;
  activateCaptureModal: () => void;
  onUploadedFileChange: (file: File) => void;
  loading: boolean;
  captureLoading: boolean;
  buttonLabel?: string;
}

export default function TakePhotoOrUpload({
  cameraError,
  picture,
  activateCaptureModal,
  onUploadedFileChange,
  loading,
  captureLoading,
  buttonLabel,
}: Props) {
  return (
    <Grid item xs={6} sm={1}>
      {cameraError ? (
        <UploadPhoto picture={picture} onUploadedFileChange={onUploadedFileChange} label={buttonLabel} />
      ) : (
        <TakePhoto
          picture={picture}
          activateCaptureModal={activateCaptureModal}
          loading={loading}
          captureLoading={captureLoading}
          label={buttonLabel}
        />
      )}
    </Grid>
  );
}

const UploadPhoto = ({ picture, onUploadedFileChange, label }) => {
  const classes = useStyles();

  return (
    <Button variant="outlined" component="label">
      {label}

      {picture ? (
        <Avatar
          className={classes.avatar}
          src={typeof picture === 'string' ? picture : URL.createObjectURL(picture)}
          alt="Box picture"
        />
      ) : (
        <MdFileUpload style={{ marginLeft: 7.5 }} fontSize="medium" />
      )}

      <input
        type="file"
        accept=".jpg"
        style={{ display: 'none' }}
        onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
          if (!event.target.files) {
            // TODO perhaps we should throw an error or call anyways to get it to cancel?
            return;
          }
          const file = event.target.files[0];
          onUploadedFileChange(file);
        }}
        multiple={false}
        data-testid="picture-upload"
      />
    </Button>
  );
};

const TakePhoto = ({ picture, activateCaptureModal, loading, captureLoading, label }) => {
  const classes = useStyles();

  return (
    <LoadingButton
      variant="outlined"
      onClick={async () => {
        // if we DON'T have a picture, we will enter the capture screen.
        // if we DO have a picture, we will do an alertConfirm and wait for the user to answer.
        if (!picture || (await alertConfirm('You have already taken a picture. Retake?'))) {
          activateCaptureModal();
        }
      }}
      disabled={loading}
      loading={captureLoading}
    >
      {label}

      {picture ? (
        <Avatar
          className={classes.avatar}
          src={typeof picture === 'string' ? picture : URL.createObjectURL(picture)}
          alt="Box picture"
        />
      ) : (
        <AiOutlineCamera style={{ marginLeft: 7.5 }} />
      )}
    </LoadingButton>
  );
};
