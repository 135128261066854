import Airtable from '../airtable';

import { AirtableRecord, Attachment } from '../db';

import { Attachment as AirtableAttchment } from 'airtable/lib/attachment';

import S3 from '../aws';
import { fileToBase64, filterObject, uuidv4, wait } from '../utils';
import { FieldSet, RecordData, Records, Record as _AirtableRecord } from 'airtable';
import logger from '../logger';
import { AirtableRecordFields } from '../db/types';
import { IAirtableAttachment } from '@rogoag/airtable';

// Only download attachments that will be used in offline mode
const DOWNLOAD_IMMEDIATE_ATTACHMENTS = ['Int Mission', 'Partial Mission', 'Bnd GeoJSON'];

const is_attachment = (fieldValue: any | Attachment): fieldValue is Attachment => {
  if (fieldValue && typeof fieldValue === 'object' && fieldValue.length > 0) {
    // TODO this is hacky and we should figure out a nicer way to type this
    return fieldValue.every(
      (att: any) => att && typeof att === 'object' && 'id' in att && 'url' in att && 'filename' in att,
    );
  }
  return false;
};

export async function refresh<T extends AirtableRecordFields = any>(this: AirtableRecord<T>) {
  if (!this.currentRefresh) this.currentRefresh = Promise.resolve();
  this.currentRefresh = new Promise<void>(async (resolve, reject) => {
    // don't allow starting one sync before other sync finishes
    try {
      await this.currentRefresh;
    } catch (e) {
      console.error('Error in previous refresh', e);
    }
    try {
      // update from airtable
      const r = await Airtable._find(this.table, this.id);
      if (!this.dirty) {
        // do not update dirty records
        // @ts-ignore TODO figure out how to recityf the `fields` type
        this.fields = r.fields;
      }

      // refresh attachments
      await this.refresh_attachments();
    } catch (err) {
      reject(err);
    }
    resolve();
  });
  return this.currentRefresh;
}

export function has<T extends AirtableRecordFields = AirtableRecordFields>(this: AirtableRecord<T>, field: keyof T) {
  return this.fields && field in this.fields;
}

export function get<T extends AirtableRecordFields = AirtableRecordFields>(this: AirtableRecord<T>, field: keyof T) {
  if (is_attachment(this.fields[field])) {
    throw new Error("'get' unsupported for attachment fields'");
  } else {
    // TODO this shouldn't be needed
    Airtable.assertFieldIncluded(this.table, field.toString());
    return this.fields[field];
  }
}

export async function set<T extends AirtableRecordFields = AirtableRecordFields>(
  this: AirtableRecord<T>,
  field: keyof T,
  value: any,
) {
  if (is_attachment(this.fields[field])) {
    throw new Error("'set' unsupported for attachment fields'");
  } else {
    if (value === undefined) {
      console.warn(`Setting field '${field.toString()}' to undefined`);
    }

    this.fields[field] = value;
    this.dirty = true;

    const modified_copy =
      this.modified_fields && this.modified_fields.constructor !== Object
        ? new Set(this.modified_fields)
        : new Set<string>();
    modified_copy.add(field);
    this.modified_fields = modified_copy;
  }
}

// export async function syncMulti(tableName: string, records: AirtableRecord[]) {
//   const airtableUpdates: RecordData<Partial<FieldSet>>[] = [];
//   for (const record of records) {
//     const fields: Partial<FieldSet> = {};
//     const attachments = this.getAttachments();

//   }

//   await Airtable._update(tableName, airtableUpdates);
// }

export async function uploadDirtyAttachments<T extends AirtableRecordFields = AirtableRecordFields>(
  record: AirtableRecord<T>,
  fields: Partial<FieldSet>,
) {
  // upload all dirty attachments first
  const attachments = record.getAttachments();
  const upload_promises: Promise<void>[] = [];
  for (const attachment of attachments) {
    if (attachment.dirty) {
      upload_promises.push(
        new Promise<void>(async (resolve, reject) => {
          try {
            // upload the file
            const record = attachment.getAirtableRecord();
            if (!record) {
              return reject(new Error(`Attachment ${attachment.attachment_id} has no associated record`));
            }

            // To fix bug where attachment.content is an empty object.
            // TODO: investigate further - why this would be the case.
            if (!attachment.content || !(attachment.content instanceof Blob)) {
              attachment.dispose();
              resolve();

              return;
            }

            const checksum = await S3.upload(
              `${record.id}/${attachment.filename}`,
              attachment.content!,
              attachment.content!.type,
            );
            // TODO upload file to root folder as backwards compatibility measure for now
            await S3.upload(`${attachment.filename}`, attachment.content!, attachment.content!.type);

            // update the attachment fields for the update request
            if (!fields[attachment.field_name]) {
              fields[attachment.field_name] = [];
            }
            const s3Url = S3.urlFor(`${record.id}/${attachment.filename}`);

            // we know we are only editing attachments so this cast should work
            (fields[attachment.field_name] as Partial<AirtableAttchment>[]).push({
              url: s3Url,
              filename: attachment.filename,
            });

            // must dispose the attachment so it can get updated with new attachment ID
            attachment.dispose();
          } catch (err) {
            reject(err);
          }
          resolve();
        }),
      );
    }
    /* 
    Currently have no idea why this happened before, seems like only dirty attachments should
    be updated. This code pushes the attachment changes no matter what, which is confusing to me
    else {
      // update the attachment fields for the update request
      if (!fields[attachment.field_name]) { 
        fields[attachment.field_name] = []; 
      }
      fields[attachment.field_name].push({
        id: attachment.attachment_id,
        url: attachment.url,
        filename: attachment.filename
      });
    } 
    */
  }
  return await Promise.all(upload_promises);
}

/**
 * Upload updates to aws boxes-shipping bucket for subsequent
 * processing by boxes-shipping-processor lambda.
 *
 * @param boxRecordsToUpdate
 * @param boxShipmentRecordID
 */
export async function transferUpdatesForProcessing(boxRecordsToUpdate: AirtableRecord[], boxShipmentRecordID: string) {
  async function prepareUpdatesForTransfer(records: AirtableRecord[]) {
    // filter out duplicate records by record ID
    records = records.filter((record, index) => records.indexOf(record) === index);

    const prepareAttachmentsAndFillFields = async (record: AirtableRecord) => {
      const fields = {};

      const attachments = record.getAttachments();
      for (const attachment of attachments) {
        if (!attachment.dirty) {
          continue;
        }

        if (!attachment.content) {
          attachment.dispose();

          continue;
        }

        // update the attachment fields for the update request
        if (!fields[attachment.field_name]) {
          fields[attachment.field_name] = [];
        }

        const imageInBase64 = await fileToBase64(attachment.content);

        (fields[attachment.field_name] as { fileName: string; fileContent: string }[]).push({
          fileName: attachment.filename,
          fileContent: imageInBase64,
        });

        // must dispose the attachment so it can get updated with new attachment ID
        attachment.dispose();
      }

      // fill in fields
      for (const modField of record.modified_fields) {
        const fieldValue = record.fields[modField];
        if (!is_attachment(fieldValue)) {
          // for attachment fields, don't override fields
          fields[modField] = fieldValue;
        }
      }

      // assemble data update payload
      const update: [table: string, recordUpdate: RecordData<FieldSet>] = [
        record.table,
        {
          id: record.id,
          fields: fields,
        },
      ];

      return update;
    };

    return await Promise.all(records.map(prepareAttachmentsAndFillFields));
  }

  const updates = await prepareUpdatesForTransfer(boxRecordsToUpdate);

  await S3.upload(
    `box_shipment_record_${boxShipmentRecordID}_uuid_${uuidv4()}_boxes_update.json`,
    new Blob([JSON.stringify(updates)], { type: 'plain/text' }),
    'application/json',
    {
      bucket: import.meta.env.PROD ? 'boxes-shipping' : 'boxes-shipping-dev',
    },
  );
}

export async function syncMulti<T extends AirtableRecordFields>(records: AirtableRecord<T>[]) {
  // filter out duplicate records by record Id
  records = records.filter((record, index) => records.indexOf(record) === index);

  // method to upload attachments and update record data appropriately
  const uploadAttachmentsAndFillFields = async <T extends AirtableRecordFields>(record: AirtableRecord<T>) => {
    const fields: Partial<T> = {};

    // do attachment uploads
    await uploadDirtyAttachments(record, fields);

    // fill in fields
    // TODO adding in a hack to check if this is an object or a set but we need to resolve this better
    if (record.modified_fields instanceof Set) {
      for (const mod_field of record.modified_fields) {
        const fieldValue = record.fields[mod_field];
        if (!is_attachment(fieldValue)) {
          // for attachment fields, don't override fields
          fields[mod_field] = fieldValue;
        }
      }
    }

    // assemble data update payload
    const update: [table: string, recordUpdate: RecordData<T>] = [
      record.table,
      {
        id: record.id,
        // @ts-ignore TODO need to figure out the types...
        fields,
      },
    ];

    return update;
  };

  const airtableUpdates = await Promise.all(records.map(uploadAttachmentsAndFillFields));

  // first organize all updates by table
  const updatesByTable: Record<string, RecordData<T>[]> = {};
  for (const [table, recordUpdate] of airtableUpdates) {
    if (!(table in updatesByTable)) {
      updatesByTable[table] = [];
    }
    updatesByTable[table].push(recordUpdate);
  }

  // then "chunk" any updates that have more than 10 records
  // into separate API calls
  const updates = Object.entries(updatesByTable)
    .map(([table, records]) => {
      const syncCalls: { table: string; chunk: RecordData<T>[] }[] = [];
      for (let i = 0; i < records.length; i += 10) {
        const chunk = records.slice(i, i + 10);
        syncCalls.push({ table, chunk });
      }
      return syncCalls;
    })
    .flat();

  // Now call each update chunk (by table / 10 records at a time)
  // we could wait 200ms-1s between each update call to avoid potentially hitting the rate limit
  // but for now just the fact that we are combining the updates should hopefully be adequate
  // but the idea is that where before we might have been making 10+ calls, now we should be
  // making 2-5 calls and thus hopefully will be below the limit either way
  const results: _AirtableRecord<T>[] = [];
  let sleepTime = 0;

  // if we have more than two update chunks, we will pause between updates to ensure
  // we don't hit the AirTable rate limits
  await logger.log(
    'AIRTABLE_RECORD_OPS',
    `syncmulti: ${updates.length} updates (${Object.keys(updatesByTable)
      .map((table) => `${table}: ${updatesByTable[table].length}`)
      .join(', ')})`,
  );
  if (updates.length >= 3) {
    sleepTime = 250;
  }

  for (const update of updates) {
    const updateResult = await Airtable._update(update.table, update.chunk);

    results.push(...updateResult);
    await wait(sleepTime);
  }

  for (const result of results) {
    const record = records.find((sel) => sel.id === result.id);
    if (record) {
      //console.log(`Updating record ${record.id} with ${JSON.stringify(result.fields)}`);
      record.dirty = false;
      record.modified_fields = new Set<string>();
      record.fields = result.fields;
    }
  }
  return results;
}

export async function sync<T extends AirtableRecordFields>(this: AirtableRecord<T>) {
  if (!this.currentSync) this.currentSync = Promise.resolve();
  this.currentSync = new Promise<void>(async (resolve, reject) => {
    // don't allow starting one sync before other sync finishes
    try {
      await this.currentSync;
    } catch (e) {
      console.error('Error in previous sync', e);
    }

    try {
      await syncMulti([this]);
    } catch (err) {
      reject(err);
    }
    resolve();
  });
  return this.currentSync;
}

function isAttachmentStale(
  localAttachment: Attachment,
  atRecordAttachmentFields: Partial<AirtableRecordFields>,
): boolean {
  if (localAttachment.dirty) {
    return false;
  }

  const attachmentInAtAttachmentFields = atRecordAttachmentFields[localAttachment.field_name] as
    | IAirtableAttachment[]
    | undefined;

  if (!attachmentInAtAttachmentFields) {
    return true;
  }

  // SVH 2022-11-14 we need to not only check IDs, but also URLs now because AirTable URLs are now transitory
  // and will change periodically
  // TODO a valid concern with thise approach is that now we will have to regularly re-download lots of binary blobs
  // which will be problematic if we have to do it a lot
  const foundSameEntryInAtAttachment = attachmentInAtAttachmentFields.find(
    (a: IAirtableAttachment) => a.id === localAttachment.attachment_id && a.url === localAttachment.url,
  );

  // If we found necessary attachment and the URL is the same, then it's not stale.
  return !foundSameEntryInAtAttachment;
}

export async function refresh_attachments(this: AirtableRecord, { download_all = false, map_making = false } = {}) {
  if (!this.currentAttachmentRefresh) this.currentAttachmentRefresh = Promise.resolve();
  this.currentAttachmentRefresh = new Promise<void>(async (resolve, reject) => {
    // don't allow starting one sync before other sync finishes
    try {
      await this.currentAttachmentRefresh;
    } catch (e) {
      console.error('Error in previous refresh_attachments', e);
    }

    try {
      // get all attachment fields from this record
      const atRecordAttachmentFields: Partial<AirtableRecordFields> = filterObject(this.fields, (sel: any) =>
        is_attachment(sel),
      );

      // get all stale attachments that must be removed
      const staleAttachments = this.getAttachments().filter((selectedAttachment) =>
        isAttachmentStale(selectedAttachment, atRecordAttachmentFields),
      );
      staleAttachments.forEach((stale_attachment) => stale_attachment.dispose());

      // create new attachments
      const fetches: Promise<void>[] = [];
      for (const fieldName in atRecordAttachmentFields) {
        for (const att of atRecordAttachmentFields[fieldName] as IAirtableAttachment[]) {
          let attachment = this.getAttachments().find(
            (sel) => sel.field_name === fieldName && sel.attachment_id === att.id && sel.url === att.url,
          );
          if (!attachment) {
            attachment = await new_attachment.bind(this)(fieldName);
            attachment.attachment_id = att.id;
            attachment.filename = att.filename;
            attachment.url = att.url;
            //console.log(`${map_making} ${download_all} ${DOWNLOAD_IMMEDIATE_ATTACHMENTS.includes(fieldName)}`);
            if (!map_making && (download_all || DOWNLOAD_IMMEDIATE_ATTACHMENTS.includes(fieldName))) {
              //console.log(`Downloading for ${att.id} immediately`)
              fetches.push(
                new Promise<void>(async (resolve, reject) => {
                  try {
                    let url: string | undefined = attachment?.url;
                    //console.log(S3.urlFor(attachment.filename));
                    const mainRecord = attachment!.getAirtableRecord();

                    // Some attachments already have the job id part in the filename. If not, add it.
                    const s3Key = attachment!.filename.includes(`${mainRecord?.id}/`)
                      ? attachment!.filename
                      : `${mainRecord?.id}/${attachment!.filename}`;

                    if (mainRecord && (await S3.checkIfS3FileExists(s3Key))) {
                      url = S3.urlFor(s3Key);
                    }

                    if (!url) {
                      return reject(new Error(`Could not retrieve Airtable attachment at ${att.url}`));
                    }
                    const resp = await fetch(url);
                    if (resp.ok) {
                      attachment!.content = await resp.blob();
                      //console.log(`set downloadUrl to ${url}`);
                      attachment!.downloadUrl = url;
                      resolve();
                    } else {
                      throw new Error(
                        `Could not retrieve Airtable attachment at ${att.url}. Failed with code ${resp.status}`,
                      );
                    }
                  } catch (err) {
                    console.log(err);
                    //reject(err);
                    // TODO silently fail?
                  }
                }),
              );
            }
          } else {
            //console.log("------ Attachment already exists");
          }
        }
      }

      // wait for work to complete
      await Promise.all(fetches);
    } catch (err) {
      //console.log(`2 airtable_record_ops:get_attachments err=${JSON.stringify(err)}`);
      reject(err);
    }
    resolve();
  });

  return this.currentAttachmentRefresh;
}

export async function get_attachments<T extends AirtableRecordFields = AirtableRecordFields>(
  this: AirtableRecord<T>,
  field: keyof T,
) {
  Airtable.assertFieldIncluded(this.table, field);

  if (is_attachment(this.fields[field])) {
    const attachments = this.getAttachments().filter((sel) => field === sel.field_name);

    return Promise.all(
      attachments.map(async (attachment) => {

        const content = attachment.content;
        const attachmentIsValid = !!content && attachment.content instanceof Blob;
        if (attachmentIsValid && (await content.text()).length) {
          return attachment;
        } else {
          // TODO could also do S3 check here...
          // TODO should specify cache policy

          let url = attachment.url;

          // Some attachments already have the job id part in the filename. If not, add it.
          const jobFileKey = attachment.filename.includes(`${this.id}/`)
            ? attachment.filename
            : `${this.id}/${attachment.filename}`;

          if (await S3.checkIfS3FileExists(jobFileKey)) {
            url = S3.urlFor(jobFileKey);
          }

          let resp: Response;
          try {
            resp = await fetch(url);
          } catch (error) {
            throw new Error(`Failed to fetch Airtable attachment at ${attachment.url}. Error - ${error}`);
          }

          if (resp.ok) {
            attachment.content = await resp.blob();
            return attachment;
          } else {
            // TODO try S3 URL
            // const resp = await fetch(formS3URL(attachment.filename));

            throw new Error(
              `Could not retrieve Airtable attachment at ${attachment.url}. Failed with code ${resp.status}`,
            );
          }
        }
      }),
    );
  } else {
    // Only throw an error if the field exists - it's possible the field is an attachment
    // (such as Partial Mission) but Airtable doesn't send the field because there's no file
    if (this.fields[field]) {
      throw new Error(`Field '${field.toString()}' is not an attachment, use regular .get() instead.`);
    }
    return [];
  }
}

export async function new_attachment(this: AirtableRecord, field: string) {
  const attachment = Attachment.create();
  attachment.field_name = field;
  attachment.attachment_id = `new_attachment_${Math.random().toString().slice(2)}`;
  attachment.AirtableRecord_id = this.instance_id;
  return attachment;
}

export async function clear_attachments(this: AirtableRecord, field: string) {
  this.dirty = true;
  this.getAttachments()
    .filter((sel) => sel.field_name === field)
    .forEach((attachment) => attachment.dispose());
}

export function attachments_up_to_date(this: AirtableRecord) {
  // get all attachment fields
  const atts = filterObject(this.fields, (sel) => is_attachment(sel));
  // check attachments
  for (const fieldName in atts) {
    for (const att of atts[fieldName]) {
      let attachment = this.getAttachments().find(
        (sel) => sel.field_name === fieldName && sel.attachment_id === att.id,
      );
      if (!attachment) {
        return false;
      }
    }
  }
  // all attachments are updated
  return true;
}

export async function getRecord<T extends AirtableRecordFields = AirtableRecordFields>(
  tableName: string,
  recordId: string,
) {
  return await AirtableRecord.findOne<AirtableRecord<T>>(
    (sel) => !!sel && sel.table === tableName && sel.id === recordId,
  );
}

export async function createRecord(tableName: string, fields: AirtableRecordFields, recordId: string) {
  const newRecord = AirtableRecord.create();
  newRecord.table = tableName;
  newRecord.id = recordId;
  newRecord.fields = fields;
  newRecord.modified_fields = new Set<string>();
  return newRecord;
}
