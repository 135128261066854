import { AirtableRecord, Mission, Session } from '../../../db';
import { kmz2kml } from '../../../utils';
import { alertError, alertWarn } from '../../../alertDispatcher';
import Airtable from '../../../airtable';
import { ShowAutoGenHelper, RogoShapefile, RogoShapefileType, JobType } from '../../../types/types';
import { Jobs } from '@rogoag/airtable';
import { DataSourceOption, IMissionLoader, mapMakingLock } from '../MissionLoaderCommon';

class OriginalMissionLoader implements IMissionLoader {
  async load(
    session: Session,
    jobID: string,
    sampling: boolean,
    setAutogenHelper?: ShowAutoGenHelper,
  ): Promise<Mission | undefined> {
    let job = await Airtable.getRecord<Jobs>('Jobs', jobID);
    if (!job) {
      try {
        const fields = await Airtable._find('Jobs', jobID);
        const record = AirtableRecord.create<AirtableRecord<Jobs>>();
        record.table = 'Jobs';
        record.id = jobID;

        // @ts-ignore the fields have a type mismatch in this situation
        record.fields = fields;
        job = await Airtable.getRecord<Jobs>('Jobs', jobID);
      } catch (e) {
        alertError('Job not found');
      }
    }

    if (!job) {
      alertError('Job not found');

      return;
    }

    let dataSource: DataSourceOption = 'Shapefiles';
    if (!sampling) {
      dataSource = await mapMakingLock(job);
      if (dataSource === 'Cancel Load') {
        return;
      }
    }

    if (!job) {
      console.error(`Job not found: ${jobID}`);
      alertError('Job not found');

      return;
    }

    if (!sampling) {
      return await this.handleNonSamplingCase(dataSource, session, job, jobID, sampling, setAutogenHelper);
    } else {
      return await this.handleSamplingCase(job, session, jobID, sampling);
    }
  }

  private async handleSamplingCase(
    job: AirtableRecord<Jobs>,
    session: Session,
    jobID: string,
    sampling: boolean,
  ): Promise<Mission | undefined> {
    let kmlFile = (await job.get_attachments('Partial Mission'))[0];
    if (!kmlFile) {
      kmlFile = (await job.get_attachments('Int Mission'))[0];
    }

    if (!kmlFile) {
      alertWarn('Map has not been made for this mission!');

      return;
    }
    let kmltext = '';
    if (kmlFile.filename.endsWith('.kmz')) {
      kmltext = await kmz2kml(kmlFile.content as Blob);
    } else {
      kmltext = await kmlFile.text();
    }

    const dealSites = (job.get('Deal Sites Type') || [''])[0];
    const expectedDensity = (job.get('Deal Density') || [0])[0];

    return await session.loadKml(kmltext, jobID, {
      sampling,
      // TODO figure out this typing
      dealSitesType: dealSites as JobType | undefined,
      fieldName: job.get('Field Name Clean'),
      expectedDensity,
    });
  }

  async handleNonSamplingCase(
    dataSource: DataSourceOption,
    session: Session,
    job: AirtableRecord,
    jobID: string,
    sampling: boolean,
    setAutogenHelper?: ShowAutoGenHelper,
  ): Promise<Mission | undefined> {
    if (dataSource === 'Shapefiles') {
      return await this.loadFromShapefiles(session, job, jobID, setAutogenHelper);
    }

    let kmltext = '';
    const fieldAttachments = await job.get_attachments(dataSource);
    const kmlFile = fieldAttachments[0];

    if (kmlFile.filename.endsWith('.kmz')) {
      kmltext = await kmz2kml(kmlFile.content as Blob);
    } else {
      kmltext = await kmlFile.text();
    }

    return await session.loadKml(kmltext, jobID, { sampling });
  }

  async loadFromShapefiles(
    session: Session,
    job: AirtableRecord,
    id: string,
    setAutogenHelper: ShowAutoGenHelper | undefined,
  ): Promise<Mission | undefined> {
    const shpfiles: RogoShapefile[] = [];

    const loadAttachments = async (field: string, type: RogoShapefileType) => {
      const attachments = await job.get_attachments(field);
      const invalidExtensions = attachments
        .map((attachment) => {
          const tokens = attachment.filename.toLowerCase().split('.');
          return tokens[tokens.length - 1];
        })
        .filter((extension) => !['shx', 'shp', 'dbf', 'prj', 'zip'].includes(extension));

      if (invalidExtensions.length === 0) {
        shpfiles.push(
          ...(await Promise.all(
            attachments.map(async (attachment) => ({
              mode: type,
              data: attachment.content ? await attachment.content.arrayBuffer() : new ArrayBuffer(0),
              source: field,
              name: attachment.filename.toLowerCase(),
            })),
          )),
        );
      } else {
        alertError(`Invalid ${type} extensions: ${invalidExtensions.join(',')}`);
      }
    };

    await loadAttachments('Bnd Shp', 'bnd');
    await loadAttachments('Pts Shp', 'pts');
    await loadAttachments('Sample Zones Shp', 'pts');

    if (shpfiles.length > 0) {
      return await session.loadShapes(job.get('Mission Name'), shpfiles, id, setAutogenHelper);
    } else {
      console.error(`Job has no mission files: ${id}`);
      alertError('Job has no mission files');

      return;
    }
  }
}

export default OriginalMissionLoader;
