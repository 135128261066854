import { TableCell, TableRow } from '@material-ui/core';
import { ShippingGroupBox } from './ShippingGroupBox';
import { Fragment } from 'react/jsx-runtime';
import { LoadingButton } from '../utils';
import { getDatabase } from '../../db/datamanager';
import { AirtableRecord } from '../../db';
import { Box } from '@rogoag/airtable';
import { useEffect, useState } from 'react';
import { ScannedBox } from '../../db/types';
import { alertConfirm } from '../../alertDispatcher';

interface Props {
  labName: string;
  dropoffName: string;
  group: any;
  switchToCourier: (dropoffName: string, labName: string) => Promise<void>;
  expandBox: (boxId: string) => void;
  collapseBox: (boxId: string) => void;
  excludeBox: (boxId: string) => void;
  scannedBoxIds: string[];
  expandedBoxes: string[];
  excludedBoxes: string[];

  cameraError: string;
  activateCaptureModal: (captureTargetId: string) => void;
  onUploadedBoxPictureChange: (boxId: string, file: File) => Promise<void>;
  loading: boolean;
  captureLoading: boolean;

  onNoScanOrPicExcuseChange: (box: AirtableRecord<Box>, excuse: string | undefined) => void;
}

export function ShippingLabGroup({
  labName,
  dropoffName,
  group,
  switchToCourier,
  expandBox,
  collapseBox,
  excludeBox,
  scannedBoxIds,
  expandedBoxes,
  excludedBoxes,
  cameraError,
  activateCaptureModal,
  onUploadedBoxPictureChange,
  loading,
  captureLoading,
  onNoScanOrPicExcuseChange,
}: Props) {
  const [scannedBoxes, setScannedBoxes] = useState<ScannedBox[]>([]);

  useEffect(() => {
    const getScannedBoxesFromDb = async () => {
      const db = getDatabase();
      const scannedBoxes = await db.ScannedBoxes.toArray();

      setScannedBoxes(scannedBoxes);
    };

    getScannedBoxesFromDb();
  }, [scannedBoxIds]);

  return (
    <Fragment>
      {group.get(dropoffName).method === 'UPS' && (
        <TableRow key={labName}>
          <TableCell colSpan={3} align="left" style={{ padding: 5 }}>
            {labName}
          </TableCell>

          <TableCell colSpan={1} align="right" style={{ padding: 5 }}>
            <LoadingButton
              onClick={async () => await switchToCourier(dropoffName, labName)}
              color="secondary"
              size="small"
            >
              Switch to Courier
            </LoadingButton>
          </TableCell>
        </TableRow>
      )}

      <TableRow style={{ backgroundColor: '#C1D8C3' }}>
        <TableCell>Box</TableCell>
        <TableCell>Picture: use only if scanning fails</TableCell>
        <TableCell>Note: use only if cannot take/upload picture</TableCell>
        <TableCell align="right">Status</TableCell>
      </TableRow>

      {group
        .get(dropoffName)
        .labBoxes.get(labName)
        .map((box: AirtableRecord<Box>) => {
          const boxCode = box.get('Box ID') || '';
          const scannedBox = scannedBoxes.find((scannedBox: ScannedBox) => scannedBox.box_id === boxCode);

          return (
            <ShippingGroupBox
              key={box.id}
              box={box}
              expandBox={expandBox}
              collapseBox={collapseBox}
              excludeBox={excludeBox}
              dropoffName={dropoffName}
              group={group}
              expandedBoxes={expandedBoxes}
              excludedBoxes={excludedBoxes}
              scannedBoxIds={scannedBoxIds}
              cameraError={cameraError}
              scannedBoxPicture={scannedBox ? scannedBox.image : undefined}
              activateCaptureModal={async () => {
                if (await alertConfirm('Do you confirm that scanning failed?')) {
                  activateCaptureModal(boxCode);
                }
              }}
              onUploadedBoxPictureChange={onUploadedBoxPictureChange}
              loading={loading}
              captureLoading={captureLoading}
              noScanOrPicExcuse={box.get('Box Ops Notes')}
              onNoScanOrPicExcuseChange={(newExcuse: string | undefined) => onNoScanOrPicExcuseChange(box, newExcuse)}
            />
          );
        })}
    </Fragment>
  );
}
