import { IKMLElement, KMLElement } from '../kml';
import { fromLatLon, toLatLon } from 'utm';

import { Boundary, Zone } from '../db';

import { point, polygon } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { cleanBoundary } from '../utils';
import { simplify as dp_simplify } from '../simplify';
import { alertWarn } from '../alertDispatcher';
import BoundaryClass from '../db/BoundaryClass';
import { Coordinate } from 'ol/coordinate';
import { MINIMUM_COORDINATES_FOR_VALID_BOUNDARY } from '../constants';

export function to_kml(this: BoundaryClass) {
  const lr = KMLElement.element('LinearRing');
  const coords = KMLElement.subelement(lr, 'coordinates');
  coords.setText('');
  for (const [i, coord] of this.coordinates.entries()) {
    coords.setText(`${coords.getText()}${i > 0 ? ' ' : ''}${coord[1]},${coord[0]},0`);
  }
  return lr;
}

export async function load_kml(
  linearring: IKMLElement,
  zone: Zone,
  outer: boolean,
  poly_id: number,
): Promise<[Boundary[], string[]]> {
  let boundaries: Boundary[] = [];
  let error_messages: string[] = [];

  const coords = linearring.find('coordinates') ? linearring.find('coordinates')?.getText() : '';
  if (!!coords) {
    let boundary_coordinates = coords
      .trim()
      .split(' ')
      .filter((coord_set) => !!coord_set)
      .map((coord_set) => [parseFloat(coord_set.split(',')[1]), parseFloat(coord_set.split(',')[0])]) as Coordinate[];
    boundary_coordinates = cleanBoundary(boundary_coordinates); // clean the boundary of duplicates
    [boundaries, error_messages] = await Boundary.new_boundary(boundary_coordinates, zone, outer, poly_id);
  } else {
    error_messages.push(`ERROR: no coordinates found for boundary in '${zone.zone_name}'`);
  }

  // return loaded boundaries and error/warning messages
  return [boundaries, error_messages];
}

export function contains(this: BoundaryClass, lat: number, lon: number) {
  const utm_loc = fromLatLon(lat, lon);
  
  // Instead of doing all of the transforms, we can just create simple polygon objects that wrap the 
  // existing coordinates. This should be much faster
  const contains = booleanPointInPolygon([lat, lon],
    {
      type: 'Polygon',
      coordinates: [
        this.coordinates
      ]
    });
  return contains;
}

export async function new_boundary(
  boundary_coordinates: Coordinate[],
  zone: Zone,
  outer: boolean,
  poly_id: number,
): Promise<[Boundary[], string[]]> {
  const boundaries: Boundary[] = [];
  let error_messages: string[] = [];
  // TODO this array creation and use of the word set is confusing. This code may not
  // be doing what the original author thought...
  let boundary_coordinate_sets = [boundary_coordinates];
  for (const _boundary_coordinates of boundary_coordinate_sets) {
    if (_boundary_coordinates.length < MINIMUM_COORDINATES_FOR_VALID_BOUNDARY) {
      alertWarn(`Could not create boundary, needs ${MINIMUM_COORDINATES_FOR_VALID_BOUNDARY} or more coordinates`);
    } else {
      const boundary = Boundary.create();
      boundary.boundary_id = zone.getMission()!.assign_id();
      boundary.poly_id = poly_id;
      if (outer) {
        boundary.OuterZone_id = zone.instance_id;
      } else {
        boundary.InnerZone_id = zone.instance_id;
      }
      boundary.coordinates = _boundary_coordinates;
      boundaries.push(boundary);
    }
  }

  // return loaded boundaries and error/warning messages
  return [boundaries, error_messages];
}

export function simplify(this: BoundaryClass, tolerance: number) {
  if (this.coordinates.length > 0) {
    const { zoneNum, zoneLetter } = fromLatLon(this.coordinates[0][0], this.coordinates[0][1]);
    const utm_points: [number, number][] = this.coordinates.map((coord) => {
      const { easting, northing } = fromLatLon(coord[0], coord[1], zoneNum);
      return [easting, northing];
    });

    const simple_points = dp_simplify(utm_points, tolerance);
    this.coordinates = simple_points.map((point) => {
      const { latitude, longitude } = toLatLon(point[0], point[1], zoneNum, zoneLetter);
      return [latitude, longitude];
    });
  }
}
